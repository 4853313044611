import { Component, OnInit } from "@angular/core"
import { LangService } from "@app/services/lang.service"

@Component({
	selector: "app-payment-client-finish",
	template: `
		<app-payment-wrap>
			<h2
				[innerHTML]="
					(success ? 'payment_successful' : 'payment_failed') | langVal
				"
			></h2>
			<br />
			<h3
				[innerHTML]="
					(success ? 'payment_successful1' : 'payment_failed1') | langVal
				"
			></h3>
			<br />
		</app-payment-wrap>
	`,
})
export class PaymentClientFinishComponent implements OnInit {
	success: boolean = false //was the purchase a success
	constructor(public lang: LangService) {}

	ngOnInit() {
		let input = location.search.split("?").pop() //gets the query string (everything in the URL after the ? sign)
		let statusCode = decodeURIComponent(input) //decodes the url (so it can be parsed as request pairs)
			.split("&") //splits to pairs
			.map((it) => it.split("=")) //split each pair to array of [name,value]
			.find((it) => it[0] == "statusCode") //tried to find an item where the name is "Response"

		if (statusCode && statusCode[1] == "0") {
			//found an item and the response was "000"
			this.success = true //change success to true
		}
		let response = decodeURIComponent(input) //decodes the url (so it can be parsed as request pairs)
			.split("&") //splits to pairs
			.map((it) => it.split("=")) //split each pair to array of [name,value]
			.find((it) => it[0] == "statusCode") //tried to find an item where the name is "Response"

		if (response && response[1] == "000") {
			//found an item and the response was "000"
			this.success = true //change success to true
		}
	}
}
