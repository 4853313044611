import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChange,
	ViewChild,
} from "@angular/core"
import { Modalable } from "@app/Modalable"

@Component({
	selector: "app-group-attends",
	template: `
		<app-table-wrap
			[fieldsStr]="
				isManualChargeShown == 'yes'
					? 'name,arrived,remarks,charged_manualy,external_invoice_id'
					: 'name,arrived,remarks'
			"
		>
			@for (row of rows; track row) {
				<tr
					app-group-attends-row
					[row]="row"
					[isManualChargeShown]="isManualChargeShown"
					(updated)="updated()"
				></tr>
			}
		</app-table-wrap>
	`,
})
export class GroupAttendsComponent extends Modalable {
	modalTitleLang: string = "group_attendancy"
	modalWidth: string = "modal-1200"
	@Input() followup_id: number = 0
	@Input() group_id: number = 0
	@Output() hasNonAttendOutput = new EventEmitter()
	hasNonAttendFunc: Function = null //function to run after api update
	rows: any[] = [] //the atteends rows
	isManualChargeShown: string = "no"

	async ngOnInit() {
		//when called to open get the attends
		this.isManualChargeShown =
			this.store.getCliniqDataOrExit().show_manual_charge
		const sendObj = {
			filterFieldApiObject: {
				group_id: this.group_id,
				followup_id: this.followup_id,
			},
		}
		const res: any = await this.apiService.get_gen_items(
			"groupattends",
			sendObj
		)
		this.rows = res.groupattends //update rows with attends
		this.rows.forEach((row) => {
			const find = row.user_id
				? this.store.searchSiteDataItem(row.user_id, ["sub_users"])
				: this.store.searchSiteDataItem(row.patient_id, [
						"patients",
						"patients_for_groups",
					])
			row.personName = find?.name || row?.patient_id || row?.user_id || ""
		})
	}
	updated() {
		const output = !!this.rows.filter((row) => row.arrived == "no").length
		if (this.hasNonAttendFunc) {
			//if function was defined, returns: (bool) are ANY items "no" (if ANY is no - true, if ALL are yes - false)
			this.hasNonAttendFunc(output)
		}
		this.hasNonAttendOutput.emit(output)
	}
}
