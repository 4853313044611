import { Pipe, PipeTransform } from "@angular/core"
import { StoreService } from "@app/services/store.service"

@Pipe({
	name: "finNumber",
})
export class FinNumberPipe implements PipeTransform {
	constructor(private store: StoreService) {}
	transform(num: any) {
		return this.store.numberFormat(num)
	}
}
