import { Component, OnInit } from "@angular/core"
import { ApiService } from "@app/services/api.service"
import { StoreService } from "@app/services/store.service"

@Component({
	selector: "app-activity",
	template: `
		<div style="padding: 20px">
			<div class="row">
				<div class="col-12">
					<app-gen-button-group
						[actualObj]="this"
						fieldName="period"
						optionsListName="activity_periods"
						title_ph_lang="activity_period"
					/>

					<button
						class="btn btn-icon"
						titleLang="expand"
						(click)="isClosed = !isClosed"
						[icon]="isClosed ? 'expand' : 'narrow'"
					></button>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<app-activity-log-table
						[isClosed]="isClosed"
						[period]="period"
					/>
				</div>
			</div>

			<div class="row">
				<div class="col-4">
					<app-table-wrap fieldsStr="status,count">
						@for (row of userNums; track row) {
							<tr>
								@for (field of ["status", "co"]; track field) {
									<td>{{ row[field] }}</td>
								}
							</tr>
						}
					</app-table-wrap>
				</div>
			</div>
		</div>
	`,
})
export class ActivityComponent implements OnInit {
	constructor(
		public apiService: ApiService,
		public store: StoreService
	) {}
	userNums: any[] = []
	period: string = "daily"
	isClosed: boolean = true

	async ngOnInit() {
		this.userNums = await this.apiService.post("get_user_type_to_num")
	}
}
