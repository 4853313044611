<div
	class="modal-backdrop fade"
	[class.show]="isShow"
	[style.display]="isShow ? 'block' : 'none'"
	[style.z-index]="zIndex - 1"
></div>

<div
	class="modal fade"
	tabindex="-1"
	role="dialog"
	(click)="onClick()"
	(mousedown)="onMouseDown()"
	[class.show]="isShow"
	[style.display]="isContentVisible ? 'block' : 'none'"
	[style.z-index]="zIndex"
>
	<div
		#modalDialog
		class="modal-dialog"
		[ngClass]="modalWrapperClass"
		(click)="innerClick($event)"
		(mousedown)="innerMouseDown($event)"
	>
		<div class="modal-content">
			<div
				class="modal-header"
				[appModalDrag]="self"
			>
				<button
					class="close"
					(click)="close()"
					aria-label="Close"
					icon="modal-close"
				></button>
				<div class="flex-v-center justify-content-between flex-grow-1">
					@if (title) {
						<h3>{{ title | langVal }}</h3>
					}
					<ng-template #header></ng-template>
				</div>
			</div>

			<div class="modal-body">
				<ng-template #viewContainerRef></ng-template>
				@if (hasExit) {
					<div class="modal-btns">
						<app-btn-ext
							text="exit"
							(clickEv)="close()"
						/>
					</div>
				}
			</div>
		</div>
	</div>
</div>
