import { Component, OnInit } from "@angular/core"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-user-details",
	template: `
		<div>
			<h4 class="gen-title">{{ "userdetails" | langVal }}</h4>

			@for (rowFields of rows; track rowFields) {
				<div class="row">
					@for (field of rowFields; track field) {
						<div
							[className]="
								'col-' +
								(field?.xs || clsDefaults.xs) +
								' col-md-' +
								(field?.md || clsDefaults.md)
							"
						>
							<label>{{ field?.lang || field.name | langVal }}</label>
							<app-gen-input
								[tabindex]="field?.tabIndex"
								[className]="field.name == 'email' ? ' email' : ''"
								[actualObj]="configcliniq"
								[errors]="errors"
								[fieldName]="field.name"
								[title_ph_lang]="field?.lang || field.name"
								(changeEvent)="validateAndSave()"
							></app-gen-input>
						</div>
					}
				</div>
			}
		</div>
	`,
})
export class CcParamsUserDetailsComponent extends CcParamsGen {
	saveObjName: string = "user"
	validationFields: any = {
		name: { not_empty: null },
		email: { not_empty: null },
	}

	clsDefaults: any = { xs: 6, md: 3 }
	tabIndexStart: number = 51
	rows: any[] = [
		[
			{ name: "name", xs: 12 },
			{ name: "title", lang: "user_title", xs: 12 },
			// {name:"name_on_invoice",lang:"name_on_invoice",xs:12},
		],
		[
			{ name: "profession", xs: 12 },
			{ name: "license_number", xs: 12 },
		],
		[
			{ name: "street" },
			{ name: "house", md: 1 },
			{ name: "city" },
			{ name: "zip", md: 2 },
		],
		[{ name: "phone" }, { name: "fax" }, { name: "email" }],
	]
	ngOnInit() {
		let curTabIndex = this.tabIndexStart
		this.rows.forEach((rowFields) => {
			rowFields.forEach((field) => {
				field.tabIndex = curTabIndex
				curTabIndex++
			})
		})
	}
}
