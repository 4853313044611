@if (isSummary) {
	@if (saveObj?.date) {
		<div
			class="row"
			style="max-width: 1000px"
		>
			<div class="green-stripe">
				<p>
					{{ "your_meeting_details" | langVal }}
					{{ saveObj?.date | dateFormat: true }} {{ "at" | langVal }}
					{{ saveObj?.time }} {{ "accepted" | langVal }}.
				</p>
			</div>
			@if (links?.length) {
				<div class="green-stripe">
					<p>
						@for (link of links; track link) {
							<a
								[href]="link"
								target="_blank"
								>{{ "please_pay-ahead" | langVal }}</a
							>
						}
					</p>
				</div>
			}
			<div class="green-stripe">
				<p style="font-size: 14px !important">
					{{ "confirmation_sent" | langVal }}
				</p>
			</div>
			<div class="green-stripe">
				<!--      <p>{{"see_you_on" | langVal}}{{saveObj?.date | dateFormat: true}}, </p>-->
				<!--      <p>  {{dataObj?.title}}{{dataObj?.name}}</p> -->
				<p>{{ "the_request_will_be_verified" | langVal }}</p>
				<p>{{ dataObj?.title }} {{ dataObj?.name }}</p>
			</div>
		</div>
	} @else {
		<div class="row">
			<div class="green-stripe">
				<p>{{ "meeting_request_was_sent" | langVal }}{{ dataObj?.name }}</p>
			</div>
		</div>
	}
} @else {
	@if (saveObj?.date) {
		<div class="row">
			<div class="green-stripe">
				<p>
					{{ "meeting_details" | langVal }}
					{{ saveObj?.date | dateFormat: true }}
					{{ saveObj?.date | dateFormat }} {{ "at" | langVal }}
					{{ saveObj?.time }}
				</p>
			</div>
		</div>
	}
	<div class="row scheduler-content">
		<div class="col-12 col-md-9">
			<label>{{ "name" | langVal }}</label>

			<app-gen-input
				[tabindex]="1"
				autocomplete="off"
				autofocus="autofocus"
				[actualObj]="actualObj"
				[errors]="errors"
				fieldName="name"
				(keyupEvent)="validateEntireForm()"
			/>
		</div>
	</div>
	<div class="row scheduler-content">
		<div class="col-12 col-md-9">
			<label>{{ "phone" | langVal }}</label>

			<app-gen-input
				[tabindex]="2"
				[actualObj]="actualObj"
				[errors]="errors"
				fieldName="phone"
				(keyupEvent)="validateEntireForm()"
			/>
		</div>
	</div>
	<div class="row scheduler-content">
		<div class="col-12 col-md-9">
			<label>{{ "email_not_must" | langVal }}</label>

			<app-gen-input
				[tabindex]="2"
				[actualObj]="actualObj"
				[errors]="errors"
				fieldName="email"
				(keyupEvent)="validateEntireForm()"
			/>
		</div>
	</div>
	<div class="row scheduler-content">
		<div class="col-12 col-md-9">
			<label>{{ "remarks" | langVal }}</label>

			<app-gen-input
				[tabindex]="3"
				type="textarea"
				[actualObj]="actualObj"
				[errors]="errors"
				fieldName="remarks"
				(keyupEvent)="validateEntireForm()"
			/>
		</div>
	</div>

	<div class="row scheduler-content">
		<div
			class="pointer col-11"
			titleLang="by_clicking_i_agree"
		>
			<input
				class="agree-to-terms"
				type="checkbox"
				[(ngModel)]="actualObj.agree_to_terms"
				[checked]="actualObj.agree_to_terms"
				(change)="validateEntireForm()"
			/>
			<span
				class="text-agree"
				(click)="openTerms()"
				style="margin-inline: 5px"
			>
				{{ "by_clicking_i_agree" | langVal }} {{ "to_terms" | langVal }}</span
			>
		</div>
	</div>
	@if (errors.agree_to_terms) {
		<p class="error">{{ errors.agree_to_terms }}</p>
	}

	<div class="row scheduler-content">
		<div class="col-12 modal-btns">
			<app-btn-ext
				[text]="
					dataObj?.meeting_request_with_calendar
						? 'to_set_the_appointment'
						: 'to_send_the_request'
				"
				[disabled]="!isFormSubmittable"
				tabindex="10"
				(clickEv)="save()"
				[isSpin]="isSaving"
			/>
		</div>
	</div>
}

@if (showBack && isMobile) {
	<div class="row scheduler-content">
		<div class="col-12">
			<button
				class="btn btn-primary btn-back btn-bottom"
				(click)="backEv.emit(isSummary)"
				icon="back"
			></button>
		</div>
	</div>
}
