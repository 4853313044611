import {
	Component,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	booleanAttribute,
} from "@angular/core"
import { Contactway, ValueName } from "@app/definitions/types"
import { LangService } from "@app/services/lang.service"
import { StoreService } from "@app/services/store.service"

@Component({
	selector: "th[sorter]",
	host: {
		"[class.pointer]": "isSortable",
		"[style.font-weight]": "bold",
		"[title]": "displayName",
	},
	template: `
		{{ displayName }}
		@if (isSortable) {
			<app-icon
				[icon]="sortField == fieldName && sortIsAsc ? 'sortUp' : 'sortDown'"
				[className]="sortField == fieldName ? 'green' : ''"
			/>
		}
	`,
})
export class SortThComponent {
	@Input() displayName: string = ""
	@Input() fieldName: string = ""
	@Input() sortField: string = ""
	@Input() sortIsAsc: string = ""
	@Input() isSortable: boolean = true
}

@Component({
	selector: "app-spin-or-content",
	template: `
		@if (isSpin) {
			<app-spin style="margin:0" />
		} @else {
			<ng-content />
		}
	`,
})
export class SpinOrContent {
	@Input() isSpin: boolean = false
}

@Component({
	selector: "app-yes-no",
	template: `
		<div class="yes-no">
			@if (disabled) {
				@if (isCheck) {
					<button
						class="btn btn-icon"
						[class.green]="isCheck"
					>
						{{ "✓" }}
					</button>
				} @else {
					<button
						class="btn btn-icon"
						[class.gray]="isCheck"
					>
						{{ "X" }}
					</button>
				}
			} @else {
				<button
					class="btn btn-icon"
					[class.green]="isCheck"
					(click)="onClick.emit(true); $event.stopPropagation()"
				>
					{{ isCheck ? "✓" : "▢" }}
				</button>
			}
		</div>
	`,
})
export class YesNo {
	@Output("onClick") onClick = new EventEmitter()
	@Input() isCheck: boolean = false
	@Input() disabled: boolean = false
}

@Component({
	selector: "app-icon,[icon]",
	template: `
		@if (isImg) {
			<img
				[src]="'img.' + icon | configVal"
				[alt]="titleLang | langVal"
				[class.pointer]="pointer"
				[ngClass]="className"
				style="height:100%;"
			/>
		} @else {
			<i
				[ngClass]="
					('icon.' + icon | configVal) + (className ? ' ' + className : '')
				"
				[class.pointer]="pointer"
				[attr.title]="(titleLang | langVal) || null"
			></i>
		}
	`,
})
export class Icon {
	@Input({ transform: booleanAttribute }) isImg: boolean = false
	@Input() icon: string = ""
	@Input() titleLang: string = ""
	@Input() className: string = ""
	@Input({ transform: booleanAttribute }) pointer: boolean
}

@Component({
	selector: "app-spin,[spin]",
	template: ` <app-icon icon="spin" />`,
})
export class Spin {}

@Component({
	selector: "app-toast-download",
	template: ` <div
		class="snackbar download"
		[class.doHide]="doHide"
		spinner
	>
		{{ "download_process" | langVal }}
	</div>`,
})
export class ToastDownloadComponent {
	doHide: boolean = true //is in show state flag
	constructor(private store: StoreService) {
		this.store.downloadSubject.subscribe(
			(isStart: boolean) => (this.doHide = !isStart)
		)
	}
}

@Component({
	selector: "app-test-display",
	template: `
		@for (testInner of test?.tests; track testInner) {
			<div>
				<h3>{{ testInner.name }}</h3>
				<app-test-display [test]="testInner" />
			</div>
		}
		@for (ques of test?.questions; track ques) {
			<div>
				<b style="display: block;">{{ ques.q }}</b>
				{{ ques.a }}
			</div>
		}
	`,
})
export class TestDisplayComponent {
	@Input() test: any
}

@Component({
	selector: "app-div-wrap",
	template: `
		<div [class.table-responsive]="isResponsive">
			<div>
				<div>
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	`,
})
export class DivWrapComponent {
	fields: string[] = []
	@Input() fieldsStr: string = ""
	@Input() fieldsArr: string[] = null
	@Input() isStriped: boolean = true
	@Input({ transform: booleanAttribute }) isResponsive: boolean = true
	widths: any = {}

	ngOnInit() {
		this.fields = this.fieldsStr.split(",")
		if (this.fieldsArr) {
			this.fields = this.fieldsArr
		}
		this.fields.forEach((field, ind) => {
			if (field.includes(":")) {
				const r = field.split(":")
				this.fields[ind] = r[0]
				this.widths[r[0]] = r[1]
			}
		})
	}
}

@Component({
	selector: "app-table-wrap",
	template: `
		<div [class.table-responsive]="isResponsive">
			<table class="table table-hover pad-block">
				@if (fields.length) {
					<thead>
						<tr>
							@for (field of fields; track field) {
								<th [style.width]="widths[field] ? widths[field] + '%' : null">
									{{ field | langVal }}
								</th>
							}
						</tr>
					</thead>
				}
				<tbody>
					<ng-content></ng-content>
				</tbody>
			</table>
		</div>
	`,
})
export class TableWrapComponent {
	fields: string[] = []
	@Input() fieldsStr: string = ""
	@Input() fieldsArr: string[] = null
	@Input() isStriped: boolean = true
	@Input({ transform: booleanAttribute }) isResponsive: boolean = true
	widths: any = {}

	ngOnInit() {
		this.fields = this.fieldsStr.split(",")
		if (this.fieldsArr) {
			this.fields = this.fieldsArr
		}
		this.fields.forEach((field, ind) => {
			if (field.includes(":")) {
				const r = field.split(":")
				this.fields[ind] = r[0]
				this.widths[r[0]] = r[1]
			}
		})
	}
}

@Component({
	selector: "app-cancel-btn",
	template: ` <button
		class="btn btn-default"
		titleLang="cancel"
		tabindex="-1"
	>
		{{ "cancel" | langVal }}
	</button>`,
})
export class CancelBtnComponent {}

@Component({
	selector: "[spinner]",
	template: ` <ng-content />
		@if (spinner) {
			<app-spin />
		}`,
})
export class SpinnerComponent {
	@Input({ transform: booleanAttribute }) spinner: boolean = false
}

@Component({
	selector: "[preIcon]",
	template: `@if (preIcon) {
			<app-icon
				[icon]="preIcon"
				className="pre-icon"
				[isImg]="isImg"
			/>
		}
		<ng-content />`,
})
export class PreIconComponent {
	@Input() preIcon: string = ""
	@Input({ transform: booleanAttribute }) isImg: boolean = false
}
@Component({
	selector: "[postIcon]",
	template: `<ng-content />
		@if (postIcon) {
			<app-icon
				[icon]="postIcon"
				className="post-icon"
			/>
		}`,
})
export class PostIconComponent {
	@Input() postIcon: string = ""
	@Input({ transform: booleanAttribute }) isImg: boolean = false
}

@Directive({
	selector: "[lang]",
})
export class LangAttrDirective {
	constructor(
		private el: ElementRef,
		private lang: LangService
	) {}
	ngOnInit() {
		this.el.nativeElement.innerText = this.lang.getVal(
			this.el.nativeElement.innerText
		)
	}
}

@Directive({
	selector: "[tabClick]",
})
export class TabClickDirective {
	constructor(private el: ElementRef) {}

	@HostListener("keydown.tab", ["$event"]) cl(event: KeyboardEvent) {
		event.preventDefault()
		this.el.nativeElement.click()
	}
}

@Component({
	selector: "app-contactway-add",
	template: `
		<div class="row">
			<div class="col-12">
				<label>{{ "contactway" | langVal }}</label>
				<button
					class="btn btn-icon btn-plus"
					(click)="addContactway.emit()"
					icon="add"
					isImg
				></button>
			</div>
		</div>
		@for (contactway of contactways; track contactway) {
			<div>
				<div class="row form-group">
					<div class="colstat colstat-big mb-2">
						<app-gen-input
							[actualObj]="contactway"
							[errors]="contactway.errors"
							fieldName="contactway"
							title_ph_lang="contactway_1"
							(keyupEvent)="keyupContactway.emit(contactway)"
							(changeEvent)="changeEventContactway.emit(contactway)"
						/>
						@if (contactway?.warning_status) {
							<span [innerHTML]="contactway.warning_status | langVal"></span>
						}
					</div>
					<div class="colstat colstat-small">
						<app-gen-button-group
							[actualObj]="contactway"
							fieldName="main"
							optionsListName="main_opts"
							(changeEvent)="fieldChange.emit()"
						>
						</app-gen-button-group>
					</div>
					<div class="col-1">
						<button
							class="btn btn-icon"
							(click)="removeContactway.emit(contactway.id)"
							icon="xmark"
						></button>
					</div>
					<!-- <div class="col-1">
            <button class="btn btn-icon" (click)="addContactway.emit()" icon="plus"></button>
          </div> -->
				</div>
				<div class="row form-group">
					<div class="col-11 colstat colstat-big mb-2">
						<app-gen-input
							[actualObj]="contactway"
							fieldName="name"
							(keyupEvent)="fieldChange.emit()"
						>
						</app-gen-input>
					</div>
					<div class="col-11 colstat colstat-big">
						<app-gen-input
							[actualObj]="contactway"
							fieldName="description"
							(keyupEvent)="fieldChange.emit()"
						>
						</app-gen-input>
					</div>

					<!--          <div class="col-12 col-lg-3">-->
					<!--            <app-gen-button-group [actualObj]="contactway" fieldName="contactway_identifier"-->
					<!--                                  optionsListName="contactway_identifier_opts"-->
					<!--                                  (changeEvent)="fieldChange.emit()">-->
					<!--            </app-gen-button-group>-->
					<!--          </div>-->
				</div>
			</div>
		}
		<!-- @empty{
      <div class="row">
        <div class="col-11">
          <button class="btn btn-icon" (click)="addContactway.emit()" icon="plus"></button>
        </div>
      </div>
    } -->
	`,
})
export class ContactwayAddComponent {
	@Input() contactways: Contactway[] = []
	@Output() fieldChange = new EventEmitter()
	@Output() addContactway = new EventEmitter()
	@Output() removeContactway = new EventEmitter()
	@Output() keyupContactway = new EventEmitter()
	@Output() changeEventContactway = new EventEmitter()
}

@Component({
	selector: "app-btn-ext",
	template: `
		<button
			class="btn"
			[ngClass]="'btn-' + btnClass"
			[titleLang]="title || text"
			[disabled]="disabled || isSpin"
			[spinner]="isSpin"
			[tabindex]="tabindex"
			tabClick
			(click)="clickEv.emit(true)"
		>
			@if (iconName && iconIsPre) {
				<app-icon [icon]="iconName" />
			}
			{{ text | langVal }}
			@if (iconName && !iconIsPre) {
				<app-icon [icon]="iconName" />
			}
		</button>
	`,
})
export class BtnExtComponent {
	@Output() clickEv = new EventEmitter()
	@Input() disabled: boolean = false
	@Input() isSpin: boolean = false
	@Input() text: string = ""
	@Input() title: string = null
	@Input() tabindex: string = null
	@Input() btnClass: string = "primary"
	@Input() iconName: string = null
	@Input({ transform: booleanAttribute }) iconIsPre: boolean = false
}

@Component({
	selector: "app-patient-modal",
	template: `<div
		(click)="open()"
		[spinner]="isSpin"
	>
		<ng-content />
	</div>`,
})
export class PatientModalComponent {
	@Input() id: number = null
	isSpin: boolean = false

	constructor(public store: StoreService) {}
	async open() {
		if (!this.id) {
			return
		}
		this.isSpin = true
		const patient = await this.store.getPatient(this.id)
		this.isSpin = false
		this.store.openEditPatient(patient)
	}
}

@Component({
	selector: "app-logo-gen",
	template: `
		<div
			class="LOGO"
			title="My-cliniq"
			style="text-align: left"
		>
			<img
				src="https://www.my-cliniq.com/wp-content/uploads/2020/07/my-cliniq-logo.png"
				alt="My-cliniq"
			/>
		</div>
	`,
})
export class LogoGenComponent {}

@Component({
	selector: "app-payment-wrap",
	template: `
		<div
			class="container-fluid"
			style="background-color: inherit"
		>
			<div style="line-height: 1.6">
				<app-logo-gen />
				<div>
					<div
						class="well well-lg"
						style="margin: 100px 300px 500px"
					>
						<div>
							<ng-content />
						</div>
					</div>
				</div>
				<div class="footer"></div>
			</div>
		</div>
	`,
})
export class PaymentWrapComponent {}

@Component({
	selector: "app-checkboxes-gen",
	template: `
		<div class="row checkboxes">
			<div class="col-12">
				<h3>{{ title | langVal }}</h3>
				<div class="table-responsive">
					<table class="table table-hover">
						<tbody>
							<tr>
								<td>
									<input
										[checked]="allOp"
										(change)="flipAll()"
										type="checkbox"
									/>
								</td>
								<td>
									<span (click)="flipAll()">
										{{ "select_all_fields" | langVal }}
									</span>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<hr />
								</td>
							</tr>
							@for (field of fields; track field.value) {
								<tr>
									<td>
										<input
											[checked]="obj[field.value]"
											(change)="
												handleChange(field.value, $event.target.checked)
											"
											type="checkbox"
										/>
									</td>
									<td>
										<span
											(click)="handleChange(field.value, !obj[field.value])"
										>
											{{ field.name | langVal }}
										</span>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	`,
})
export class CheckboxesGenComponent {
	@Input() fields: ValueName[] = []
	@Input() title: string = ""
	@Input() obj: any = null
	@Output() onChange = new EventEmitter()

	allOp: boolean = true

	handleChange(field: any, newValue: boolean) {
		this.obj[field] = newValue
		this.onChange.emit(this.obj)
	}

	flipAll() {
		this.allOp = !this.allOp
		this.obj = this.fields.reduce(
			(prev, { value }) => ({ ...prev, [value]: this.allOp }),
			{}
		)
		this.onChange.emit(this.obj)
	}
}
