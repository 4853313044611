<app-mobile-tabs
	[activeColumn]="showColumn"
	[personType]="personType"
	(onClick)="updateShowColumnLocal($event)"
/>

<app-banner
	[person]="payor || contact"
	[type]="personType"
/>

<div class="row below-banner person-content">
	<div
		[hidden]="
			eventsService.isMobile &&
			(showColumn | inArray: ['calendar', 'sideItems'])
		"
		class="col-12 col-lg-8"
	>
		@if (payor || contact) {
			@if (curCenter) {
				<div class="panel panel-default">
					<div class="panel-head">
						<!--            <div class="col-1" style="width: 30px;">-->
						<!--              <button  class="close" tabindex="-1" titleLang="back_to_details" (click)="backToMain()" icon="modal-close">-->
						<!--                <span class="back-to">{{'back_to_details' | langVal}}</span>-->
						<!--              </button>-->
						<!--            </div>-->
						<div class="col-10">
							<button
								class="close back-to"
								tabindex="-1"
								titleLang="back_to_details"
								(click)="backToMain()"
								preIcon="modal-close"
							>
								<span class="back-to-details">{{
									"back_to_details" | langVal
								}}</span>
							</button>
						</div>
						@if (curCenter == "invoice") {
							<div class="d-flex justify-content-between flex-wrap">
								<div>
									<button
										titleLang="bankaccounts"
										tabindex="-1"
										class="btn btn-primary"
										(click)="openBankaccounts()"
									>
										{{ "bankaccounts" | langVal }}
									</button>
									<button
										titleLang="invoice_short"
										tabindex="-1"
										class="btn btn-primary"
										(click)="addInvoice()"
										postIcon="plus"
									>
										{{ "invoice_short" | langVal }}
									</button>
								</div>
								<div>
									@if (payor) {
										<button
											titleLang="follow_ups_report"
											tabindex="-1"
											class="btn btn-primary"
											(click)="openReport('followups_report')"
										>
											{{ "follow_ups_report" | langVal }}
										</button>
									}
									<button
										titleLang="income"
										tabindex="-1"
										class="btn btn-primary"
										(click)="openReport('invoices_report')"
									>
										{{ "income" | langVal }}
									</button>
								</div>
								<div style="min-width: 300px">
									<app-gen-button-group
										[actualObj]="this"
										fieldName="is_finresource_connected_only"
										optionsListName="is_finresource_connected_only"
										optionFieldId="value"
									/>
								</div>
							</div>
						}
					</div>
					<div class="panel-body">
						@switch (curCenter) {
							@case ("contactway") {
								<app-gen-table
									[filterField]="personType + '_id'"
									[filterFieldValue]="(contact || payor).id"
									tableName="contactways"
									(changeHappened)="leftSideUpdate($event, 'contactways')"
								/>
							}
							@case ("reminder") {
								<app-gen-table
									[filterField]="personType + '_id'"
									[filterFieldValue]="(contact || payor).id"
									tableName="notifications"
									isEditDisabled
								/>
							}
							@case ("invoice") {
								<app-gen-table
									#finresourceTable
									[filterField]="personType + '_id'"
									[filterFieldValue]="(contact || payor).id"
									[tableName]="
										is_finresource_connected_only == 'yes'
											? 'open_proformas'
											: 'finResource_list'
									"
								/>
							}
						}
					</div>
				</div>
			} @else {
				@if (contact) {
					<app-update-contact
						[contact]="contact"
						(addInvoiceEv)="addInvoice()"
					/>
				}
				@if (payor) {
					<app-update-payor
						[payor]="payor"
						(addInvoiceEv)="addInvoice()"
						(openReport)="openReport($event)"
					/>
				}
			}
		}
	</div>

	@if (contact || payor) {
		<div
			[hidden]="
				eventsService.isMobile && (showColumn | inArray: ['calendar', 'center'])
			"
			id="person-side-col"
			class="col-12 col-lg-4 side-scroll"
			#center
		>
			@for (sideItem of sideItems; track sideItem) {
				<app-person-side-item
					[color]="color"
					[sideItem]="sideItem"
					[curCenter]="curCenter"
					(click)="curCenter = sideItem.name; updateShowColumnLocal('center')"
					class="pointer"
				>
					<div class="person-side-items">
						@switch (sideItem.name) {
							@case ("contactway") {
								<div
									class="contactway"
									*ngFor="
										let contactway of (contact || payor).contactways
											| slice: 0 : 4
									"
								>
									{{ contactway.contactway }}
									{{ contactway.name ? "   -   " + contactway.name : "" }}
								</div>
							}
							@case ("invoice") {
								@if (payor) {
									<div class="totals-format">
										<div>
											{{ ("total_lm_debt" | langVal) + " - " }}
											<span>{{ payor.total_lm_debt | finNumber }}</span>
										</div>
										@if (payor.total_total_debt >= 0) {
											<div>
												{{ ("total_total_debt" | langVal) + ": " }}
												<span>{{ payor.total_total_debt | finNumber }}</span>
											</div>
										} @else {
											<div>
												{{
													("total_total_credit" | langVal) + payor.name + ": "
												}}
												<span>{{ -payor.total_total_debt | finNumber }}</span>
											</div>
										}
									</div>
								}
							}
						}
					</div>
				</app-person-side-item>
			}
		</div>
	}
</div>
