import { Component, Input, OnInit, SimpleChange } from "@angular/core"
import { Configcliniq } from "@app/definitions/types"
import { PermsService } from "@app/services/perms.service"
import { StoreService } from "@app/services/store.service"
import { ModalService } from "@app/services/modal.service"
@Component({
	selector: "app-cc-sections",
	templateUrl: "./cc-sections.component.html",
})
export class CcSectionsComponent {
	@Input() name: string = ""
	@Input() section: string = ""
	configcliniq: Configcliniq //the config DB obj

	sections: any[] = []

	areaSections = {
		patients: [
			// { tableName: "financial", title: "financial",color: "#FFB7B2", isOpen: true, isTable:false },
			{
				tableName: "patients",
				title: "patients",
				color: "#FFDAC1",
				isOpen: false,
			},
			{ tableName: "groups", title: "groups", color: "#B5EAD7" },
			{ tableName: "inquirers", title: "inquirers", color: "#75E6DA" },
			// { tableName: "patienttypes", title: "patienttypes", color: "#E2F0CB"},
			{
				tableName: "cc-insert-patient-templates",
				title: "select_fields",
				color: "#cbe2f0",
				nonTable: true,
			},
			{ tableName: "patientfields", title: "patientfields", color: "#f0cbe2" },
			{
				tableName: "patient-details-fields-manage",
				title: "patient_details_fields",
				color: "#cbe2f0",
				nonTable: true,
			},
			{ tableName: "referers", title: "referers", color: "#cbf0ec" },
			// { tableName: "inquiry_statuses", title: "inquiry_statuses", color: "#cafde0"},
			{
				tableName: "patient_unification",
				title: "patient_unification",
				color: "#AEC6CF",
				nonTable: true,
			},
			{
				tableName: "import-patients",
				title: "import_patients",
				color: "#fcdcdf",
				nonTable: true,
			},
			{
				tableName: "import-followups",
				title: "import_followups",
				color: "#bae1ff",
				nonTable: true,
			},
			{ tableName: "contactway_list", title: "emails", color: "#d3d3d3" },
		],
		multiuser: [
			{
				tableName: "sub_users",
				title: "sub_users_dashboard",
				color: "#baffc9",
				isOpen: true,
			},
			{ tableName: "clinic_roles", title: "roles", color: "#e7ffff" },
			{ tableName: "locations", title: "locations", color: "#ffb3ba" },
			{ tableName: "tags", title: "tags", color: "#ffdfba" },
			{
				tableName: "import-tags",
				title: "import_tags",
				color: "#d3d3d3",
				nonTable: true,
			},
			{ tableName: "meetingtypes", title: "meetingtypes", color: "#ffffba" },
			{ tableName: "tarifs", title: "tarif", color: "#fcdcdf" },

			{
				tableName: "sub_user_groups",
				title: "sub_user_groups",
				color: "#bae1ff",
			},
			{ tableName: "partners", title: "partners", color: "#bae1ff" },
			// { tableName: "cc-reports", title: "reports",color: "#d3d3d3",nonTable:true},
		],
		contacts: [
			// { tableName: "financial", title: "financial",color: "#FFB7B2", isOpen: true, nonTable:true },
			{
				tableName: "contacts",
				title: "contacts",
				color: "#FFDAC1",
				isOpen: true,
			},
			{ tableName: "contacttypes", title: "contacttypes", color: "#E2F0CB" },
		],
		taxes: [
			// { tableName: "financial", title: "financial",color: "#FFB7B2", isOpen: true, isTable:false },
			{ tableName: "taxes", title: "taxes", color: "#FFDAC1" },
			{ tableName: "payors", title: "payors", color: "#E2F0CB" },
			{
				tableName: "accountant",
				title: "accountant",
				color: "#B5EAD7",
				nonTable: true,
			},
			{ tableName: "expensetypes", title: "expensetypes", color: "#f7dfba" },
			{ tableName: "suppliers", title: "suppliers", color: "#baffc9" },
			// { tableName: "hashav", title: "hashav", color: "#FFDA11",nonTable:true },
			{
				tableName: "ownerbankaccounts",
				title: "ownerbankaccounts",
				color: "#bae1ff",
			},
		],
		"info-tables": [
			{ tableName: "medics", title: "medics", color: "#FFB7B2", isPagi: true },
			{ tableName: "nondrugs", title: "nondrugs", color: "#FFDAC1" },
			{ tableName: "labtests", title: "labtests", color: "#E2F0CB" },
			{
				tableName: "diagps",
				title: "diagnosis",
				color: "#B5EAD7",
				isPagi: true,
			},
		],
		// "document-and-quest":[
		tests: [
			{
				tableName: "tests",
				title: "tests",
				color: "#FFDAC1",
				isOpen: true,
				nonTable: true,
			},
			{
				tableName: "public_tests",
				title: "public_tests",
				color: "#FFDAC1",
				nonTable: true,
			},
			// { tableName: "documenttemplates", title: "documenttemplates",color: "#FFB7B2" },
		],
		journal: [
			// { tableName: "calendar_config", title: "calendar_config",color: "#FFB7B2", isOpen: true, isTable:false },
			{ tableName: "meetingtypes", title: "meetingtypes", color: "#FFDAC1" },
			{ tableName: "followuptypes", title: "followuptypes", color: "#E2F0CB" },
			{
				tableName: "holiday",
				title: "holiday",
				color: "#B5EAD7",
				nonTable: true,
			},
		],
		"home-super-admin": [
			{ tableName: "dblogs", title: "dblogs", color: "#FFDAC1" },
			{ tableName: "users", title: "users", color: "#E2F0CB" },
			{ tableName: "faqs", title: "faqs", color: "#B5EAD7" },
			{
				tableName: "activity",
				title: "activity",
				color: "#B5EAD7",
				nonTable: true,
			},
		],
		user_payments: [
			// { tableName: "financial", title: "financial",color: "#FFB7B2", isOpen: true, isTable:false },
			{
				tableName: "payment",
				title: "payment",
				color: "#B5EAD7",
				nonTable: true,
			},
			{
				tableName: "admininvoices",
				title: "admininvoices",
				color: "#B5EAD7",
				nonTable: true,
			},
		],
		automation: [
			{
				tableName: "accountant",
				title: "accountant",
				color: "#B5EAD7",
				nonTable: true,
			},
			{ tableName: "patients_sms", title: "patients_sms", color: "#f0cbe2" },
		],
		user: [
			// { tableName: "user-config", title: "user_configuration", color: "#cbe2f0",nonTable: true},
			{
				tableName: "user-details",
				title: "user_details",
				color: "#f0cbe2",
				isOpen: true,
				nonTable: true,
			},
		],
		self_coordination: [
			{
				tableName: "user-details-website",
				title: "userdetails_website",
				color: "#aacbe2",
				nonTable: true,
			},
		],
	}

	constructor(
		public store: StoreService,
		public permsService: PermsService,
		public modalService: ModalService
	) {
		this.configcliniq = this.store.getCliniqDataOrExit()
	}

	ngOnChanges(changes: SimpleChange) {
		//if person changed (ex: contact) - send to load function
		if (changes["section"]) {
			this.sections = this.areaSections[this.section] || []
		}
	}

	openSection(section: any) {
		const curIsClosed = !section.isOpen
		this.sections.forEach((section) => (section.isOpen = false))
		if (curIsClosed) {
			section.isOpen = true
		}
	}
}
