import { TimeAllocationComponent } from "./time-allocation/time-allocation.component"
import { PaymentFinishComponent } from "./payment-finish/payment-finish.component"
import { NgModule, inject } from "@angular/core"
import {
	RouterModule,
	Routes,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateFn,
} from "@angular/router"
import { GenTablePaginationComponent } from "@app/gen-table-pagination/gen-table-pagination.component"
import { CalendarComponent } from "./calendar/calendar.component"
import { ConfigCliniqComponent } from "./config-cliniq/config-cliniq.component"
import { ContactComponent } from "./person-common/person-common.component"
import { GenTableComponent } from "./gen-table/gen-table.component"
import { GenTable2Component } from "./gen-table2/gen-table2.component"
import {
	HomeComponent,
	HomeAdminComponent,
	HomeSuperAdminComponent,
} from "./home/home.component"
import { LoginComponent } from "./login/login.component"
import { PatientComponent } from "./patient/patient.component"
import { PaymentFormComponent } from "./payment-form/payment-form.component"
import { ClientPaymentFormComponent } from "./client-payment-form/client-payment-form.component"
import { GuestPaymentFormComponent } from "./guest-payment-form/guest-payment-form.component"
import { PayorComponent } from "./person-common/person-common.component"
import { ErrorService } from "./services/error-service.service"
import { UserComponent } from "./user/user.component"
import { CcParamsSubUserComponent } from "@app/cc-params-sub-user/cc-params-sub-user.component"
import { PaymentClientFinishComponent } from "./payment-client-finish/payment-client-finish.component"
import { PaymentClientFinishGrowComponent } from "./payment-client-finish-grow/payment-client-finish-grow.component"
import { PaymentClientFailGrowComponent } from "./payment-client-fail-grow/payment-client-fail-grow.component"
import { GuestSchedComponent } from "./guest-sched/guest-sched.component"
import { PatientTestFillComponent } from "./patient-test-fill/patient-test-fill.component"
import { StoreService } from "./services/store.service"
import { PatientDetailsFillComponent } from "./patient-details-fill/patient-details-fill.component"
import { GuestCalendarComponent } from "./guest-calendar/guest-calendar.component"
import { FctryComponent } from "./fctry/fctry.component"

const UserLoginGuardFn: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	return inject(StoreService).authGuard(state)
}

const routes: Routes = [
	{
		path: "",
		canActivate: [UserLoginGuardFn],
		children: [
			//every path inside this cannot be accessed without being logged in
			{ path: "config-cliniq", component: ConfigCliniqComponent },
			{
				path: "config-cliniq/:innerSectionName",
				component: ConfigCliniqComponent,
			}, //uses inner section name as param
			{ path: "sub-user-config-cliniq", component: CcParamsSubUserComponent },
			{ path: "purchases/create", component: PaymentFormComponent },
			{ path: "home", component: HomeComponent },
			{ path: "home-admin", component: HomeAdminComponent },
			{ path: "calendar", component: CalendarComponent },
			{ path: "calendar/:urlView/:urlDate", component: CalendarComponent },
			{ path: "table/:tableName", component: GenTableComponent }, //any table from table service (some are excluded)
			{ path: "table2/:tableName", component: GenTable2Component }, //any table from table service (some are excluded)
			{ path: "contacts/:id", component: ContactComponent },
			{ path: "payors/:id", component: PayorComponent },
			{ path: "patients/:id", component: PatientComponent },
			{ path: "user", component: UserComponent },
			{ path: "table-pagi/:tableName", component: GenTablePaginationComponent }, //same as table/:tableName but with pagination capabilities
			{ path: "time-allocation", component: TimeAllocationComponent },
			{ path: "activity", redirectTo: "table/dblogs", pathMatch: "full" },
			{
				path: "home-super-admin",
				component: HomeSuperAdminComponent,
				canActivate: [() => inject(StoreService).isSuperAdmin()],
			},
			{ path: "", redirectTo: "home", pathMatch: "full" },
		],
	},
	{ path: "client-payment", component: ClientPaymentFormComponent },
	{ path: "r/:code", component: ClientPaymentFormComponent },
	{ path: "guest-payment", component: GuestPaymentFormComponent },
	{ path: "takbullClientFinish", component: PaymentClientFinishComponent }, //on return from tranzila iframe
	{ path: "GrowClientFinish", component: PaymentClientFinishGrowComponent }, //on return from tranzila iframe
	{ path: "GrowClientFail", component: PaymentClientFailGrowComponent }, //on return from tranzila iframe
	{ path: "takbullFinish", component: PaymentFinishComponent }, //on return from tranzila iframe
	{ path: "login", component: LoginComponent },
	{ path: "password/:type/:token", component: LoginComponent }, //used for resets/forgot/etc but with token from an email
	{ path: "password/:type", component: LoginComponent }, //used for resets/forgot/etc
	{ path: "account/confirmation/:confirmToken", component: LoginComponent }, //used at the end of user registration confirmation with token from an email
	{ path: "account-sub-user/:subUserToken", component: LoginComponent }, //used at sub user registration after receiving email from cliniq_user with token
	{ path: "potential-user/:potentialUserToken", component: LoginComponent }, //used at sub user registration after receiving email from cliniq_user with token
	{ path: "regi/:regi", component: LoginComponent }, //can be greeting etc instead
	{ path: "regi/:regi/:userId", component: LoginComponent }, //can be greeting etc instead
	{ path: "register", redirectTo: "regi/regi", pathMatch: "full" }, //can be greeting etc instead
	{ path: "guest-cal", component: GuestCalendarComponent },
	{ path: "guest-sched", component: GuestSchedComponent },
	{ path: "tranzilaFinish", component: PaymentFinishComponent }, //on return from tranzila iframe
	{ path: "patient-test-fill/:code", component: PatientTestFillComponent },
	{
		path: "patient-details-fill/:code",
		component: PatientDetailsFillComponent,
	},
	{ path: "estryrhfgfw45ey6hfg", component: FctryComponent },
	{ path: "estryrhfgfw45ey6hfg/delay", component: FctryComponent },

	{ path: "**", resolve: { data: "LangResolver" }, component: LoginComponent }, //catch all for bad routes
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
	// imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [
		{
			provide: "LangResolver", //will use the "resolve" method in ErrorService
			useClass: ErrorService,
		},
	],
})
export class AppRoutingModule {}
