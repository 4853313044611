import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core"
import { ZoneRow } from "@app/definitions/types"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-zones",
	template: `
		<div>
			<h4 class="gen-title">{{ "zones" | langVal }}</h4>
			<div
				[style.height]="disp.length * totalHEach + 'px'"
				style="position:relative;"
			>
				@for (zone of disp; track zone) {
					<div
						#dragItems
						[attr.id]="zone.id"
						class="zone"
						[style.top]="$index * totalHEach + 'px'"
						(mousedown)="zoneClick(zone, $event)"
						[style.background-color]="'#90ee90' + (zone.show ? '' : '80')"
						style="z-index:4;"
					>
						<app-icon
							[icon]="zone.show ? 'zone-eye' : 'zone-eye-off'"
							(click)="show(zone, $event)"
							(mousedown)="$event.stopImmediatePropagation()"
							(mouseup)="$event.stopImmediatePropagation()"
						/>
						{{ zone.name + "s" | langVal }}
					</div>
				}
			</div>
		</div>
	`,
})
export class CcParamsZonesComponent extends CcParamsGen {
	//inheriting most functions and fields!

	zones: ZoneRow[] = []
	base: any = {}
	disp: any[] = []
	dragObj: {
		id: number
		mm: any
		el: any
		clientY: number
		offsetY: number
		itemTop: number
	} = null
	totalHEach: number = 44
	@ViewChildren("dragItems") dragItems: QueryList<ElementRef>

	ngOnInit() {
		this.zones = []
		const ccZones = this.store.getCliniqDataOrExit()?.zones
		let pos = 0
		const zs = this.store.getSiteDataTable("zones")
		if (ccZones) {
			ccZones.forEach((zone_id) => {
				this.zones.push({
					id: zone_id,
					name: zs.find((z) => z.id == zone_id)?.name,
					order: pos,
					show: true,
				})
				pos++
			})
		}
		zs.forEach((zoneRow) => {
			if (this.zones.find((z) => z.id == zoneRow.id)) {
				return
			}
			this.zones.push({
				id: zoneRow.id,
				name: zoneRow.name,
				order: pos,
				show: false,
			})
			pos++
		})
		this.sort()
	}

	sort() {
		this.disp = [...this.zones.map((it) => ({ ...it }))]
		this.disp.sort((a, b) => a.order - b.order)
	}
	show(it: any, ev: MouseEvent) {
		ev.stopImmediatePropagation()
		ev.preventDefault()
		it.show = !it.show
		this.save()
	}

	zoneClick(it: any, ev: MouseEvent) {
		ev.preventDefault()
		const itemTop = this.totalHEach * it.order
		this.dragObj = {
			id: it.id,
			clientY: ev.clientY,
			offsetY: ev.offsetY,
			itemTop,
			mm: this.mm2.bind(this),
			el: ev.target,
		}

		this.zone.runOutsideAngular(() => {
			//register event listener OUTSIDE ANGULAR PROCESS, so that detection would be avoided (much better performance)
			this.dragObj.el.style.zIndex = this.dragObj.el.style.zIndex + 1
			window.document.addEventListener("mousemove", this.dragObj.mm)
		})
	}
	mm2(ev: MouseEvent) {
		if (this.dragObj?.mm) {
			ev.preventDefault()
			let newTop = this.dragObj.itemTop + ev.clientY - this.dragObj.clientY
			if (newTop < 0) {
				newTop = 0
			}
			const lastTop = (this.disp.length - 1) * this.totalHEach
			if (newTop > lastTop) {
				newTop = lastTop
			}
			const orderOfItem = Math.floor(newTop / this.totalHEach)
			const item = this.disp.find((it) => it.id == this.dragObj.id)
			if (orderOfItem != item.order) {
				//move to lower number
				const other = this.disp.find((it) => it.order == orderOfItem)
				if (other) {
					//change the order for both,
					other.order = item.order
					item.order = orderOfItem
					//change top for OTHER
					const find = this.dragItems.find(
						(it) => it.nativeElement.id == other.id
					)
					if (find) {
						find.nativeElement.style.top = other.order * this.totalHEach + "px"
					}
				}
			}

			this.dragObj.el.style.top = newTop + "px"
		}
	}
	@HostListener("window:mouseup", ["$event"]) mu(ev: MouseEvent) {
		if (this.dragObj?.mm) {
			this.zone.runOutsideAngular(() => {
				window.document.removeEventListener("mousemove", this.dragObj.mm)
			})
			this.dragObj.el.style.zIndex = this.dragObj.el.style.zIndex - 1
			this.dragObj = null
			this.zones = [...this.disp]
			this.sort()
			this.save()
		}
	}

	async save() {
		const cc = this.store.getCliniqDataOrExit()
		cc.zones = this.disp.filter((z) => z.show).map((z) => z.id)

		const result: any = await this.apiService.post("update_configcliniq_data", {
			...cc,
			update: "user",
		})
		if (result && !result?.error) {
			//success
			this.modalService.openToast(this.lang.getVal("updated_successfully")) //open toast with success message
			sessionStorage.setItem("cliniqData", JSON.stringify(result)) //update the config object in the sessionStorage
		} else {
			//fail
			this.modalService.openToast(this.lang.getVal(result?.error)) //open toast with fail message
		}
	}
}
