<div>
	<app-mobile-tabs
		isHome
		[activeColumn]="showColumn"
		(onClick)="updateShowColumnLocal($event)"
	/>

	<div class="home-container row">
		<div
			[class.hidden-calendar]="
				eventsService.isMobile &&
				(showColumn | inArray: ['center', 'sideItems'])
			"
			class="col-12 col-lg-3"
		>
			<app-calendar
				isHome
				(loadingIsTrue)="isLoading = true"
				(outputAction)="calendarChange($event)"
			/>
			<!-- (evClick)="calendarClick($event)" (dayEvents)="dayEventsChange($event)" (refreshLastEv)="refreshLastEv()" -->
		</div>
		<div
			[hidden]="eventsService.isMobile && showColumn == 'calendar'"
			class="col-lg-9 followup-patient-on-home"
		>
			@if (fusObj?.user_id) {
				<app-banner
					[isLoading]="isLoading"
					[person]="user"
					type="user"
				/>
				<div class="row">
					<div
						[hidden]="
							eventsService.isMobile &&
							(showColumn | inArray: ['calendar', 'sideItems'])
						"
						class="col-12 col-lg-8 side-scroll user-home-meeting-wrapper"
						#center
					>
						@if (followup) {
							<app-home-meeting
								[followup]="followup"
								(moveToOtherFollowup)="moveToOtherFollowup($event)"
							/>
						}
					</div>
				</div>
			}

			@if (fusObj?.patient_id || !fusObj) {
				<app-patient
					#patientComponent
					[showColumn]="showColumn"
					isHome
					[patient]="patient"
					[followup]="followup"
					[isLoading]="isLoading"
					[timeStatus]="timeStatus"
					[dayEventsLength]="dayEvents.length"
					(moveToOtherFollowup)="moveToOtherFollowup($event)"
					(updateShowColumn)="showColumn = $event"
				/>
			}
		</div>
	</div>
</div>
