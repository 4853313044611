import {
	Component,
	EventEmitter,
	Input,
	Output,
	booleanAttribute,
} from "@angular/core"
import { HomePersonColumns, ReportItemType } from "@app/definitions/types"

@Component({
	selector: "app-mobile-tabs",
	template: `
		<ul
			[class.three-tabs]="isHome"
			class="d-lg-none d-flex justify-content-start mobile-tabs"
		>
			@for (tab of tabNames; track tab) {
				<li
					class="text-center"
					[class.active]="activeColumn == tab"
					(click)="onClick.emit(tab)"
				>
					{{ tabToLang[tab] | langVal }}
				</li>
			}
		</ul>
	`,
})
export class MobileTabsComponent {
	@Input() personType: ReportItemType = null
	@Input() activeColumn: HomePersonColumns
	@Input({ transform: booleanAttribute }) isHome: boolean = false
	@Output() onClick = new EventEmitter()
	tabNames: string[] = ["calendar", "center", "sideItems"]
	tabToLang: any = {
		calendar: "calendar",
		center: "followup",
		sideItems: "patient_file",
	}

	ngOnInit() {
		if (!this.isHome) {
			this.tabNames.shift()
			this.tabToLang = {
				center: this.personType,
				sideItems: this.personType + "_details",
			}
		}
	}
}
