import { Component, OnInit } from "@angular/core"
import * as moment from "moment"
import { ImportCommonComponent } from "./import-common/import-common.component"

@Component({
	selector: "app-import-followups",
	templateUrl: "./import-common/import-common.component.html",
})
export class ImportFollowupsComponent extends ImportCommonComponent {
	fields = [
		"",
		"patient_id",
		"date",
		"length",
		"name",
		"family_name",
		"name_en",
		"therapist",
		"meetingtype",
		"payor",
		"price",
		"description",
		"remarks",
	]
	headerLang: string = "import_followups"
	hasDate: boolean = true
	//
	dateFormat: "month_first" | "date_first" = "month_first"

	ngOnInit(): void {
		let cliniqData = this.store.getCliniqDataOrExit()
		if (cliniqData.import_followups_blocked == "yes") {
			this.isBlocked = true
		}
	}
	colChosen() {
		const requiredCol = Object.values(this.columns).find((col) => col == "date")
		this.error = requiredCol ? "" : "please_choose_date_column"
		this.isFormSubmittable = !!requiredCol
	}

	async import() {
		this.error = ""
		let data = []

		this.table.forEach((row) => {
			const dataRow = {}
			Object.keys(this.columns).forEach((key, pos) => {
				const col = this.columns[key]
				if (col) {
					const val = row[pos] || ""
					dataRow[col] = val
					if (this.dateFormat == "month_first" && col == "date") {
						const r = val.split("/")
						if (r.length != 3) {
							this.error = "wrong date format"
							return
						}
						dataRow[col] = r[1] + "/" + r[0] + "/" + r[2]
					}
				}
			})
			data.push({ row: dataRow })
		})
		if (this.error) {
			return
		}

		const format = "D/M/YYYY"

		const badDateFormatRow = data.find((obj) => {
			const row = obj.row
			const date = row?.date
			return !moment(date, format, true).isValid()
		})
		if (badDateFormatRow) {
			this.error = "invalid_date"
			return
		}

		data.sort((a, b) => {
			const dateA = moment(a.row?.date)
			const dateB = moment(b.row?.date)
			return dateA.isBefore(dateB) ? -1 : 1
		})
		// data.forEach(r => {

		// })

		// return;

		this.isSaving = true
		const res: any = await this.apiService.post("import_followups", {
			data: JSON.stringify(data),
		})
		this.isSaving = false
		await this.store.timeout(10)
		window.location.reload()
	}
}
