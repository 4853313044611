import {
	Directive,
	EventEmitter,
	Input,
	Output,
	booleanAttribute,
} from "@angular/core"
import { ConfigDefinitionsObj } from "./config"
import { Subject } from "rxjs"
import { AvailablePostPaths } from "@app/services/api.service"

export type yes_no_opts = "yes" | "no"
export type showOnlyFollowups_opts = "yes" | "no" | "unpaid"

export const ConfigDefinitions = ConfigDefinitionsObj

export interface AutoCompleteKeyUpObj {
	str: string
	isIdenticalToPreviousValue: boolean
}

// /#fffc9c
//E3ED00
export const patientColors = [
	"#eee7b6",
	"#d0a747",
	"#f7b2c2",
	"#ac85bc",
	"#699fca",
	"#c8e7d9",
	"#7c9c6b",
	"#E3ED00",
	"#c4ac78",
	"#c4abb4",
	"#92afcc",
	"#93cdce",
	"#a7d6a3",
	"#c0dc8f",
	"#c8c9a9",
	"#c2b39c",
	"#fee4cd",
	"#efe6e3",
	"#f4817e",
	"#cd92c1",
	"#9676b5",
	"#b4d9f3",
	"#a4d0d1",
	"#a0c2b4",
	"#7bc580",
	"#bab974",
	"#f2d440",
	"#baa88d",
	"#fbba89",
	"#c5a6a2",
	"#ebb6d4",
	"#a694c7",
	"#32a4d5",
	"#519d9f",
	"#c9e6d3",
	"#9bc09a",
	"#7e975d",
	"#cecac5",
	"#d0c491",
	"#fecb8b",
	"#c8ada0",
	"#f79f99",
	"#b9b8d0",
	"#a3c6df",
	"#97c2aa",
	"#6d9f71",
	"#cbdf7d",
	"#bcb0b3",
	"#ffd100",
	"#efd795",
	"#d3aa73",
	"#eaa380",
	"#f4817e",
	"#af607d",
	"#c399b2",
	"#a5b5dd",
	"#69a1c2",
	"#98bca6",
	"#85c776",
	"#adbb79",
	"#d2ced0",
	"#ffdd77",
	"#d4bb98",
	"#fab18a",
	"#f48b91",
	"#a54f70",
	"#bb8bb3",
	"#c7cce0",
	"#58b6da",
	"#c0caa0",
	"#6d7e8f",
	"#fab395",
	"#fcbd7a",
	"#f177ae",
	"#7c9fcd",
	"#6cb6ac",
	"#66b683",
	"#7eab73",
	"#8c9e54",
	"#f9c1d0",
	"#d1b5ad",
	"#f8b1b8",
	"#b191a6",
	"#ccd9ef",
	"#79bfd2",
	"#3eb871",
	"#5d9d62",
	"#c5b27d",
	"#bba4a0",
	"#cca7ce",
	"#7698c3",
	"#aedfe6",
	"#7ec2b1",
	"#5fac7c",
	"#d0e4a8",
	"#c8d068",
	"#c7d68f",
	"#ecca84",
	"#d3c2b4",
	"#f5896b",
	"#a678a8",
	"#7f99bc",
	"#86cad4",
	"#0bb89b",
	"#8cb698",
	"#89bb6e",
	"#b1b96a",
	"#b2a9a7",
	"#ffcf62",
	"#c87a8a",
	"#915b74",
	"#a3c7e9",
	"#5fc8d7",
	"#83baad",
	"#a3c083",
	"#a0a767",
	"#6e9c78",
	"#98c0c2",
	"#fbb475",
	"#fab8a5",
	"#f599b0",
	"#c6a7b2",
	"#906793",
	"#b6cae0",
	"#94c9d2",
	"#5fa871",
	"#b5c7aa",
	"#f3f094",
	"#a0a767",
]

export const insertPatientFields = [
	"name_en",
	"id_number",
	"year_of_birth",
	"date_of_birth",
	"number_of_kids",
	"marital_status",
	"parents_marital_status",
	"gender",
	"patienttype_id",
	"payor_id",
	"notification_default",
	"notifiy",
	"invoice_by_mail",
	"automatic_proforma",
	"remarks",
	"referer",
	"is_withholding_tax",
	"withholding_tax_percent",
	"tags",
	"address",
]
export const reportNames = [
	"income1",
	"follow_ups_report",
	"rent_report",
	"locations_report",
	"invoices_sub_user_report",
	"followups_sub_user_report",
	"followups_sub_user_rent_report",
	"text_report",
	"debt",
	"expenses_report",
	"proformas_report",
	"justinvoices_report",
	"irs_journal_report",
	"taxes_calc",
	"profitloss_report",
	"graphs",
]
export const reportNamesSolo = [
	"income1",
	"follow_ups_report",
	"contacts_meetings",
	"text_report",
	"debt",
	"expenses_report",
	"proformas_report",
	"justinvoices_report",
	"irs_journal_report",
	"taxes_calc",
	"profitloss_report",
	"graphs",
]
export const patientDetailsFillFields = [
	"name",
	"id_number",
	"email",
	"phone",
	"marital_status",
	"gender",
	"remarks",
]

export const reportVisFields = {
	invoices_report: [
		"date",
		"item_name",
		"total",
		"vat",
		"totalMinusvat",
		"model_name",
		"paid_by_finresource",
		"sendgrid_status",
		"emails",
		"payment_method",
		"cheque_num",
	],
	invoices_report_by_payment_method: [
		"date",
		"repayment_date",
		"total",
		"item_name",
		"model_name",
		"payment_method",
		"cheque_num",
	],
	admininvoices_report: [
		"date",
		"total",
		"vat",
		"totalMinusvat",
		"paid_by",
		"sendgrid_status",
		"payment_method",
	],
	followups_report: [
		"date",
		"item_name",
		"assoc",
		"arrived",
		"meetingtype_name",
		"group_name",
		"paid_by",
		"price",
		"price_patient",
		"payor_name",
		"price_payor",
		"total",
		"vat",
		"sendgrid_status",
		"paid_by_invoice",
		"invoice_id",
	],
	followups_text_report: [
		"date",
		"item_name",
		"assoc",
		"meetingtype_name",
		"arrived",
		"description",
		"remarks",
	],
	followups_all_report: [
		"date",
		"item_name",
		"assoc",
		"arrived",
		"meetingtype_name",
		"paid_by",
		"price",
		"total",
		"vat",
		"invoice_id",
		"deleted_remarks",
	],
	followups_sub_user_report: [
		"date",
		"item_name",
		"assoc",
		"arrived",
		"meetingtype_name",
		"price",
		"price_patient",
		"price_payor",
		"tarif_name",
		"tarif_percent",
		"sub_user_tarif",
		"sub_user_tarif_paid",
	],
	invoices_sub_user_report: [
		"date",
		"item_name",
		"assoc",
		"total",
		"vat",
		"totalMinusvat",
		"model_name",
		"sendgrid_status",
		"emails",
	],
	followups_sub_user_rent_report: [
		"date",
		"item_name",
		"assoc",
		"location",
		"arrived",
		"meetingtype_name",
		"time",
		"time_to",
		"length",
		"is_deleted",
		"deleted_remarks",
		"is_private",
		"count_is_private_yes",
		"count_is_private_no",
	],
	debts_report: [
		"date",
		"item_name",
		"assoc",
		"arrived",
		"meetingtype_name",
		"paid_by",
		"price",
		"price_patient",
		"price_payor",
		"payor_name",
		"total",
		"vat",
		"paid_by_invoice",
		"id",
		"debtUntilDate",
		"debtUntilNow",
	],
	debts_report2: ["date", "display_name", "price", "num"],
	real_debt_report: ["item_name", "debtUntilNow"],
	proformas_report: [
		"item_name",
		"total",
		"vat",
		"totalMinusvat",
		"period",
		"paid_by_invoice",
		"created_at",
		"sendgrid_status",
	],
	justinvoices_report: [
		"item_name",
		"total",
		"vat",
		"totalMinusvat",
		"period",
		"paid_by_invoice",
		"created_at",
		"sendgrid_status",
	],
	expenses_report: [
		"date",
		"description",
		"expensetype_name",
		"sum",
		"expense_without_vat",
		"expense_vat",
		"expensetype_vat_percent",
		"expense_vat_recognized",
		"tax_percent",
		"expense_recognized_for_tax",
		"expense_recognized_for_tax2",
		"supplier_name",
		"supplier_number",
		"supplier_invoice",
		"created_at_render",
	],
	payorprices_report: [
		"id",
		"payor_name",
		"meetingtype_name",
		"price_payor",
		"unit",
		"active",
	],
	rent_report: [
		"id",
		"contact_name",
		"date",
		"location",
		"price",
		"time",
		"time_to",
		"length",
		"is_deleted",
		"deleted_remarks",
	],
	locations_report: [
		"date",
		"location",
		"time",
		"time_to",
		"length",
		"id",
		"name",
	],
}

export const usersColor = [
	//  Red (hex #FF0000)Orange (#FFC000)red-orange (#ff2a00)Green (#FF0000)Cyan (#00FFFF)Magenta (#FF0000)
	"#fbfbf0",
	"#006aff",
	"#aeff00",
	"#3b4bff",
	"#00ffea",
	"#00ffb3",
	"#32cf7e",
	"#00ff88",
	"#fa9395",
	"#00ffd0",
	"#fc8b74",
	"#c7fcee",
	"#00FFFF",
	"#FF00FF",
	"#fc0303",
	"#ae9dfa",
	"#c1ffa6",
	"#e1ff00",
	"#00ddff",
	"#6a00ff",
	"#22ff00",
	"#3280cf",
	"#e5ed53",
	"#f786f4",
	"#a3039f",
	"#8d09eb",
	"#5f42ed",
	"#53ed42",
]
//#595cf7
export const documentTemaplateBtns: string[] = [
	"name",
	"id_number",
	"followup",
	"followups",
	"future_meetings",
	"diagppatients",
	"labtestpatients",
	"medicpatients",
	"nondrugpatients",
	"address",
	"marital_status",
	"age",
	"payor",
	"email",
	"phone",
	"remarks",
]

export type TimeInpMode = null | "m" | "h"
export type FinResourcePeriods =
	| "date_range"
	| "this_year"
	| "this_month"
	| "month_before"
	| "this_week"
	| "today"
	| "all_options"

export type FusUpdateModes = "duplicate" | "delete" | "update"
export type LoginComponentModes =
	| "login"
	| "regi"
	| "forgot-password"
	| "reset-password"
	| "expired-password"
	| "regi-otp"
	| "greeting"
	| "sub-user-regi"
	| "potential-user-regi"
export type FinResourceType =
	| ""
	| "invoice"
	| "proforma"
	| "prescription"
	| "document"
	| "admininvoice"
	| "justinvoice"
	| "receipt"
	| "credit_invoice"
	| "credit_receipt"
	| "credit_admininvoice"
	| "credit_justinvoice"
	| "selfinvoice"

export class Announce {
	id: number = 0
	an_lang: string = "iw"
	subject: string = ""
	message: string = ""
	remarks: string = ""
	errors?: any = {}
}
export class Autotext {
	id: number = 0
	name: string = ""
	active: string = "yes" //  'no',
	html_autotext: string = ""
	field_id: string = ""
	remarks: string = ""
	showText?: boolean = false
}
export class Bankaccount {
	id: number = 0
	patient_id: number = 0
	payor_id: number = 0
	contact_id: number = 0
	branch_id: number = 0
	bank_code: number = 0
	number: string = ""
	name: string = ""
	is_foreign: string = "no" //  'no',
	foreign_bank: string = ""
}

export class Bank {
	id: number = 0
	name: string = ""
	code: string = ""
}
export class MixedListItem {
	id: number = 0
	type: string = ""
	date: string = ""
	time: string = ""
	price: string = ""
	description: string = ""
	supervisor_notes: string = ""
	arrived: string = ""
	followuptype_name: string = ""
	//key: false ??
	meetingtype_name: string = ""
	personal_memorandum: string = ""
}
export class FollowupsUpdateListItem {
	id: number = 0
	patient_id: number = 0
	is_group: string = ""
	date: string = ""
	time: string = ""
	price: string = ""
	price_payor: string = ""
	arrived: string = ""
	charged: string = ""
	takes_place: string = ""
	payor_id: number = 0
	meetingtype_id: number = 0
}

export class SubUserPayments {
	id: number = 0
	user_id: number = 0
	month: string = ""
	sum: string = ""
	expense_id: number = 0
}
export class FinResourceItem {
	id: number = 0
	doctype: FinResourceType = "" // invoice,justinvoice,proforma, receipt
	resource: string = "" // patient,contact , payor
	date: string = ""
	time: string = ""
	price: string = ""
	description: string = ""
	arrived: string = ""
	followuptype_name: string = ""
	//key: false ??
	meetingtype_name: string = ""
	personal_memorandum: string = ""
}
export class Paymentapp {
	id: number = 0
	name: string = ""
	more: string = ""
}
export class Branch {
	id: number = 0
	name: string = ""
	code: string = ""
	bank_id: number = 0
}

export class taxesList {
	total_with_vat: number = 0
	total_invoice_total_without_vat: number = 0
	total_invoice_tax_downpayment: number = 0
	total_invoice_vat: number = 0
	total_expenses: number = 0
	total_expense_vat_recognized: number = 0
	total_expense_recognized: number = 0
	total_expense_tax_not_permanent: number = 0
	total_invoice_vat_exempt: number = 0
	total_invoice_vat_required: number = 0
	total_expense_vat_permanent: number = 0
	tax_percent_downpayment: number = 0
	vat_for_payment: number = 0
	tax_for_payment: number = 0
	total_expense_vat_not_permanent: number = 0
}

export class BkmvdataList {
	number: number = 0
	type: string = ""
	amount: number = 0
	sum: number = 0
}
export class Configcliniq {
	id: number = 0
	profession: string = ""
	meeting_length: number = 0
	show_payment_details: string = "" //  'no',
	invoice_per_followup: string = "no" //  'no',
	show_manual_charge: string = "" //  'no',
	withoutvat: string = "" //  'no',
	vat: number = 0
	defaultpatienttype: number = 0
	default_price: number = 0
	default_font_size: number = 0
	default_font_for_documents: string = ""
	items_patient_document: number = 0
	items_patient_address: number = 0
	items_patient_email: number = 0
	items_patient_phone: number = 0
	items_patient_pcontactway: string = ""
	invoice_num: number = 0
	proforma_num: number = 0
	justinvoice_num: number = 0
	receipt_num: number = 0
	paginate_normal: number = 0
	license_number: string = ""
	street: string = ""
	house: string = ""
	city: string = ""
	zip: string = ""
	fax: string = ""
	email: string = ""
	vat_number: string = ""
	tax_account_type: string = "" //op','hp','om')  NULL,
	tax_number: string = ""
	break_length: number = 0
	guest_sched_hours_to_add: number = 0
	time_to_logout: number = 0
	calendar_rows_: number = 0
	calendar_start_time: string = ""
	calendar_end_time: string = ""
	date_start_accounting: string = ""
	accountant_frequency: string = "" //never','monthly','2monthly')  'never',
	accountant_file_type: string = "" //pdf','csv','dat','xlsx','unified')  'pdf',
	accountant_email: string = ""
	date_sent_to_accountant: string = ""
	accountant_every_copy: string = "" //no','yes')  'no',
	account_in_hashav: number = 0
	account_in_hashav_cash: number = 0
	account_in_hashav_cheque: number = 0
	account_in_hashav_credit: number = 0
	series_for_hashav_patient: number = 0
	account_in_hashav_patient_all: number = 0
	account_in_hashav_vat: number = 0
	account_in_hashav_income: number = 0
	account_in_hashav_expenses: number = 0
	series_for_hashav_contact: number = 0
	series_for_hashav_payor: number = 0
	send_original_to_user: string = "" //no','yes')  'yes',
	time_on_holiday_evening: string = ""
	lang: string = ""
	application_link: string = ""
	bank_account_text: string = ""
	consent_to_sms_contract: string = "no"
	name_on_message: string = ""
	time_of_sms: string = "evening"
	days_before_meeting_message: number = 1
	full_address: string = ""
	address_web: string = ""
	name_web: string = ""
	title_web: string = ""
	email_web: string = ""
	languages: string = ""
	website: string = ""
	expertise: string = ""
	image: string = ""
	is_new_vat_number?: string = "no"
	show_patient_id: string = "no"
	show_insert_row: string = "no"
	show_future_meetings: string = "no"
	show_search_by_phone: string = "no"
	import_followups_blocked: string = "yes"
	expenses_by_created_at_default: string = "no"
	expenses_by_former_month: string = "no"
	budget_for_payors: string = "no"
	login_to_weekly_view: string = "no"
	mixed_list_period: string = null
	mixed_list_pm: string = null
	enable_israel_invoice: string = "no"
	enable_invoice_number_edit: string = "no"
	credit_clearance: string = null
	sms_real_debt: string = "no"
	wide_logo: string = "no"
	calc_age_by_months: string = "no"
	id_number_on_patient_banner: string = "no"
}
export class SubUserConfigcliniq {
	id: number = 0
	name: string = ""
	title: string = ""
	profession: string = ""
	meeting_length: number = 0
	withoutvat: string = "" //  'no',
	default_font_size: number = 0
	license_number: string = ""
	street: string = ""
	house: string = ""
	city: string = ""
	zip: string = ""
	fax: string = ""
	email: string = ""
	vat_number: string = ""
	tax_account_type: string = "" //op','hp','om')  NULL,
	tax_number: string = ""
	break_length: number = 0
	guest_sched_hours_to_add: number = 0
	time_to_logout: number = 0
	lang: string = ""
	show_personal_memorandum: string = "no"
	budget_for_payors: string = "no"
	sms_real_debt: string = "no"
	wide_logo: string = "no"
	calc_age_by_months: string = "no"
	id_number_on_patient_banner: string = "no"
}

export class City {
	id: number = 0
	name: string = ""
	errors?: any = {}
}

export class Contact {
	id: number = 0
	name: string = ""
	message: string = ""
	contacttype_id: number = 0
	status: string = "yes"
	account_in_hashav: number = 0
	notifiy: string = "no"
	withholding_tax_percent: number = 0
	is_withholding_tax: string = "no"
	notification_default: string = "email"
	notification_time: string = "day_before"
	errors?: any = {}
}
export class Contacttype {
	id: number = 0
	name: string = ""
	code: string = ""
	errors?: any = {}
}

export class Contactway {
	id: number = 0
	contact_id: number = 0
	patient_id: number = 0
	payor_id: number = 0
	name: string = ""
	contactway: string = ""
	contact_id_to: number = 0
	description: string = ""
	contactway_identifier: string = "email"
	main: string = "no"
	errors?: any = {}
	warning_status?: string = null
	inValidation?: boolean = true
	subj?: Subject<any> = null
}

export class Apotropus {
	id: number = 0
	patient_id: number = 0
	contactway_id: number = 0
	name: string = ""
	relation: string = ""
	start_date: string = ""
	end_date: string = null
	remarks: string = ""
	main: string = "no"
}
/*id  patient_id,contactway_id,name,id_number,relation`,start_date`,end_date,remarks*/

export class Creditcard {
	id: number = 0
	name: string = ""
	more: string = ""
}

export class Creditcomp {
	id: number = 0
	name: string = ""
	address: string = ""
	more: string = ""
}

export class Credittype {
	id: number = 0
	name: string = ""
	more: string = ""
}
export class ZoneRow {
	id: number = 0
	name: string = ""
	show: boolean = true
	order: number = 0
}

export class Diagp {
	id: number = 0
	name: string = ""
	code: string = ""
	sort_code: number = 0
}

export class Diagppatient {
	id: number = 0
	diagp_id: number = 0
	patient_id: number = 0
	diagp_name: string = ""
	diagp_code: string = ""
	code: string = ""
	dx_status: string = ""
	start_date: string = ""
	end_date: string = null
	remarks: string = ""
}
export class Dblog {
	id: number = 0
	u_id: number = 0
	php_sapi_name: string = ""
	level: string = ""
	url: string = ""
	message: string = ""
	context: string = ""
}

export class Medilog {
	id: number = 0
	patient_id: number = 0
	resource_id: number = 0
	resource: string = ""
	url: string = ""
	message: string = ""
	remarks: string = ""
	created_at: string = ""
	updated_at: string = ""
}

export class Medicalog {
	id: number = 0
	patient_id: number = 0
	resource_id: number = 0
	resource: string = ""
	url: string = ""
	message: string = ""
	created_at: string = ""
	updated_at: string = ""
}
export class Document {
	id: number = 0
	signed: string = "no" //  'no',
	patient_id: number = 0
	followup_id: number = 0
	headture_id: number = 0
	date: string = ""
	title: string = ""
	doc_name: string = ""
	recipient: string = ""
	contact_id: number = 0
	doc_type: string = "" //upload','html')  NULL,
	html_content: string = ""
	path: string = ""
	remarks: string = ""
	size: number = 0
	mime: string = ""
	extension: string = ""
	from: string = ""
	signature: string = ""
	address: string = ""
	medical_conf: string = "yes" //  'yes',
	mode: string = ""
	selectedHeadtureId: number = 0
	default_signature: string = ""
	image_ids?: string = ""
	images?: any[] = []
}
export class Documenttemplate {
	id: number = 0
	title: string = ""
	html_content: string = ""
	header: string = ""
	signature: string = ""
	recipient: string = ""
}
export class Expense {
	id: number = 0
	expensetype_id: number = 0
	supplier_id: number = 0
	user_id: number = 0
	description: string = ""
	supplier_invoice: string = ""
	date: string = ""
	sum: string = ""
	withvat: string = "yes"
}
export class Faq {
	id: number = 0
	faq_subject_id: number = 0
	question: string = ""
	answer: string = ""
}

export class Faq_subject {
	id: number = 0
	name: string = ""
	lang: string = ""
}

export class Expensegroup {
	id: number = 0
	name: string = ""
}
export class Patientfield {
	id: number = 0
	name: string = ""
	show: string = "yes"
	order: number = 0
}

export class Expensetype {
	expensegroup_id: number = 0
	explanation: string = ""
	id: number = 0
	name: string = ""
	percent_for_tax: number = 0
	percent_for_vat: number = 0
	permanent: string = "no"
	withvat: string = "yes"
	unit: string = ""
	errors?: any = {}
}
export class Scheduled_report {
	id: number = 0
	contact_id: number = 0
	report_name: string = "invoices"
	send_freq: string = "1month"
	file_format: string = "xlsx"
	date_in_month: number = 1
	errors?: any = {}
}

export class AccountantScheduledReport {
	contact_id: number = 0
	name: string = "" //
	email: string = "" //
	is_new_contact: string = "no" //
	send_freq: string = "1month"
	file_format: string = "xlsx"
	date_in_month: number = 3
	invoices: boolean = true
	payment_method: boolean = true
	expenses: boolean = true
	profitloss: boolean = true
	taxes: boolean = true
}

export class Holiday {
	id: number = 0
	active: string = "no"
	holiday_group: string = ""
	name: string = ""
	description: string = ""
	length: string = ""
	time_from: string = ""
	time_to: string = ""
	time_from_description: string = ""
	time_to_description: string = ""
}

export class Holiday_instance {
	id: number = 0
	holiday_id: number = 0
	date: string = ""
	date_to: string = ""
}
export class Followup {
	id: number = 0
	followup_id: number = 0
	person_name: string = ""
	patient_id: number = 0
	meetingtype_name?: string = ""
	contact_id: number = 0
	start_unix: number = 0
	end_unix: number = 0
	date: string = ""
	time: string = ""
	date_to: string = ""
	time_to: string = ""
	start_day: number = 0
	end_day: number = 0
	length: number = 0
	is_all_day: string = "" //  NULL,
	series_repeat: string = "" //one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly')  NULL,
	repeat_days: number = 0
	description: string = ""
	remarks: string = ""
	personal_memorandum: string = ""
	supervisor_notes: string = ""
	followuptype_id: number = 0
	meetingtype_id: number = 0
	payor_id: number = 0
	price: number = 0
	price_payor: number = 0
	price_patient: number = 0
	sub_user_tarif: number = 0
	takes_place: string = "" //  NULL,
	charged: string = "" //  NULL,
	charged_manualy: string = "" //  'no',
	charged_manualy_payor: string = "" //  'no',
	arrived: string = "" //yes','no','canceled')  'yes',
	invoice_id: number = 0
	finresource_type = ""
	invoice_id_patient = 0
	invoice_id_payor = 0
	finresource_type_payor = ""
	series_id: number = 0
	series_one_of: number = 0
	series_total: number = 0
	status: string = "" //active','deleted')  NULL,
	done: string = "" //  'no',
	updated_at: string = ""
	updated_at_display: string = ""
	images: Fp_image[] = []
}

export class Status {
	generalDescription: string = ""
	consciousness: string = ""
	orientation: string = ""
	affect: string = ""
	thoughtProcess: string = ""
	content: string = ""
	suicidality: string = ""
	delusions: string = ""
	hallucinations: string = ""
	judgement: string = ""
	insight: string = ""
	intelligence: string = ""
	memory: string = ""
	id: number = 0
	patient_id: number = 0
}

export class Followupserie {
	id: number = 0
	patient_id: number = 0
	contact_id: number = 0
	start_unix: number = 0
	end_unix: number = 0
	date: string = ""
	time: string = ""
	date_to: string = ""
	time_to: string = ""
	start_day: number = 0
	end_day: number = 0
	series_limit_date: string = ""
	length: number = 0
	is_all_day: string = "" //  NULL,
	series_repeat: string = "" //one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly')  NULL,
	repeat_days: number = 0
	notes: string = ""
	meeting_title: string = ""
	personal_memorandum: string = ""
	followuptype_id: number = 0
	meetingtype_id: number = 0
	meetingtype_color: string = ""
	price: number = 0
	price_payor: number = 0
	takes_place: string = "" //  'yes',
	charged: string = "" //  NULL,
	series_id: number = 0
	status: string = "" //active','deleted')  'active',
	conflictReasons: ConflictReason[] = []
}
export class Followuptype {
	id: number = 0
	name: string = ""
	length: number = 0
	price: number = 0
	is_default: string = "yes"
	is_all_day: string = "no"
	takes_place: string = "yes"
	errors?: any = {}
}

export class Groupattend {
	id: number = 0
	followup_id: number = 0
	group_id: number = 0
	patient_id: number = 0
	arrived: string = "yes"
	start_date: string = ""
	remarks: number = 0
	charged_manualy: string = "" //  'no',
	external_invoice_id: number = 0
}

export class Grouppatient {
	id: number = 0
	group_id: number = 0
	user_id: number = 0
	patient_id: number = 0
	price: number = 0
	remarks: string = ""
}

export class Headture {
	id: number = 0
	name: string = ""
	header: string = ""
	signature: string = ""
	header_default: string | null = null //i','p','l','n')  NULL,
	logo: string = ""
	lang: string = "iw"
	header_text: string = ""
	signature_text: string = ""
	template_id: number = 0
	areas?: string = ""
}

export class Labtest {
	id: number = 0
	name: string = ""
	code: string = ""
	normal_range: string = ""
}
export class Referer {
	id: number = 0
	name: string = ""
}

export class PaymentFormObject {
	user_name: string
	currency_symbol: string
	monthlyPrice: string
	price: any
	createdAt: string
	last_payment_date: string
	last_payment_paid: string
	iframeLink: string
}
export class ClientPaymentFormObject {
	user_name: string
	patient_name: string
	currency_symbol: string
	monthlyPrice: string
	sum: any
	createdAt: string
	last_payment_date: string
	last_payment_paid: string
	iframeLink: string
	has_token: boolean = false
}

export class Labtestpatient {
	id: number = 0
	labtest_id: number = 0
	patient_id: number = 0
	labtest_name: string = ""
	date: string = ""
	result: string = ""
}
export class Refererpatient {
	id: number = 0
	referer_id: number = 0
	patient_id: number = 0
}
export class Medic {
	id: number = 0
	reg_no: string = ""
	trade_name: string = ""
	route_of_admin: string = ""
	dosage_form: string = ""
	health_basket: string = "" //true','false')  NULL,
	amount: number = 0
	unit: string = ""
	atc5_name: string = ""
	atc5_code: string = ""
	sort_code: number = 0
	amount_array: string = ""
}
export class Medicpatient {
	id: number = 0
	medic_id: number = 0
	patient_id: number = 0
	medic_name: string = ""
	start_date: string = ""
	end_date: string = null
	amount: string = ""
	amount_output: string = ""
	frequency: string = ""
	frequency_unit: string = "day"
	level: string = ""
	total_number: number = 0
	change: string = "no"
	permanent_usage: string = "yes"
	iterations: number = 0
	remarks: string = ""
	atc5: string = ""
}

export class Meetingtype {
	id: number = 0
	name: string = ""
	length: number = 50
	price: number = 0
	color: string = ""
	is_default: string = "no"
	countable: string = "no"
	first_time_meeting_request: string = "no"
	return_meeting_request: string = "no"
	meeting_text: string = ""
	errors?: any = {}
}
export class Meeting_request {
	id: number = 0
	name: string = ""
	date: string = null
	time: string = null
	meetingtype_id: number = 0
	email: string = ""
	phone: string = ""
	remarks: string = ""
	errors?: any = {}
}
export class Nondrug {
	id: number = 0
	name: string = ""
	code: string = ""
}
export class Nondrugpatient {
	id: number = 0
	nondrug_id: number = 0
	patient_id: number = 0
	nondrug_name: string = ""
	remarks: string = ""
	start_date: string = ""
	end_date: string = null
	contact_id: number = 0
}
export class Notification {
	id: number = 0
	date: string = ""
	time: string = ""
	toname: string = ""
	patient_id: number = 0
	contact_id: number = 0
	payor_id: number = 0
	series_id: number = 0
	email_phone: string = ""
	subject: string = ""
	message: string = ""
	attachment: string = ""
	remarks: string = ""
	from_patient: string = "" //true','false')  'false',
	type: string = "" //chat','self_reminder','reminder','auto_message','document','invoice','proforma','prescription','document_copy','invoice_copy','proforma_copy','prescription_copy','invitation')  'chat',
	type_id: number = 0
	status: string = "" //open','closed')  'closed',
	postpone: string = ""
	read_at: string = ""
	loaded_at: string = ""
	sendgrid_id: string = ""
	sendgrid_status: string = ""
}
export class Smslog {
	id: number = 0
	date: string = ""
	time: string = ""
	patient_id: number = 0
	contact_id: number = 0
	payor_id: number = 0
	phone: string = ""
	status: string = "" //open','closed')  'closed',
}

export class AddContactwayObject {
	email_is_new: boolean = false
	email: string = ""
	phone_is_new: boolean = false
	phone: string = ""
	patient_id: number = null
}

export class Ownerbankaccount {
	id: number = 0
	branch_id: number = 0
	bank_code: number = 0
	number: string = ""
	name: string = ""
	account_in_hashav: number = 0
}

export class Patient {
	id: number = 0
	name: string = ""
	id_number: string = ""
	name_en: string = ""
	year_of_birth: string = ""
	marital_status: string = ""
	parents_marital_status: string = ""
	age: string = ""
	state: string = ""
	gender: string = ""
	number_of_kids: number = 0
	active: string = "yes" //  'yes',
	invoice_by_mail: string = "yes" //  'yes',
	automatic_proforma: string = "no" //  'yes',
	patientprice: number = 0
	patienttype_id: number = 0
	is_group: string = "no" //  'no',
	payor_id: number = 0
	color: number = 0
	country_id: string = ""
	account_in_hashav: number = 0
	notifiy: string = "yes" //  'no',
	is_withholding_tax: string = "no" //  'no',
	withholding_tax_percent: number = 0
	notification_default: string = "sms" //whatsapp','email','sms')  'email',
	notification_time: string = "day_before" //day_before','meeting_day')  'day_before',
	contactway: string = ""
	remarks: string = ""
	assoc: number[] = []
	date_start_therapy: string = ""
	date_of_birth: string = ""
	created_at: string = ""
	updated_at: string = ""
	charge_mode: string = "by_participant"
	payment_mode: string = "by_attending"
	referrer_user_id: number = null
	status: string = "inquirer"
	filled_stage: string = ""
	contactways: Contactway[]

	// assoc_name: string = "";
}
export class UpdateGroup {
	id: number = 0
	name: string = ""
	id_number: string = ""
	price: number = 0
	patientprice: number = 0
	state: string = ""
	active: string = "yes" //  'yes',
	patienttype_id: number = 0
	is_group: string = "yes" //  'no',
	payor_id: number = 0
	color: number = 0
	assoc: number[] = []
	chargeModeOpts: string = "by_participant"
	paymentModeOpts: string = "by_attending"
}
export class Payorprice {
	id: number = 0
	payor_id: number = 0
	meetingtype_id: number = 0
	//price_for_meeting: number = 0;
	price_payor: number = 0
	unit: string = "amount" //percent','amount')  'amount',
	price_cancellation: number = 0
	unit_cancellation: string = "amount" //percent','amount')  'amount',
	active: string = "yes" //  'yes',
}

export class Tarif {
	id: number = 0
	name: string = ""
	price_for_meeting: number = 0
	unit: string = "amount"
	assoc_payment: string = "to_pay"
	active: string = "yes" //  'yes',
}

export interface FuzzySearchObj {
	apiCall?: AvailablePostPaths
	outerFilterName?: string
	innerFilterName?: string
	minLength?: number
	otherFieldsToUpdate?: string[]
}
export interface PatientFormObj {
	id: number
	name: string
	date: string
	questions: { q: string; a: string }[]
	answers: any[]
}
export interface PatientTestObj {
	id: number
	name: string
	date: string
	dateFullStr: string
	questions?: { q: string; a: string; order_num?: number }[]
	answers?: any[]
	test_id?: number
	order_num?: number
	tests?: PatientTestObj[]
	hasMultipleFills?: boolean
}

export class Payor {
	id: number = 0
	name: string = ""
	address: string = ""
	account_in_hashav: number = 0
	withholding_tax_percent: number = 0
	is_withholding_tax: string = "no" //  'no',
	bn_number: number = 0
	budget_sum: number = 0
	budget_percent_for_alert: number = 0
	budget_date: string = ""
	budget_usage: number = 0
	budget_usage_percent: number = 0
}
export class Program {
	id: number = 0
	name: string = ""
	description: string = ""
	price: number = 0
	duration: number = 0 // 'in days',
	status: number = 0
	image: string = ""
}

export class Patienttype {
	id: number = 0
	name: string = ""
	is_group: string = "no"
}
export class Site {
	id: number = 0
	name: string = ""
	errors?: any = {}
}
export class Inquiry_status {
	id: number = 0
	name: string = ""
	errors?: any = {}
}
export class Supplier {
	id: number = 0
	name: string = ""
	address: string = ""
	number: number = 0
	phone: string = ""
	email: string = ""
	expensetype_id: number = 0
	account_in_hashav: number = 0
	active: string = "yes" // COLLATE utf8_unicode_ci NOT NULL DEFAULT 'yes',
}

export class Tax {
	id: number = 0
	name: string = "" //vat,income_tax,social_security,withholding_tax
	valid_from: string = ""
	valid_to: string = ""
	percent: number = 0
}
export class User {
	id: number = 0
	user_name: string = ""
	name: string = ""
	title: string = ""
	email: string = ""
	db: string = ""
	password: string = ""
	phone: string = ""
	phone_mobile: string = ""
	otp_token: string = ""
	otp_time: string = ""
	otp_retry: number = 0
	otp_sent_today: number = 0
	sms_sent: string = "" //no','yes')  'no',
	otp_sent_today_time: string = ""
	role: number = 0
	comments: string = ""
	organization_id: number = 0
	program_id: number = 0
	continuous_charge: number = 0
	image: string = ""
	about: string = ""
	reminder_token: string = ""
	remember_token: string = ""
	last_login: string = ""
	user_email_consent: string = "" //no','yes')  'no',
	change_invoice_num: string = "" //no','yes')  'no',
	first_use: string = "" //  'yes',
	active: string = "yes" //  'yes',
	status: string = "" //free','3months_free','paying','suspended')  '3months_free',
	lang: string = ""
	unsubscribed: number = 0
	FirstPaymentEmail: string = ""
	SecondPaymentEmail_copy1: string = ""
	unsubscribed_token: string = ""
	color_hex: string = ""
	MaxNumberOfPayments?: number = 1
}

export class User_program {
	user_id: number = 0
	program_id: number = 0
	active: string = "" //'active',
	created_at: string = ""
}
export class Clinic_role {
	name: string = ""
}
export class Sub_user {
	name: string = ""
	user_name: string = ""
	tarif_id: number = 0
	location_id: number = 0
	email: string = ""
	phone_mobile: string = ""
	perms_role: number = null
	active: string = "no"
}
export class Fp_image {
	id?: number = 0
	patient_id: number = 0
	followup_id: number = 0
	image: string = ""
	url?: string = ""
	isDeleting?: boolean = false
}
export class Tag {
	id: number = 0
	name: string = ""
	lang: string = "iw"
	description: string = ""
	errors?: any = {}
}

export class Pricingtype {
	id: number = 0
	name: string = ""
	monthly: string = ""
	errors?: any = {}
}
export class Location {
	id: number = 0
	name: string = ""
	is_conflicting: string = "yes"
	errors?: any = {}
}

export class Sub_user_tarif {
	id: number = 0
	user_id: number = 0
	tarif_id: number = 0
	active: string = ""
	errors?: any = {}
}

export class Object_to_sub_user {
	id: number = 0
	item_id: number = 0
	user_id: number = 0
	item_type: string
	active: string = "yes"
}

export interface SearchSortable {
	searchStr: string
	sortField: string
	sortIsAsc: boolean
	numberFields: string[]
	searchableFields: string[]
}

export interface Searchable {
	searchStr: string
	searchableFields: string[]
}

export class exportParamsObj {
	exp_start_date: string = ""
	exp_end_date: string = ""
	doc_type: string = "pdf"
	source_doc_type: string = "invoice"
}
export type ReportItemType = "payor" | "patient" | "contact" | ""
export class reportParamsObj {
	patient_id: number = 0
	meetingtype_id: number = 0
	patienttype_id: number = 0
	expensetype_id: number = 0
	payor_id: number = 0
	contact_id: number = 0
	patient_name: string = ""
	from_date: string = ""
	to_date: string = ""
	report_name: string = ""
	report_period_input: string = "month_before"
	report_break_input: string = "no_breaks"
	is_ng: boolean = true
	app_locale: string = "iw"
	calcIncomeAsNextMonth: boolean = false
	item_id: number = 0
	item_type: ReportItemType = ""
	showItemNumberAsName: yes_no_opts = "no"
	invoices_report_by_opts = "invoices_report"
	debts_report_opts = "debts_report"
	showEmails: yes_no_opts = "no"
	until_now: yes_no_opts = "yes"
	showByCreatedAt: yes_no_opts = "no"
	showOnlyFollowups: showOnlyFollowups_opts = "unpaid"
	individualView: ReportIndividualViewOpts = "all_options"
	vat_number: string = ""
	taxesReportMode: string = ""
	location_tag_ids: number[] = []
	user_tag_ids: number[] = []
}

export class FollowupserieSave {
	id: number = 0
	is_create_overlap_exceptions: string = "no" //reserved
	change_series: string = "only_one" //in create
	ser_id: number = 0
	followupserie_series_id: number = 0
	followupserie_id: number = 0
	followuptype_id: number = 1
	meetingtype_id: number = 0
	followupserie_time: string = ""
	followupserie_time_to: string = "" //either calc or chosen
	followupserie_date: string = ""
	followupserie_date_to: string = ""
	current_original_date: string = ""
	current_original_date_to: string = ""
	length: number = 0
	price: number = 0
	meeting_with: string = "patient" //patient/contact/meeting
	is_group: string = "no" //yes if multiple participants
	patient_id: number = 0
	user_id: number = 0
	contact_id: number = 0
	charged: string = "yes" //or no
	series_repeat: string = "one_time"
	repeat_days: number = 0
	takes_place: string = "yes" //or no
	meeting_on_holiday: string = "no"
	series_limit_date: string = ""
	patient_name: string = "" //existing
	is_all_day: string = "no" //not in use
	name: string = "" //new
	new_phone_input: string = "" //new
	notifiy: string = "no" //new
	notification_default: string = "sms" //new
	payor_id: number = 0 //new
	is_new_patient_or_contact: string = "no" //
	meeting_title: string = ""
	followupserie_du_remarks: string = ""
	followupserie_du_message: string = ""
	followupserie_until_date: string = ""
	notes: string = ""
	mode: string = "add"
	start_unix: number
	assoc_sub_user_ids: string[] = []
	location_id: number = 0
	tarif_id: number = 0
	start_day: number = 0
	vacation_all_users: yes_no_opts = "no"
	is_assoc: yes_no_opts = "yes"
	time: string = ""
	time_to: string = ""
	meeting_request_id: number = 0
	invoice_id_patient: number = null
}

export class InvoiceLine {
	hash: number = 0
	paymentmethod: string = "cash"
	sum: number = 0
	value_date: string = ""
	account_num?: number = 0
	repayment_date?: string = ""
	cheque_num?: string = ""
	credit_card_4_digits?: string = ""
	credit_card_company?: number = 0
	credit_card?: number = 0
	credittype?: number = 0
	paymentapp_id?: number = 0
	transaction_id?: string = ""
	errors?: any = {}
	creditcard?: any = null
	paymentapp?: any = null
	bank_name?: string = ""
	branch_name?: string = ""
}

export class AdmininvoiceLine {
	hash: number = 0
	paymentmethod: string = "cash"
	sum: number = 0
	value_date: string = ""
	account_num?: number = 0
	repayment_date?: string = ""
	cheque_num?: string = ""
	credit_card_4_digits?: string = ""
	credit_card_company?: number = 0
	credit_card?: number = 0
	credittype?: number = 0
	paymentapp_id?: number = 0
	transaction_id?: string = ""
	errors?: any = {}
	creditcard?: any = null
	paymentapp?: any = null
	bank_name?: string = ""
	branch_name?: string = ""
}

export class Proforma {
	id: number = 0
	total: number = 0
	description: string = ""
	created_at: string = ""
}

export class Invoice {
	type: "invoice"
	id: number = 0
	patient_id: number = 0
	contact_id: number = 0
	payor_id: number = 0
	payee_id: number = 0
	proforma_id: number = 0
	total: number = 0
	vat: number = 0
	withholding_tax: number = 0
	withholding_tax_percent: number = 0
	totalMinusvat: number = 0
	for: string = ""
	paid_by: string = ""
	description: string = ""
	remarks: string = ""
	period: string = ""
	payment_details: string = ""
	email_details: string = ""
	created_at: string = ""
	total_lm_debt: number = 0
	total_total_debt: number = 0
	total_lm_prices: number = 0
	total_until_lm_debt: number = 0
	proforma: number = 0
	from_date: string = ""
	value_date: string = ""
	to_date: string = ""
	withoutvat: string = "no"
	mode: string = "add"
	resource: string = ""
	is_preview: string = ""
	invoicelines: InvoiceLine[] = []
}

export class Admininvoice {
	type: "admininvoice"
	id: number = 0
	user_id: number = 0

	total: number = 0
	vat: number = 0

	totalMinusvat: number = 0
	for: string = ""
	paid_by: string = ""
	description: string = ""
	created_at: string = ""
	proforma: number = 0
	withoutvat: string = "no"
	mode: string = "add"
	resource: string = ""
	is_preview: string = ""
	admininvoicelines: AdmininvoiceLine[] = []
}

export class FinResource {
	id: number = 0
	patient_id: number = 0
	contact_id: number = 0
	payor_id: number = 0
	payee_id: number = 0
	proforma_id: number = 0
	proforma_ids: number[] = []
	receipt_ids: number[] = []
	justinvoice_id: number = 0
	receipt_id: number = 0
	total: number = 0
	vat: number = 0
	withholding_tax: number = 0
	withholding_tax_percent: number = 0
	total_minus_withholding: number = 0
	totalMinusvat: number = 0
	for: string = ""
	paid_by: string = ""
	description: string = ""
	remarks: string = ""
	period: string = ""
	payment_details: string = ""
	email_details: string = ""
	created_at: string = ""
	total_lm_debt: number = 0
	total_total_debt: number = 0
	total_lm_prices: number = 0
	total_until_lm_debt: number = 0
	proforma: number = 0
	from_date: string = ""
	value_date: string = ""
	to_date: string = ""
	withoutvat: string = "no"
	mode: string = "add"
	resource: string = ""
	is_preview: string = ""
	is_withholding_tax: string = "no"
	invoicelines: InvoiceLine[] = []
	includedFollowups: any[] = []
	followupsAndCreditSum: number = 0
	futureFusIds: number[] = []
	without_invoice: string = "no"
	show_email_in_pdf: string = "yes"
	show_patient_id: string = "no"
	show_versions: string = "no"
	show_personal_memorandum: string = "no"
	budget_for_payors: string = "no"
	id_number: string = ""
	attached_summary_text: string = ""
}

export class ErrorModalForm {
	user: number = 0
	subject: string = ""
	announcement: string = ""
	phone: string = ""
	email: string = ""
}
export class NewUserForm {
	name: string = ""
	phone_mobile: string = ""
	email: string = ""
	campaign_id: number = 2
}
export class TimeAllocationForm {
	id: number = 0
	user_id: number = 0
	location_id: number = 0
	day: number = 0
	time: string = ""
	time_to: string = ""
}
export class SoloTimeAllocation {
	id: number = 0
	day: number = 0
	time: string = "09:00"
	time_to: string = "12:00"
}

export class UserCredentials {
	login_string: string = ""
	user_name: string = ""
	password: string = ""
	confirm_password: string = ""
	email: string = ""
	phone_mobile: string = ""
	agree_to_terms: boolean = false
	token: string = ""
	affiliate_name: string = ""
	subscription_type: string = "private"
}

export class PrescriptionLine {
	medic_name: string = ""
	amount: number = 0
	medic_amount: number = 0
	unit: string = ""
	total_amount: number = 0
	frequency: number = 0
	frequency_unit: string = ""
	route_of_admin: string = ""
	dosage_form: string = ""
	remarks: string = ""
}

export interface FusCrudReturnObj {
	add: StoreFollowupserie
	update: StoreFollowupserie
	remove: number
	exceptions: StoreFollowupserie[]
	followups: StoreFollowup[]
	res1: any
	res2: any
}

export class MeetingChangeDetails {
	mode: string = ""
	followupserie_series_id: number = 0
	change_series: string = "only_one"
	until_date: string = ""
	message: string = ""
	remarks: string = ""
}

export class Prescription {
	id: number = 0
	patient_id: number = 0
	name: string = ""
	date: string = ""
	date_display: string = ""
	remarks: string = ""
	permanent_remarks: string = ""
	patient_name: string = ""
	patient_id_number: string = ""
	address: string = ""
	resource: string = ""
	is_preview: string = "no"
	prescriptionLines: PrescriptionLine[] = []
	age: number = 0
}

export class State {
	name: string = ""
	task_text: string = ""
	revoke_in_days: number = 0
	repeat_every_days: number = 0
}
export class Sub_user_group {
	id: number = 0
	name: string = ""
	description: string = ""
}

export class Sub_user_group_member {
	id: number = 0
	sub_user_group_id: number = 0
	user_id: number = 0
}

export class TimeAlloaction {
	id: number = 0
	user_id: number = 0
	location_id: number = 0
	day: string = ""
	time: string = ""
	time_to: string = ""
}
export class Task {
	id: number = 0
	user_id: number = 0
	content: string = ""
	patient_id: number = 0
	followupserie_id: number = 0
	contact_id: number = 0
	date: string = ""
	time: string = ""
	repeat_days: number = 0
	closed: string = "no"
	email_notify: string = "no"
	sms_notify: string = "no"
	urgent: string = "no"
	confident: string = "yes"
	sub_users: string[] = []
}

export class Task_comment {
	id: number = 0
	task_id: number = 0
	user_id: number = 0
	content: string = ""
}

export class Task_recipient {
	id: number = 0
	task_id: number = 0
	user_id: number = 0
}

export class Test {
	id?: number = 0
	name: string = ""
	short_name: string = ""
	description: string = ""
	remarks: string = ""
	for_patient: string = "no"
	test_id: number = 0
	order_num: number = 0
	is_highest_order?: boolean = false
	is_lowest_order?: boolean = false
	is_documented?: boolean = false
	is_locked?: string = "no"
	patient_sign_required?: string = "no"
	user_sign_required?: string = "no"
	calculated?: string = "no"
	tests: Test[] = []
	errors?: any = {}
	isFlipOrderSending?: boolean = false
	isDeleting?: boolean = false
	checked?: boolean = false
	active: string = "yes"
	public: string = "no"
}

export class Testgroup {
	id: number = 0
	name: string = ""
	tests_in_group: string = ""
	errors?: any = {}
}
export class TestquestionOption {
	value: string = ""
	mark: number = 0
	order_num: number = 0
	is_highest_order?: boolean = false
}

export class Testquestion {
	id: number = 0
	test_id: number = 0
	question: string = ""
	correct_answer: number = null
	field_type:
		| "text"
		| "number"
		| "opts-select"
		| "opts-checkbox"
		| "opts-btn-group"
		| "remark" = "text"
	range_min: number = null
	range_max: number = null
	step: string = ""
	required: yes_no_opts = "yes"
	options: string = ""
	order_num: number = 0
	question_num: number = 0
	optionsArr: TestquestionOption[] = []
	is_highest_order?: boolean = false
	is_lowest_order?: boolean = false
	isFormSubmittable: boolean = false
	errors?: any = {}
	isFlipOrderSending?: boolean = false
	isDeleting?: boolean = false
}
export class Testscale {
	id: number = 0
	test_id: number = 0
	scale_id: number = 0
	name: string = ""
	calculation_method: "average" | "sum" = "average"
	remarks: string = ""
	questions: string = null
	avg_sd: string = null
	interpretation: string = null
}
export class Testschedule {
	id: number = 0
	start_date: string = ""
	is_fixed_interval: yes_no_opts = "yes"
	interval: number = 1
	repetitions: number = 1
	daysArr: any[] = []
}
export class Userdetail_line {
	id: number = 0
	user_id: number = 0
	subject_web: string = ""
	description_web: string = ""
	line: number = null
	order_num: number = 0
	isFormSubmittable: boolean = false
	errors?: any = {}
	is_highest_order?: boolean = false
	is_lowest_order?: boolean = false
	isFlipOrderSending?: boolean = false
	isDeleting?: boolean = false
}

export class Testanswer {
	patient_id: number = 0
	test_id: number = 0
	testquestion_id: number = 0
	date: string = ""
	time: string = ""
	answer: string = ""
	errors?: any = {}
}

export class UserDetail {
	id: number = 0
	user_id: number = 0
	name: string = ""
	title: string = ""
	profession: string = ""
	address: string = ""
	email: string = ""
	phone: string = ""
	languages: string = ""
	website: string = ""
	image: string = ""
	errors?: any = {}
}

export class UserDetailLine {
	id: number = 0
	userdetail_id: number = 0
	line: number = 0
	subject: string = ""
	title: string = ""
	description: string = ""
	errors?: any = {}
}
export class Product {
	id: number = 0
	user_id: number = 0
	quantity: number = 0
	lang: string = ""
	name: string = ""
	title: string = ""
	sub_title: string = ""
	body: string = ""
	date: string = ""
	active: string = "yes"
	price: number = 0
	external_id: number = 0
	image: string = ""
	coupon: string = ""
	coupon_price: number = 0
	coupon_date_limit: string = ""
}

export interface TableGen {
	collectionFields: FieldGen[]
	tableName: string
	expandOnLoadMobile: boolean
	expandOnLoad: boolean
	langProps: { allName: string; [name: string]: string }
	collectionName: string
	defaultNewRow?: any
	duplicateWarningFields?: string[]
	filterFields?: {
		fieldName?: string
		opts?: any[]
		lang?: string
		checkType?: string
		defaultValue?: any
		canEditOnValue?: any
		initialValue?: string
	}[]
	searchableFields?: string[]
	sortField?: string
	sortIsAsc?: boolean
	updateRowOverride?: string
	itemsPerPage?: number
	expanded?: { upperFields?: string[]; lgAddedUpperFields?: string[] }
	mobileUpperFields?: string[]
	filterField: string
	filterFieldValue: any
	headerModalOpenBtns?: {
		icon?: string
		hoverLang?: string
		modal?: any
		fieldsToUpdate?: any
		dataVariablesFields?: string[]
		sendCheckedItems?: boolean
		funcToRunOnReturn?: string
		addFilterFieldValueFromTable?: boolean
		visibleCheckedOnly?: boolean
		textOnly?: boolean
	}[]
	dataVariables: any
	openNewRowOnInit?: boolean
	filterFieldApiObjectPreset?: any
	hasFieldHiding?: boolean
	isCheckItems?: boolean
	tabindexDomain?: number
	perms?: string[]
	overrideUpperFields?: string[]
	hasAssocFilter?: boolean
	hasTagsFilter?: boolean
	addRowIsByPlus?: boolean
	hideExport?: boolean
	tagFilterType?: TagItemTypes
	avoidNewRow?: boolean
}

export interface FieldGen {
	fieldName: string
	langName?: string
	isSortable?: boolean
	type:
		| "input"
		| "textarea"
		| "select"
		| "button-group"
		| "fuzzy"
		| "image"
		| "file"
		| "date"
		| "time"
		| "button"
		| "wysiwyg"
		| "color"
		| "pdf-view"
		| "download"
		| "mail-to"
		| "credit"
		| "fuzzy-api"
		| "static"
		| "staticIcon"
		| "document-action"
		| "renew"
		| "duplicate"
		| "tags-ac"
		| "autocomplete-multi"
		| "action-button"
		| "inquiry-status"
		| "show-item"
		| "external-link"
		| "whatsapp"
		| "phone"
		| "yes-no"
		| "string"
		| "otherSiteDataTableValue"
		| "calc"
		| "otherSiteDataTableValueMinusField"
		| "hidden" //button-group,button,download,mail-to,static,date,file,time
	validationRules?: any
	optionsCollection?: any
	isNumberField?: boolean
	isSiteDataSortField?: boolean
	savingProps?: { noSave?: boolean; funcName?: string; fieldName?: string }
	attrs?: FieldGenAttrs
	tabindex?: number
	isHideable?: boolean
	curHidden?: boolean
	genTblToLoad?: string
	fields?: FieldGen[]
	fuzzyArr?: string[]
	unique_for_value?: boolean
	hideOnMobile?: boolean
	hideOnNonMobile?: boolean
}
export const nonFocusFieldTypes = [
	"button-group",
	"button",
	"download",
	"mail-to",
	"static",
	"staticIcon",
	"date",
	"file",
	"time",
]

export interface FieldGenAttrs {
	autofocus?: "autofocus"
	autofocusNewRowOnly?: "autofocus"
	displayStringFieldName?: string
	objectFieldToSave?: string
	fuzzySearchableClasses?: string[]
	tableName?: string
	fuzzySearchObj?: FuzzySearchObj
	optionDisplay?: string
	urlTemplate?: string
	icon?: string
	modal?: any
	fieldsToUpdate?: any
	tableNameLang?: string
	rowVariableName?: string
	headturesInsert?: boolean
	avoidPlus?: boolean
	showPlusPerm?: string
	isImg?: boolean
	idNameConnection?: any
	width?: string
	minWidth?: string
	mobileWidth?: string
	mobileMinWidth?: string
	height?: string
	minHeight?: string
	avoidDateReset?: boolean
	downloadType?: string
	outputLang?: boolean
	nonVisNewRow?: boolean
	updatedByServer?: boolean
	staticOnEdit?: boolean
	updateFuncName?: string
	isInputInNewRow?: boolean
	staticOnPerms?: string[]
	isShownField?: string
	tagItemType?: TagItemTypes
	otherFieldToUse?: any
	ngIfFunc?: Function //expects curRow
	clickFunc?: string //expects curRow
	showItemBtnUrl?: string
	newValue?: any
	maxDims?: any
	disabledAfterToday?: boolean
	otherTableName?: string
	fieldNameinThisTable?: string
	fieldNameInOtherTable?: string
	fieldNameFirst?: string
	fieldNameSecond?: string
	minusField?: string
}

//////////////////

export interface PureDragObj {
	affectedObj?: any
	xStyleAttrName?: string
	yStyleAttrName?: string
}

export class DragBase {
	clickX?: number = 0 //start X
	clickY?: number = 0 //start Y
	mmFunc?: any = null //function to attach to window listener
	baseVals?: any = {} //start vals for the native element
	pureDragObj?: PureDragObj = null //native element and the moved properties
}
////////////////////////////////////

///////////////////////////

@Directive()
export class BasicInputFormClass {
	@Input() fieldName: string = ""
	@Input() actualObj: any = {}
	@Input() errors: any = {}
	@Input() addonObject: any = null //{before:{className:"abc",icon:"some icon class",text:"some text"},after:{}}
	//className is ADDITIONAL to input-group-addon
	//NOTHING is required
	@Input({ transform: booleanAttribute }) disabled: boolean = false
	@Input() className: string = ""
	@Input() placeholder: string = ""
	@Input() title_ph_lang: string = ""
	@Input({ transform: booleanAttribute }) isStatic: boolean = false
	@Input() tabindex: string = ""
	@Output() changeEvent = new EventEmitter()
	@Input() dataRowField: string = "" //used to be found for focusing purposes
	@Input({ transform: booleanAttribute }) autofocus: string = ""
	@Input() genTableVars: any = null //vars to be used to load init vars to gen table field
}

@Directive()
export class ExtendedInputFormClass extends BasicInputFormClass {
	@Input() min: string = ""
	@Input() max: string = ""
	@Input() autocomplete: string = "off"
	@Output() keyupEvent = new EventEmitter()
}

//input,textarea,select,wysiwyg,fuzzy

//STORE STUFF

export interface StoreSiteData {
	banks: Bank[]
	// bankaccounts:Bankaccount[];
	cities: City[]
	contacts: Contact[]
	contacttypes: Contacttype[]
	// contactways:Contactway[];
	creditcomps: Creditcomp[]
	creditcards: Creditcard[]
	credittypes: Credittype[]
	nondrugs: Nondrug[]
	expensetypes: Expensetype[]
	expenses: Expense[]
	labtests: Labtest[]
	meetingtypes: Meetingtype[]
	meeting_requests: Meeting_request[]
	paymentapps: Paymentapp[]
	payors: Payor[]
	patients: Patient[]
	// grouppatients:Grouppatient[];
	groups: Patient[]
	patienttypes: Patienttype[]
	suppliers: Supplier[]
	sites: Site[]
	followuptypes: Followuptype[]
	tarif: Tarif[]
	testgroups: Testgroup[]
}
export class StorePatient {
	id: number = 0
	name: string = ""
	id_number: string = ""
	name_en: string = ""
	year_of_birth: string = ""
	date_of_birth: string = ""
	age: string = ""
	date_start_therapy: string = ""
	marital_status: string = ""
	parents_marital_status: string = ""
	state: string = ""
	gender: string = ""
	number_of_kids: number = 0
	active: string = "yes" //  'yes',
	invoice_by_mail: string = "yes" //  'yes',
	automatic_proforma: string = "no" //  'yes',
	patientprice: number = 0
	patienttype_id: number = 0
	is_group: string = "no" //  'no',
	payor_id: number = 0
	color: number = 0
	country_id: string = ""
	account_in_hashav: number = 0
	notifiy: string = "yes" //  'no',
	is_withholding_tax: string = "no" //  'no',
	withholding_tax_percent: number = 0
	notification_default: string = "email" //whatsapp','email','sms')  'email',
	notification_time: string = "day_before" //day_before','meeting_day')  'day_before',
	contactway: string = ""
	last_followup: any = ""
	next_followup: any = ""
	total_lm_debt: number = 0
	total_lm_prices: number = 0
	total_lm_payor_prices: number = 0
	total_until_lm_debt: number = 0
	total_total_debt: number = 0
	assoc: number[] = []
	// assoc_name: string = "";
	//objects and arrays:
	// bankaccounts:Bankaccount[];
	apotropuses: Apotropus[]
	contactways: Contactway[]
	dblogs: Dblog[]
	diagppatients: Diagppatient[]
	documents: Document[]
	followups: Followup[]
	followups_list: Followup
	followupseries: Followupserie[]
	invoices: Invoice[]
	medicpatients: Medicpatient[]
	mixed_list: MixedListItem[]
	followups_update_list: FollowupsUpdateListItem[]
	sub_user_payments: SubUserPayments[]
	finResource_list: FinResourceItem[]
	nondrugpatients: Nondrugpatient[]
	notifications: Notification[]
	smslogs: Smslog[]
	prescriptions: Prescription[]
	reminders: any
	statuses: any
	remarks: any
	created_at: string = ""
	updated_at: string = ""
	charge_mode: string = "by_participant"
	payment_mode: string = "by_attending"
	referrer_user_id: number = null
	status: string = "inquirer"
	filled_stage: string = ""
	israel_invoice?: string = ""
}

export class StoreFollowupserie {
	id: number = 0
	patient_id: number = 0
	contact_id: number = 0
	start_unix: number = 0
	end_unix: number = 0
	date: string = ""
	time: string = ""
	date_to: string = ""
	time_to: string = ""
	start_day: number = 0
	end_day: number = 0
	series_limit_date: string = ""
	length: number = 0
	is_all_day: string = "" //  NULL,
	series_repeat: string = "" //one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly')  NULL,
	repeat_days: number = 0
	notes: string = ""
	meeting_title: string = ""
	personal_memorandum: string = ""
	followuptype_id: number = 0
	meetingtype_id: number = 0
	price: number = 0
	price_payor: number = 0
	takes_place: string = "" //  'yes',
	charged: string = "" //  NULL,
	ser_id: number = 0
	status: string = "" //active','deleted')  'active',
	is_group: string = "no"
	is_new_patient_or_contact: string = "no" //
	group: any = null
	patient: any = null
	contact: any = null
	isInVac: boolean = false
	location_id: number = 0

	/*
  * recieved from api
  * arrived: "yes"
charged: "yes"
color: 14
  date: "2020-12-30"
date_render: "30-12-2020"
date_to: "2020-12-30"
date_to_render: "30-12-2020"
followup_description: ""
followup_description_abst: ""
followup_id: 4
followup_remarks: ""
followup_remarks_abst: ""

followuptype: {id: 1, name: "פגישה"}
meetingtype: {id: 1, name: "רגילה"}
is_holiday: false
meeting_with: "patient"
meeting_with_name: "tahunya tevi"
name: "tahunya tevi"
notes_abst: ""
series_limit_date_render: ""
time_render: "07:30"
time_to_render: "08:20"
  *
  *
  *
  * */
}
export class StoreFollowup {
	id: number = 0
	followup_id: number = 0
	person_name: string = ""
	patient_id: number = 0
	contact_id: number = 0
	start_unix: number = 0
	end_unix: number = 0
	date: string = ""
	time: string = ""
	date_to: string = ""
	time_to: string = ""
	start_day: number = 0
	end_day: number = 0
	length: number = 0
	is_all_day: string = "" //  NULL,
	series_repeat: string = "" //one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly')  NULL,
	repeat_days: number = 0
	description: string = ""
	remarks: string = ""
	supervisor_notes: string = ""
	personal_memorandum: string = ""
	followuptype_id: number = 0
	meetingtype_id: number = 0
	payor_id: number = 0
	price: number = 0
	price_payor: number = 0
	price_patient: number = 0
	takes_place: string = "" //  NULL,
	charged: string = "" //  NULL,
	charged_manualy: string = "" //  'no',
	arrived: string = "" //yes','no','canceled')  'yes',
	invoice_id: number = 0
	finresource_type = ""
	invoice_id_patient = 0
	invoice_id_payor = 0
	finresource_type_payor = ""

	series_id: number = 0
	series_one_of: number = 0
	series_total: number = 0
	status: string = "" //active','deleted')  NULL,
	done: string = "" //  'no',
	updated_at: ""
}
export class Store {
	siteData: any = null
	followupseries: StoreFollowupserie[] = []
	fusExceptions: StoreFollowupserie[] = []
	loadedPatients: StorePatient[] = []
	followups: StoreFollowup[] = []
	// tasks: Task[] = [];
	// patientAssoc: any = {};
}

export type DatepickerChangeOpts = "ActualObject" | "CurrentDate" | "DisplayStr"

export type PermModes = "roles" | "users" | ""
export type ConflictReason = "patient" | "contact" | "assoc" | "location"
export type TagItemTypes = "patient" | "user" | "location" | "partner"

export interface User_assoc {
	id: number
	name: string
	email: string
	is_assoc: boolean
}

export interface CalendarExportAction {
	currentDate: string
	dayEvents: any[]
	centerFus: any | null
}

export interface GenTableUpdateField {
	genTableFieldName: string
	incomingRowFieldName: string
}

export interface GenTableUpdate {
	tableName: string
	rows: any[]
	idField: string
	fieldsToUpdate: GenTableUpdateField[]
}

export class TagsObj {
	hasAny: boolean = false
	ids: number[] = []
}

export interface ValueName {
	value: any
	name: string
}

export interface RecurrencePattern {
	id?: number
	taskId: number
	recurrenceType: "daily" | "weekly" | "monthly" | "specific_dates"
	intervalValue: number
	weekDays?: string[]
	specificDates?: string[]
	endDate?: Date
}

export type UserAssocItemTypes = "patient" | "followup" | "followupserie"
export type TimeStatuses = "past" | "present" | "future"
export type PatientOrUserId = "patient_id" | "user_id"
export type ReportIndividualViewOpts = "individual" | "group" | "all_options"
export const daysOfWeek = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
]
export type MouseOrTouchEvent = MouseEvent & TouchEvent
export type calUrlView = "week" | "day" | "month"
export type HomePersonColumns = "all" | "calendar" | "center" | "sideItems"
export type ReportActionBtns =
	| "downloadInvoice"
	| "sendStatus"
	| "emailInvoice"
	| "creditInvoice"
	| "isCheckmark"
	| "emailProforma"
	| "addInvoice"
	| "sendProforma"
