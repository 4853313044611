import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { EventsService } from "@app/services/events.service"
import { ValidatorService } from "@app/services/validator.service"
import * as moment from "moment/moment"
import { ActivatedRoute } from "@angular/router"
import { environment } from "@env/environment"
import { Title } from "@angular/platform-browser"
import { Meta } from "@angular/platform-browser"

@Component({
	selector: "app-guest-payment-form",
	templateUrl: "./guest-payment-form.component.html",
})
export class GuestPaymentFormComponent implements OnInit {
	environment = environment
	guestPaymentFormObject: any = null //object containing the data to be displayed and the iframe link
	errors: any = {} //will hold errors for the fields in the form
	isFormSubmittable: boolean = false //can submit (controls disabled in the submit button)
	product_id: number = null
	orig_price: number = null
	couponErrorObj: any = {}

	validationFields: any = {
		//field validations, control displaying field errors and ability to submit the form
		name: { not_empty: null },
		email: { email: null },
		phone: { not_empty: null, regex: /^([0-9]+)$/ },
	}
	fdsewrfsdfds: string = ""
	hgfe5ty6hjtgft454tr: string = ""
	saveObj: any = {}
	actualObj: any
	isBackFromFirstApiCall: boolean = false
	isFetching2ndApi: boolean = false

	constructor(
		public validator: ValidatorService,
		private apiService: ApiService,
		public lang: LangService,
		public sanitizer: DomSanitizer,
		public eventsService: EventsService,
		public titleService: Title,
		public metaService: Meta,
		protected route: ActivatedRoute
	) {}

	async ngOnInit() {
		this.actualObj = {
			date: "",
			name: "",
			phone: "",
			email: "",
			payment_only: "yes",
		}
		this.actualObj.date = moment.utc().format("Y-M-D")

		this.route.queryParams.subscribe((params) => {
			Object.keys(params).forEach((key) => {
				this[key] = params[key]
			})
			if (this.fdsewrfsdfds && this.hgfe5ty6hjtgft454tr && this.product_id) {
				this.saveObj = {
					fdsewrfsdfds: this.fdsewrfsdfds,
					hgfe5ty6hjtgft454tr: this.hgfe5ty6hjtgft454tr,
					product_id: this.product_id,
				}
				this.load()
			}
		})
	}
	async load() {
		const res: any = await this.apiService.post(
			"get_guest_payment_form_data",
			this.saveObj
		)
		this.titleService.setTitle(res.name)

		this.metaService.addTag({ name: "description", content: res.name })
		this.metaService.addTag({
			name: "og:image",
			content: environment.serverUrl + "/product-image/" + res.id,
		})

		// document.querySelector('meta[name="description"]').setAttribute("content",res.name);
		// document.querySelector('meta[name="og:image"]').setAttribute("content",environment.serverUrl+ '/product-image/'+res.id);
		this.isBackFromFirstApiCall = true
		this.guestPaymentFormObject = res
		this.orig_price = this.guestPaymentFormObject.price
	}
	async redirect() {
		this.isFetching2ndApi = true
		this.saveObj.price = this.guestPaymentFormObject.price
		const res: any = await this.apiService.post("get_guest_redirect_link", {
			...this.saveObj,
			...this.actualObj,
		})
		//const res:any=await this.apiService.post("get_guest_grow_redirect_link",this.getSendObj());
		this.isFetching2ndApi = false
		if (res.link) {
			;(window.location.href as any) = res.link
		}
	}
	validateEntireForm() {
		this.isFormSubmittable = this.validator.validateEntireForm(
			this.actualObj,
			this.errors,
			this.validationFields
		)

		if (this.errors?.name) {
			this.errors.name = this.lang.getVal("name_should_be_inserted")
		}
		if (this.errors?.phone) {
			this.errors.phone = this.lang.getVal("phone_should_be_inserted")
		}
		if (this.errors?.email) {
			this.errors.email = this.lang.getVal("email_should_be_inserted")
		}
	}

	checkCoupon() {
		const couponIsBad =
			this.guestPaymentFormObject.coupon != this.actualObj.coupon ||
			this.guestPaymentFormObject.coupon_date_limit < this.actualObj.date
		this.couponErrorObj = couponIsBad
			? { coupon: this.lang.getVal("not_valid_coupon") }
			: {}
		this.guestPaymentFormObject.price =
			this.orig_price -
			(couponIsBad ? 0 : this.guestPaymentFormObject.coupon_price)
	}
}
