import { environment } from "@env/environment"
//    -       sent-   icon.ok   ,Delivery-icon.sent green , Bounce icon.delete , read-icon.read
export const ConfigDefinitionsObj = {
	"icon.plus": "fa fa-plus fa-lg",
	"icon.minus": "fa fa-minus-circle fa-lg",
	"icon.edit": "fa fa-edit fa-lg",
	"icon.copy": "fa-regular fa-clone fa-lg",
	"icon.sortDown": "fas fa-sort-amount-down-alt",
	"icon.sortUp": "fas fa-sort-amount-up-alt",
	"icon.plus-sm": "fa fa-plus",
	"icon.medic": "fas fa-pills fa-lg",
	"icon.filter": "fa fas fa-filter",
	"icon.ok": "fa fa-check",
	"icon.irrelevant": "fa fa-ban red",
	"icon.sent": "fa fa-check",
	"icon.Delivery": "fa fa-check Delivery green",
	"icon.Bounce": "fa fa-delete red",
	"icon.read": "fa fa-check-double green",
	"icon.credit": "fa fa-arrow-right",
	"icon.dots": "fas fa-2x fa-ellipsis-h",
	"icon.import": "fa fa-lg fa-file-import fa-2x",
	"icon.export1": "fa fa-file-export",
	"icon.export": "fa fa-file-export",
	"icon.whiteplus": "fa fa-plus fa-lg white",
	"icon.bug": "fas fa-lg fa-bug",
	"icon.calendar1": "fa fa-calendar-days",
	"icon.calendar-check": "fa fa-calendar-check",
	"icon.calendar": "fa fa-calendar-days",
	"icon.calendarday": "fa-solid fa-calendar-day",
	"icon.group": "fas fa-users ",
	"icon.users": "fas fa-users ",
	"icon.setting1": "fa fa-lg fa-user green",
	"icon.setting2": "fa fa-lg fa-cog",
	"icon.accountant": "fa fa-lg fa-glasses",
	"icon.bank": "fa fa-piggy-bank",
	"icon.bankaccount": "fas fa-lg fa-money-check-alt",
	"icon.contact": "fa fa-lg fa-heart ",
	"icon.email": "fas fa-at",
	"icon.emailLarge": "fas fa-at fa-2x",
	"icon.mobile": "fas fa-lg fa-phone",
	"icon.expand": "fa fas fa-chevron-down",
	"icon.narrow": "fa fas fa-chevron-up",
	"icon.report_expand": "fa-solid fa-circle-chevron-down  fa-xl",
	"icon.report_narrow": "fa-solid fa-circle-chevron-up  fa-xl",
	"icon.arrow_left": "fas fa-angle-right",
	"icon.arrow_right": "fas fa-angle-left",
	"icon.arrow_left_green": "fas fa-angle-right",
	"icon.arrow_right_green": "fas fa-angle-left",
	"icon.authenticate": "fa fa-key",
	"icon.expense": "fa fa-lg fa-credit-card",
	"icon.expensetype": "fa fa-lg fa-wrench",
	"icon.wrench": "fa fa-lg fa-wrench",
	"icon.followuptype": "fa fa-lg fa-wrench",
	"icon.hashav": "fa fa-lg fa-file-export",
	"icon.header": "fa fa-lg fa-address-card",
	"icon.invoice": "fas fa-lg fa-money-bill-alt",
	"icon.nondrug": "fa fa-lg fa-leaf",
	"icon.patient": "fa fa-user ",
	"icon.patient-strong": "fa fas-user",
	"icon.patienttype": "fa fa-lg fa-users-cog",
	"icon.payor": "fas fa-solid fa-building-columns",
	"icon.payorprices": "fa  fa-lg fa-coins  white",
	"icon.phone": "fa fa-mobile-screen-button fa-lg",
	"icon.supplier": "fa fa-cart-shopping",
	"icon.tax": "fa fa-lg fa-calculator red",
	"icon.irs": "fa fa-eye",
	"icon.report": "fa fa-chart-pie",
	"icon.graph": "fa-regular fa-chart-bar",
	"icon.insert": "fa fa-plus-circle fa-2x",
	"icon.insert2": "fa fa-plus-circle fa-3x blue",
	"icon.update": "fa-regular fa-pen-to-square",
	"icon.show": "fa fa-eye",
	"icon.hide": "fa fa-solid fa-eye-slash",
	"icon.whatsapp": "fa fa-brands fa-whatsapp",
	"icon.preview": "fa fa-eye",
	"icon.delete": "fa-regular fa-trash-can",
	"icon.delete_sm": " fa-trash-can",
	"icon.upload": "fa fa-upload",
	"icon.zone-eye": "fas fa-eye",
	"icon.zone-eye-off": "fas fa-eye-slash",
	"icon.download": "fa fa-arrow-down",
	"icon.reprint": "fa fa-arrow-up",
	"icon.print": "fas fa fa-print",
	// 'icon.repeat': 'fas fa-redo',
	"icon.repeat": "fa fa-solid fa-arrows-rotate",
	"icon.repeat2": "fas fa-sync",
	"icon.list1": "fa fa-list",
	"icon.list": "fab fa-buffer fa-lg",
	"icon.list-historic": "fa fa-list fa-lg",
	"icon.prescription": "fas fa-prescription fa-lg",
	"icon.login": "fa fa-arrow-right-to-bracket",
	"icon.logout1": "fa fa-arrow-right-from-bracket",
	"icon.logout": "fa fa-lg fa-arrow-right-from-bracket",
	"icon.search": "fa fa-magnifying-glass",
	"icon.maximize": "fa fa-expand",
	"icon.minimize": "fa fa-compress",
	"icon.remove-circle": "fas fa-eraser",
	"icon.remarks": "fa fa-comment  fa-2x",
	"icon.bell": "fa fa-bell",
	/*'icon.date': 'fa-solid fa-calendar-week',*/
	"icon.date": "fas fa-angle-double-left",
	"icon.holiday": "fas fa-lg fa-glass-cheers",
	"icon.hourglass": "fa fa-lg fa-hourglass-half",
	"icon.information": "fa fa-lg fa-info-sign",
	"icon.file": "fa fa-file",
	"icon.free_meeting": "fa fa-martini-glass",
	"icon.meeting": "fa fa-martini-glass",
	"icon.pushpin": "fa fa-thumbtack",
	"icon.settings": "fa-regular fa-gear",
	"icon.settings1": "fa fas fa-cog",
	"icon.time1": "fa-regular fa-clock",
	"icon.time": "fa fa-clock",
	"icon.warning-sign": "fa fa-triangle-exclamation",
	"icon.close": "fas fa-circle-xmark  fa-lg",
	"icon.modal-close": "fa fa-arrow-right fa-lg",
	"icon.cancel": "fas fa-circle-xmark",
	"icon.save": "fas fa-floppy-o",
	"icon.info": "fa fa-info fa-lg",
	"icon.sliders": "fa fa-sliders fa-lg",
	"icon.attachment": "fa fa-paperclip fa-lg",
	"icon.submit": "fa fa-caret-left",
	"icon.stop": "fa fa-stop",
	"icon.open": "fa fa-circle",
	"icon.template": "fal fa-newspaper",
	"icon.documenttemplate": "fa fa-file",
	"icon.changePassword": "fa fa-unlock",
	"icon.lock": "fa fa-lock",
	"icon.bars": "fa fa-bars",
	"icon.arow-right": "fa fa-chevron-right",
	"icon.arow-left": "fa fa-chevron-left",
	"icon.receipt": "fa fa-receipt fa-lg green",
	"icon.justinvoice": "fa fa-file-invoice fa-lg",
	"icon.translate": "fas fa-globe-americas",
	"icon.spin": "fa fa-spinner fa-spin",
	"icon.arrow_up": "fa fa-angle-up",
	"icon.arrow_down": "fa fa-angle-down",
	"icon.next": "fa fa-arrow-down",
	"icon.previous": "fa fa-arrow-up",
	"icon.arrow_down2": "fa fa-angle-down fa-2x",
	"icon.paragraph": "fa fa-paragraph",
	"icon.caret-left": "fa fa-caret-left",
	"icon.caret-right": "fa fa-caret-right",
	"icon.caret-down": "fa fa-caret-down",
	"icon.align-right": "fa fa-align-right",
	"icon.align-center": "fa fa-align-center",
	"icon.align-left": "fa fa-align-left",
	"icon.list-ol": "fa fa-list-ol",
	"icon.list-ul": "fa fa-list-ul",
	"icon.therapist": "fas fa-user-md fa-lg",
	"icon.map": "fas fa-map fa-lg",
	"icon.tasks": "fas fa-tasks fa-lg",
	"icon.home-grey": "fas fa-home fa-lg",
	"icon.link-grey": "fas fa-link fa-lg",
	"icon.profession": "fa fa-user-tie fa-lg",
	"icon.languages": "fa fa-hands-asl-interpreting fa-lg",
	"icon.expertise": "fa fa-dumbbell fa-lg",
	"icon.sub_user": "fas fa-user-graduate",
	"icon.sign_document": "fas fa-file-signature",
	"icon.help": "fa-regular fa_lg fa-circle-question ",
	"icon.sms": "fa fa-sms  fa-2x",
	"icon.sms_small": "fa fa-sms",
	"icon.fill": "fa fa-fill-drip",
	"icon.cabinet": "fa fa-cabinet-filing",
	"icon.graphs": "fa-regular fa-chart-bar",
	"icon.payment": "fa fa-lg fa-piggy-bank",
	"icon.door": "fa fa-door-open",
	"icon.open_folder": "fa fa-folder-open",
	"icon.takbull": "fa fa-credit-card",
	"icon.affiliate": "fa fa-share-nodes",
	"icon.test": "fa-sharp fa fa-file-waveform   fa-2x",
	"icon.form": "fa-regular fa-file-lines",
	"icon.doubleArrow": "fas fa-angle-double-left",
	"icon.rightArrow": "fas fa-angle-right",
	"icon.leftArrow": "fas fa-angle-left",
	// 'icon.back': 'fa fa-delete-left fa-lg',
	"icon.back": "fa fa-arrow-right-to-bracket",
	"icon.partner": "fa fa-user-group",
	"icon.times": "fa fa-bullseye",
	"icon.security": "fa fa-lock",
	"icon.magic": "fa-solid fa-wand-sparkles",
	"icon.tests": "fa-sharp fa fa-file-waveform   fa-2x",
	"icon.xmark": "fa-solid fa-circle-xmark fa-2x",
	// 'icon.tests': 'fa-solid fa-circle-question',
	"icon.autotexts": "fa-solid fa-clipboard",
	"icon.handshake": "fa fa-regular fa-handshake",
	"icon.automation": "fa-solid fa-wand-magic",
	"icon.image": "fa-solid fa-image",
	// 'img.contactway': environment.serverUrl + '/images/newdesign/mail.png',
	"img.add": environment.serverUrl + "/svg/plus.svg",
	"img.insert": environment.serverUrl + "/svg/circle-plus.svg",
	"img.contactway": environment.serverUrl + "/svg/contact.svg",
	"img.contactway-white": environment.serverUrl + "/svg/contact-white.svg",
	// 'img.invoice-sm': environment.serverUrl + '/images/newdesign/-e-money.png',
	"img.invoice-sm": environment.serverUrl + "/svg/payments.svg",
	"img.reminder": environment.serverUrl + "/images/newdesign/-e-bell.png",
	// 'img.reminder': environment.serverUrl + '/svg/notifications.svg',
	"img.home": environment.serverUrl + "/svg/home.svg",
	"img.calendar1": environment.serverUrl + "/svg/history.svg",
	"img.calendar1-white": environment.serverUrl + "/svg/history-white.svg",
	"img.main_calendar": environment.serverUrl + "/svg/calendar.svg",
	"img.elipse": environment.serverUrl + "/images/newdesign/ellipse.png",
	"img.setting": environment.serverUrl + "/svg/settings.svg",
	"img.financial": environment.serverUrl + "/svg/reports.svg",
	"img.notifications": environment.serverUrl + "/svg/notifications.svg",
	"img.proforma": environment.serverUrl + "/images/newdesign/booking.png",
	"img.test": environment.serverUrl + "/svg/questionnaire.svg",
	"img.test-white": environment.serverUrl + "/svg/questionnaire-white.svg",
	// 'img.money': environment.serverUrl + '/images/newdesign/-e-money.png',
	"img.money": environment.serverUrl + "/svg/payments.svg",
	"img.money-white": environment.serverUrl + "/svg/payments-white.svg",
	// 'img.diagp': environment.serverUrl + '/images/newdesign/-e-Dx.png',
	"img.diagp": environment.serverUrl + "/svg/Dx.svg",
	"img.diagp-white": environment.serverUrl + "/svg/Dx-white.svg",
	// 'img.document': environment.serverUrl + '/images/newdesign/-e-kabala.png',
	"img.document": environment.serverUrl + "/svg/documents.svg",
	"img.document-white": environment.serverUrl + "/svg/documents-white.svg",
	// 'img.labtest': environment.serverUrl + '/images/newdesign/lab.png',
	"img.labtest": environment.serverUrl + "/svg/lab.svg",
	"img.labtest-white": environment.serverUrl + "/svg/lab-white.svg",
	"img.nondrug": environment.serverUrl + "/svg/treatments.svg",
	"img.nondrug-white": environment.serverUrl + "/svg/treatments-white.svg",
	// 'img.medic': environment.serverUrl + '/images/newdesign/-e-Rx.png',
	"img.medic": environment.serverUrl + "/svg/Rx.svg",
	"img.medic-white": environment.serverUrl + "/svg/Rx-white.svg",
	"img.refresh": environment.serverUrl + "/images/newdesign/refresh.png",
	"img.m-refresh": environment.serverUrl + "/images/newdesign/m-refresh.png",
	grow: environment.serverUrl + "/images/newdesign/grow.png",
	"img.autotexts": environment.serverUrl + "/svg/texts-white.svg",

	//"color.contact": "#FF5733",
	"color.contact": "#cff9fc",
	"color.payor": "#3adbb9",
	"color.user": "#3adbb9",
	"color.meeting": "#D3E5F5",
	"color.message": "#FFA54D",
	"color.reminder": "#FFA54D",
	//"color.message": "#FF7E00",
	//"color.reminder": "#FF7E00",
	"color.national": "#C4DFE6",
	"color.jewish": "#66A5AD",
	"color.muslim": "#6FB98F",
	"color.christian_catholic_protestant": "#F18D9E",
	"color.christian_orthodox": "#E6D72A",
	"color.druze": "#5BC8AC",
	"color.cherkess": "#98DBC6",

	momentDateFormat: "YYYY-MM-DD",
	momentDateDisplay: "D.M.YY",
}
