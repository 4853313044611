import { Component, OnInit } from "@angular/core"
import { LangService } from "@app/services/lang.service"

@Component({
	selector: "app-payment-client-fail-grow",
	template: `
		<app-payment-wrap>
			<h2>{{ "payment_failed" | langVal }}</h2>
			<br />
			<h3>{{ "payment_failed1" | langVal }}</h3>
			<br />
		</app-payment-wrap>
	`,
})
export class PaymentClientFailGrowComponent implements OnInit {
	success: boolean = false //was the purchase a success
	constructor(public lang: LangService) {}

	ngOnInit() {
		let input = location.search.split("?").pop() //gets the query string (everything in the URL after the ? sign)
	}
}
