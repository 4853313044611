<div>
	<h4 class="gen-title">{{ "financial" | langVal }}</h4>
	<div class="row">
		<div class="col-12 col-md-2">
			<label>{{ "account_type" | langVal }}</label>
			<app-gen-select
				[actualObj]="configcliniq"
				[errors]="errors"
				fieldName="tax_account_type"
				(changeEvent)="onAccountTypeChange(); validateAndSave()"
				optionsListName="taxAccountOpts"
				optionFieldId="value"
			>
			</app-gen-select>
		</div>
		<div class="col-6 col-md-2">
			<ng-container
				*ngTemplateOutlet="
					inp;
					context: {
						fieldName: 'vat_number',
						disabled: configcliniq?.is_new_vat_number == 'yes',
					}
				"
			/>
		</div>
		<div class="col-6 col-md-2">
			<ng-container
				*ngTemplateOutlet="inp; context: { fieldName: 'tax_number' }"
			/>
		</div>
		<div class="col-6 col-md-1">
			<ng-container
				*ngTemplateOutlet="
					inp;
					context: { fieldName: 'vat', title: 'vat_percent' }
				"
			/>
		</div>
		<div class="col-12 col-md-2">
			<ng-container
				*ngTemplateOutlet="btnGroupYesNo; context: { fieldName: 'withoutvat' }"
			/>
		</div>
	</div>
	<div class="buttons-row row">
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_payment_details' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_manual_charge' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'send_original_to_user' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'is_selfinvoice' }
				"
			/>
		</div>
	</div>
	<div class="row">
		<div class="col-6 col-xl-3">
			<ng-container
				*ngTemplateOutlet="inp; context: { fieldName: 'application_link' }"
			/>
		</div>
		<div class="col-6 col-xl-3">
			<ng-container
				*ngTemplateOutlet="inp; context: { fieldName: 'bank_account_text' }"
			/>
		</div>
	</div>
	<div class="row">
		<div class="col-3 col-xl-2">
			<label>{{ "invoice_per_followup" | langVal }}</label>
			<app-gen-button-group
				[actualObj]="configcliniq"
				fieldName="invoice_per_followup"
				optionsListName="yes_no_opts"
				(changeEvent)="warnInvoicePerFollowupChange()"
			/>
		</div>
	</div>
	<hr class="separation-line" />
</div>

<app-cc-sections section="taxes" />

<ng-template
	#inp
	let-fieldName="fieldName"
	let-title="title"
	let-disabled="disabled"
>
	<label>{{ title || fieldName | langVal }}</label>
	<app-gen-input
		[tabindex]="-1"
		[actualObj]="configcliniq"
		[errors]="errors"
		[fieldName]="fieldName"
		[title_ph_lang]="title || fieldName"
		(keyupEvent)="validateAndSave()"
		[disabled]="disabled"
	/>
</ng-template>

<ng-template
	#btnGroupYesNo
	let-fieldName="fieldName"
>
	<label>{{ fieldName | langVal }}</label>
	<app-gen-button-group
		[actualObj]="configcliniq"
		[fieldName]="fieldName"
		optionsListName="yes_no_opts"
		(changeEvent)="validateAndSave()"
	/>
</ng-template>
