@if (isStatic) {
	{{ searchStr }}
} @else {
	<div class="flex-v-center gen-autocomplete-wrapper">
		<div class="input-group">
			<div style="flex-grow: 1">
				<input
					#inputElement
					class="gen-autocomplete"
					[ngClass]="className"
					(keyup)="onkeyup($event.target.value)"
					[disabled]="disabled"
					[placeholder]="placeholder || (title_ph_lang || fieldName | langVal)"
					[value]="searchStr"
					[title]="title_ph_lang || fieldName | langVal"
					[tabindex]="tabindex"
					(click)="handleClick($event)"
					[attr.name]="fieldName"
					[class.validation-checked]="error === null"
					[class.validation-error]="error"
					autocomplete="off"
				/>

				@if (searchStr?.length && !disabled) {
					<app-icon
						icon="close"
						class="searchClear"
						pointer
						(click)="clear()"
					/>
				}

				@if (isDisplayResults) {
					<ul [style.width]="'100%'">
						<!-- <ul  [style.width]="'100%'"> -->
						@for (option of optionsShows; track option) {
							<li
								(click)="choose(option)"
								[innerHtml]="option[actualDisplayString]"
							></li>
						} @empty {
							@if (!avoidNoResult) {
								<li>{{ "no_result" | langVal }}</li>
							}
						}
					</ul>
				}
			</div>
			@if (!disabled) {
				<span
					class="input-group-addon"
					[class.validation-checked]="error === null"
					[class.validation-error]="error"
					icon="search"
					(click)="inputElement.focus()"
				></span>
			} @else {
				<!--        <span style="border: 1px solid #ccc;border-radius: 40px 0 0 40px !important;"></span>-->
			}
		</div>
		@if (hasPlus) {
			<button
				[titleLang]="plusLang | langVal"
				class="btn btn-icon btn-plus"
				(click)="plusClick.emit()"
				icon="add"
				isImg
			></button>
		}
	</div>
	@if (error) {
		<p class="error">{{ error }}</p>
	}
}
