import { Component, Input, OnInit } from "@angular/core"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-taxes",
	templateUrl: "./cc-params-taxes.component.html",
})
export class CcParamsTaxesComponent extends CcParamsGen {
	//inheriting most functions and fields!
	saveObjName: string = "taxes" //which part to save while updating

	onAccountTypeChange() {
		//changes the withoutvat field according to the account type (default to "no")
		this.configcliniq.withoutvat =
			this.configcliniq.tax_account_type == "op" ? "yes" : "no"
	}
}
