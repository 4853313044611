import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { OptionsList } from "@app/definitions/opts"
import { lang as langEn } from "@app/definitions/lang-en"
import { lang as langHe } from "@app/definitions/lang-he"
import { ConfigDefinitions } from "@app/definitions/types"

@Injectable({
	providedIn: "root",
})
export class LangService {
	Config = ConfigDefinitions
	availableLangs = ["iw", "en"] //langs that are availabe (in select and by url)
	langCode: string = "iw" //current code

	isRtl = true //used for printing style and aligning popups
	OptionsList = OptionsList //the option lists for buttons and select - need to be translated

	translation: any = {} //will hold the action dictionary keys and values
	translationEn: any = {}
	translationHe: any = {}

	constructor(protected router: Router) {
		this.setLang(this.langCode, true) //init the lang (even if same will force the translation activities)
		this.translationEn = langEn
		this.translationHe = langHe
		// import("@app/definitions/lang-en").then((res: any) => {
		// });
		// this.postGetLang();
	}
	routeTryChangeLang(lang: string) {
		//if is available lang - attempts to set a lang. returns its success
		if (this.availableLangs.includes(lang)) {
			//check if available
			this.setLang(lang)
			return true
		}
		return false
	}

	setLang(langCode: string = "", isForce: boolean = false) {
		//sets lang, updates translations if different than previous lang OR forced
		if (!isForce && this.langCode == langCode) {
			return
		} //if same lang a previous (and not force) - exit
		if (langCode) {
			//set the lang
			this.langCode = langCode
		}
		switch (
			this.langCode //fetch the dictionary and set isRtl
		) {
			case "iw":
				// this.translation=lang;
				this.postGetLang(langHe)
				// import("@app/definitions/lang-he").then(this.postGetLang.bind(this));
				this.isRtl = true
				break
			case "en":
				this.isRtl = false
				this.postGetLang(langEn)
				// import("@app/definitions/lang-en").then(this.postGetLang.bind(this));
				///
				break
		}
		;["bs-rtl", "style-rtl"].forEach((id) => {
			//disable/enable the bootstrap and style sheet for rtl according to isRtl
			const el: any = document.getElementById(id)
			if (el) {
				el.disabled = !this.isRtl
			}
		})
		document.documentElement.lang = this.langCode //set HTML document properties
		document.documentElement.dir = this.isRtl ? "rtl" : "ltr"
	}
	postGetLang(lang: any) {
		//save the dictionary and translate all options
		this.translation = lang //save to translation
		Object.keys(this.OptionsList).forEach((key) => {
			//iterate all option lists
			let list = this.OptionsList[key]
			list.forEach((item) => {
				//iterate items in list
				let translationKey = item.name ? item.name : item.value //get the key by  either the item's name (if not empty) or value
				item.lang = this.translation[translationKey] || translationKey //save or override to lang property of item (to be used by the components to display)
			})
		})
	}
	getVal(key: string) {
		return this.translation[key] || key
	}
	getValHe(key: string) {
		return this.translationHe[key] || key
	}
	getConfigVal(key: string) {
		return this.Config[key] || ""
	}
	convertLangTerminologyPreferences(
		terminology_prefrences: "medical" | "business"
	) {
		if (terminology_prefrences == "medical") {
			return
		}
		switch (terminology_prefrences) {
			case "business":
				Object.keys(this.translation).forEach((key) => {
					this.translation[key] = this.translation[key].replaceAll(
						"מטופל",
						"לקוח"
					)
					this.translation[key] = this.translation[key].replaceAll(
						"לקוחים",
						"לקוחות"
					)
				})
				Object.keys(this.translationEn).forEach((key) => {
					this.translationEn[key] = this.translationEn[key].replaceAll(
						"patient",
						"client"
					)
				})

				break
		}
	}
}
