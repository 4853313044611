import { Component, Input } from "@angular/core"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { ValidatorService } from "@app/services/validator.service"

@Component({
	selector: "app-patient-details-fill",
	templateUrl: "./patient-details-fill.component.html",
})
export class PatientDetailsFillComponent {
	@Input() code: string = ""
	res: any = null
	patient: any = {}
	errors: any = {}
	isFormSubmittable: boolean = false
	isSaving: boolean = false
	submitted: boolean = false
	validationFields: any = {}
	patientfields: any[] = []

	constructor(
		private apiService: ApiService,
		private lang: LangService,
		private modalService: ModalService,
		public validator: ValidatorService
	) {}

	async ngOnInit() {
		const res = await this.apiService.post(
			"patient_details_fill_init",
			this,
			"code"
		)
		if (!res) {
			return
		}
		this.res = res
		this.res.patient_details_fields_manage = JSON.parse(
			this.res.patient_details_fields_manage
		)
		if (this.res?.patient_details_fields_manage) {
			Object.keys(this.res.patient_details_fields_manage)
				.filter((field) => {
					const obj = this.res.patient_details_fields_manage[field]
					return obj?.show && obj?.required == "yes"
				})
				.forEach((field) => {
					this.patient[field] = null
					this.validationFields[field] = { not_empty: true, not_null: true }
				})
		}
		this.patientfields = res.patientfields
			.map((row) => ({
				id: row.id,
				name: row.name,
				fieldName: `patientfield_id_${row.id}`,
			}))
			.filter(
				({ fieldName }) =>
					this.res.patient_details_fields_manage[fieldName]?.show
			)
	}
	validateEntireForm() {
		this.errors = {}

		this.isFormSubmittable = this.validator.validateEntireForm(
			this.patient,
			this.errors,
			this.validationFields
		)
	}
	async save() {
		this.isSaving = true
		await this.apiService.post("patient_fill_details", {
			patient: this.patient,
			code: this.code,
		})
		this.modalService.openToast(this.lang.getVal("update_successfuly"))
		this.submitted = true
		this.isSaving = false
	}
}
