import { Component, OnInit } from "@angular/core"
import { ModalService } from "@app/services/modal.service"
import { LangService } from "@app/services/lang.service"
import { StoreService } from "@app/services/store.service"

@Component({
	selector: "app-footer",
	template: `
		<div class="container-fluid footer-bottom-mobile">
			<footer class="text-center d-none d-lg-block">
				<hr />
				<div>
					<span
						(click)="openHelp()"
						class="pointer"
						>{{ "contact_us" | langVal }}
					</span>
					&nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;
					<span
						><a
							href="http://my-cliniq.com/"
							tabindex="-1"
							target="_blank"
							>2016-2024 © My-Cliniq.com</a
						>
					</span>
					&nbsp;&nbsp;|&nbsp;&nbsp;
					<span> {{ "version" | langVal }} {{ store.VERSION }}</span>
				</div>
			</footer>
		</div>
	`,
})
export class FooterComponent {
	//outputs the footer for the site

	constructor(
		public lang: LangService,
		public modalService: ModalService,
		public store: StoreService
	) {}

	openHelp() {
		//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("error")
	}
}
