<div class="wrapper flex">
	<nav>
		<!--    <div class="custom-menu d-lg-none">-->
		<!--      <button  class="btn btn-primary" (click)="showMenu=!showMenu" preIcon="bars">-->
		<!--        <span class="sr-only">Toggle Menu</span>-->
		<!--      </button>-->
		<!--    </div>-->
		@if (showMenu) {
			<div class="options">
				<ul class="list-unstyled components">
					@for (sideItem of sideItems; track sideItem) {
						<li class="sidebar-item">
							<!-- each click on the side menu changes to url to this section }-->
							<div
								style="border-radius: 4px"
								[routerLink]="'/config-cliniq/' + sideItem.sectionName"
								(click)="sideMenuClick()"
								[style.background]="
									innerSectionName == sideItem.sectionName &&
									!eventsService.isMobile
										? 'var(--light-grey)'
										: ''
								"
								[preIcon]="sideItem.icon"
							>
								{{ sideItem.lang | langVal }}
							</div>
						</li>
					}
				</ul>
			</div>
		}
	</nav>
	@if (
		configcliniq &&
		(!eventsService.isMobile || (eventsService.isMobile && !showMenu))
	) {
		<div class="sections">
			<div class="d-lg-none">
				<div>
					<button
						class="close"
						tabindex="-1"
						titleLang="back_to_menu"
						(click)="showMenu = !showMenu"
						icon="modal-close"
					></button>
				</div>
				<hr />
			</div>

			@switch (innerSectionName) {
				@case ("headtures") {
					<app-gen-table
						tableName="headtures"
						filterField="user_id"
						[filterFieldValue]="null"
					/>
				}
				@case ("products") {
					<app-gen-table tableName="products" />
				}
				@case ("journal") {
					<app-cc-params-journal />
				}
				@case ("user") {
					<app-cc-params-user-details />
				}
				@case ("payors") {
					<!--<app-gen-table tableName="payors" />-->
				}
				@case ("ownerbankaccounts") {
					<!--<app-gen-table tableName="ownerbankaccounts" />-->
				}
				@case ("patienttypes") {
					<!--<app-gen-table tableName="patienttypes" />-->
				}
				@case ("hashav") {
					<!--<app-cc-params-hashav />-->
				}
				@case ("accountant") {
					<!--<app-cc-params-accountant />-->
				}
				@case ("taxes") {
					<app-cc-params-taxes />
				}
				@case ("zones") {
					<!--<app-cc-params-zones />-->
				}
				@case ("holiday") {
					<app-cc-params-holiday />
				}
				@case ("switches") {
					<app-cc-params-switch />
				}
				@case ("security") {
					<app-cc-params-security />
				}
				@case ("self-coordination") {
					<app-cc-params-self-coordination />
				}
				@case ("automation") {
					<app-cc-params-automation />
				}
				@default {
					<app-cc-sections
						[name]="innerSectionName"
						[section]="innerSectionName"
					/>
				}
			}
		</div>
	}
</div>
