import { ActivatedRoute, Router } from "@angular/router"
import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { ValidatorService } from "@app/services/validator.service"
import { LangService } from "@app/services/lang.service"
import { ApiService } from "@app/services/api.service"
import { User } from "@app/definitions/types"
import { StoreService } from "@app/services/store.service"
import { PermsService } from "@app/services/perms.service"

@Component({
	selector: "app-user",
	template: `
		@if (user?.id) {
			@if (
				(permsService?.owner_has_users && isOwner) ||
				!permsService?.owner_has_users ||
				permsService?.perms?.administrative
			) {
				<div>
					<app-btn-ext
						[disabled]="!admininvoiceObjs.length"
						(clickEv)="print()"
						[isSpin]="isDownloading"
						text="print_multiple_invoices"
					/>
					<button
						class="btn btn-primary"
						titleLang="payment"
						routerLink="/purchases/create"
					>
						{{ "payment" | langVal }}
					</button>
				</div>

				<div>
					<app-gen-table
						filterField="user_id"
						[filterFieldValue]="user?.id"
						tableName="admininvoices"
						isCheckItems
						(checkedItemsChanged)="updateAdmininvoicesIds($event)"
					/>
				</div>
				<div style="margin-top: 50px">
					<app-gen-table tableName="affiliated_users" />
				</div>
			} @else {
				<app-gen-table
					tableName="finResource_person_list"
					filterField="user_id"
					[filterFieldValue]="user?.id"
				/>
			}
		}
	`,
})
export class UserComponent implements OnInit {
	user: User
	admininvoiceObjs: any[] = []
	isDownloading: boolean = false
	cliniqData: any = null
	isOwner: boolean = false

	constructor(
		private apiService: ApiService,
		public lang: LangService,
		public permsService: PermsService,
		public validator: ValidatorService,
		private route: ActivatedRoute,
		private store: StoreService
	) {}

	async ngOnInit() {
		const res = await this.apiService.post("get_user_data")
		this.user = res
		this.cliniqData = this.store.getCliniqDataOrExit()
		this.isOwner = this.cliniqData.has_users
	}

	updateAdmininvoicesIds(arrItems: any[]) {
		this.admininvoiceObjs = arrItems
	}

	async print() {
		if (!this.admininvoiceObjs.length) {
			return
		}
		this.isDownloading = true
		for (let obj of this.admininvoiceObjs) {
			const sendObj: any = { ...obj, doctype: "admininvoice" } //,headture_id:this.headtureRow.id
			await this.store.downloadToastFunc(async () => {
				const res = await this.apiService.download(
					"print_make_finresource",
					sendObj
				)
				if (!res?.size) {
					return this.store.downloadFailedModal()
				}
				let name = `admininvoice_${obj.id}_copy.pdf`
				this.apiService.downloadFile(res, name)
			})
		}
	}
}
