<div class="site-menu">
	<div class="cont">
		<div>
			@if (!isOwner) {
				<div class="main-menu">
					@for (item of menu; track item) {
						<div
							(mouseenter)="item.isOpen = true"
							(mouseleave)="item.isOpen = false"
							[class.active]="
								curMenuItem == item?.id || curMenuItem == item?.link
							"
						>
							<app-icon
								(click)="menuItemClick(item)"
								[icon]="item.icon"
								[isImg]="item.isImg"
								[titleLang]="item.lang"
								pointer
							/>

							@if (item.children && item.isOpen) {
								<div
									class="menu-float"
									[class.menu-float-home]="item?.id == 'home'"
								>
									@for (subItem of item.children; track subItem) {
										@if (!subItem.hidden && !subItem.hiddenCc) {
											<div (click)="menuItemClick(subItem)">
												<div>
													<app-icon
														[icon]="subItem.icon"
														[isImg]="subItem.isImg"
														[titleLang]="subItem.lang"
													/>
												</div>
												<div>{{ subItem.lang | langVal }}</div>
											</div>
										}
									}
								</div>
							}
						</div>
					}
					<div>
						<div (click)="clickNoti()">
							{{ store.unreadTasks !== null ? store.unreadTasks : "" }}
						</div>
					</div>
				</div>
			}
		</div>

		<div class="d-flex gap-3 mx-3">
			<!-- @if(!isOwner){}-->
			<div class="searchBar">
				<app-gen-autocomplete
					#searchFuzzy
					title_ph_lang="patients_plus"
					[fuzzySearchableClasses]="
						permsService?.perms?.administrative
							? ['patients', 'contacts', 'payors']
							: ['patients', 'contacts']
					"
					(chosenEvent)="fuzzyClick($event)"
					[searchableFields]="['name', 'id_number']"
					optionDisplay="%name% %id_number%"
				/>
			</div>

			@if (!isOwner && showSearchByPhone == "yes") {
				<div class="searchBar">
					<app-menu-contactway-search />
				</div>
			}
			@if (
				permsService?.perms?.administrative ||
				permsService?.perms?.add_new_patient
			) {
				<button
					class="btn btn-icon open-patient-form"
					(click)="openPatientForm()"
					titleLang="add"
					icon="insert"
					isImg
				></button>
			}
		</div>

		<div class="user-menu">
			<div class="d-none d-lg-block">
				<div
					(click)="userMenuOpen($event)"
					titleLang="user"
					class="pointer"
					postIcon="caret-down"
				>
					{{ cliniqData?.user_name }}
				</div>

				@if (userMenuIsOpen) {
					<div class="menu-float">
						<div
							routerLink="/user"
							titleLang="my_account"
						>
							<div icon="setting1"></div>
							<span>{{ "my_account" | langVal }}</span>
						</div>
						<div
							[routerLink]="
								permsService?.owner_has_users && !isOwner
									? 'sub-user-config-cliniq'
									: 'config-cliniq'
							"
							titleLang="configuration"
						>
							<div icon="setting2"></div>
							<div>{{ "configuration" | langVal }}</div>
						</div>

						@if (permsService?.perms?.administrative) {
							<div
								routerLink="/purchases/create"
								titleLang="payment"
							>
								<div icon="invoice"></div>
								<div>{{ "payment" | langVal }}</div>
							</div>
							<div
								titleLang="get_2fa_key"
								(click)="store.openGoogle2faModal()"
							>
								<div icon="authenticate"></div>
								<div>{{ "get_2fa_key" | langVal }}</div>
							</div>
							<div
								titleLang="affiliate_link"
								(click)="openAffiliateLink()"
							>
								<div icon="affiliate"></div>
								<div>{{ "affiliate_link" | langVal }}</div>
							</div>
							<div
								titleLang="create_takbull_account"
								(click)="openCreateTakbullAccount()"
							>
								<div icon="takbull"></div>
								<div>{{ "create_takbull_account" | langVal }}</div>
							</div>
							<div
								titleLang="create_grow_account"
								(click)="openCreateGrowAccount()"
							>
								<div
									icon="grow"
									isImg="true"
								></div>
								<div>{{ "create_grow_account" | langVal }}</div>
							</div>
							@if (enable_israel_invoice) {
								<div
									titleLang="ita_get_auth"
									(click)="openItaGetAuth()"
								>
									<div icon="info"></div>
									<div>{{ "ita_get_auth" | langVal }}</div>
								</div>
							}
							@if (permsService?.owner_has_users) {
								<div
									titleLang="invite_partner"
									(click)="openInvitePartner()"
								>
									<div icon="takbull"></div>
									<div>{{ "invite_partner" | langVal }}</div>
								</div>
							}
						}
						<!-- @if(!permsService?.owner_has_users){
              <div titleLang="time_allocation" routerLink="/time-allocation">
                <div icon="calendar1"></div>
                <div>{{"time_allocation" | langVal}}</div>
              </div>
            } -->

						<div
							titleLang="change_password"
							(click)="openChangePassword()"
						>
							<div icon="changePassword"></div>
							<div>{{ "change_password" | langVal }}</div>
						</div>
						<!--            <div titleLang="walkthrough" (click)="modalService.openMulti('walkthrough')">-->
						<!--              <div icon="changePassword"></div>-->
						<!--              <div>{{"walkthrough" | langVal}}</div>-->
						<!--            </div>-->
					</div>
				}
			</div>

			<div
				(click)="logout()"
				titleLang="logout"
				icon="logout"
				class="pointer"
			></div>

			<div
				[routerLink]="isOwner ? 'config-cliniq' : '/'"
				title="My-cliniq"
				class="pointer"
			>
				<img
					[src]="environment.serverUrl + '/images/newdesign/main-logo.png'"
					class="LOGO"
				/>
			</div>
		</div>
	</div>
</div>
