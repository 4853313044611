@if (isStatic) {
	<div [innerHTML]="previousValue | safeHtml"></div>
} @else {
	<div
		class="wysiwyg"
		[class.maximized]="isMaximized"
	>
		@if (isMaximized) {
			<label class="followup-title">{{
				title_ph_lang || fieldName | langVal
			}}</label>
		}

		<div class="toolbar d-flex justify-content-between">
			<div class="d-flex align-items-center">
				<button
					titleLang="bold"
					[attr.aria-label]="'bold' | langVal"
					[class.active]="curProps.B"
					(click)="comm('bold')"
				>
					<b>B</b>
				</button>
				<button
					titleLang="italic"
					[attr.aria-label]="'italic' | langVal"
					[class.active]="curProps.I"
					(click)="comm('italic')"
				>
					<i>I</i>
				</button>
				<button
					titleLang="underline"
					[attr.aria-label]="'underline' | langVal"
					[class.active]="curProps.U"
					(click)="comm('underline')"
				>
					<u>U</u>
				</button>
				<button
					titleLang="removeformat"
					[attr.aria-label]="'removeformat' | langVal"
					class="no-very-small-mob"
					(click)="comm('removeformat')"
					icon="remove-circle"
				></button>

				@if (colorDisplay) {
					<div class="note-holder">
						<div>
							@for (colArr of paletteColors; track colArr) {
								<div>
									@for (col of colArr; track col) {
										<button
											[style.backgroundColor]="'#' + col"
											(click)="colorSelect(col)"
										></button>
									}
								</div>
							}
						</div>
					</div>
				}

				<button
					titleLang="bgColor"
					[attr.aria-label]="'bgColor' | langVal"
					class="color no-small-mob"
					[style.backgroundColor]="props.bgColor"
					(click)="opColorPalette('bgColor', $event)"
				></button>
				<button
					titleLang="color"
					[attr.aria-label]="'color' | langVal"
					class="color no-small-mob"
					[style.backgroundColor]="props.color"
					(click)="opColorPalette('color', $event)"
				></button>

				<button
					titleLang="rtl"
					[attr.aria-label]="'rtl' | langVal"
					(click)="textDirection('rtl')"
					class="no-very-small-mob"
					class="dir-btn no-small-mob"
				>
					<app-icon icon="paragraph" />
					<app-icon icon="caret-left" />
				</button>
				<button
					titleLang="ltr"
					[attr.aria-label]="'ltr' | langVal"
					(click)="textDirection('ltr')"
					class="no-very-small-mob"
					class="dir-btn no-small-mob"
				>
					<app-icon icon="caret-right" />
					<app-icon icon="paragraph" />
				</button>

				@if (alignDisplay) {
					<div style="position: relative">
						<div class="align-holder">
							<button
								titleLang="justifyRight"
								[attr.aria-label]="'justifyRight' | langVal"
								class="no-very-small-mob"
								(click)="comm('justifyRight')"
								[class.active]="curProps.right"
								icon="align-right"
							></button>
							<button
								titleLang="justifyCenter"
								[attr.aria-label]="'justifyCenter' | langVal"
								class="no-very-small-mob"
								(click)="comm('justifyCenter')"
								[class.active]="curProps.center"
								icon="align-center"
							></button>
							<button
								titleLang="justifyLeft"
								[attr.aria-label]="'justifyLeft' | langVal"
								(click)="comm('justifyLeft')"
								class="no-very-small-mob"
								[class.active]="curProps.left"
								icon="align-left"
							></button>
						</div>
					</div>
				}
				<button
					titleLang="justify"
					[attr.aria-label]="'justify' | langVal"
					class="no-very-small-mob align-select"
					(click)="opAlign($event)"
					[icon]="
						'align-' +
						(curProps.left ? 'left' : curProps.center ? 'center' : 'right')
					"
				></button>

				<!-- <button titleLang="justifyRight" [attr.aria-label]="'justifyRight' | langVal"  class="no-very-small-mob"
        (click)="comm('justifyRight')" [class.active]="curProps.right" icon="align-right"></button>
      <button titleLang="justifyCenter" [attr.aria-label]="'justifyCenter' | langVal"  class="no-very-small-mob"
        (click)="comm('justifyCenter')" [class.active]="curProps.center" icon="align-center"></button>
      <button titleLang="justifyLeft" [attr.aria-label]="'justifyLeft' | langVal" (click)="comm('justifyLeft')"  class="no-very-small-mob"
        [class.active]="curProps.left" icon="align-left"></button> -->
				<!-- <div style="position:relative;" (click)="justifyClick($event)">
        <div  class="pointer" style="display:flex">
          <app-icon [icon]="'align-'+(curProps.left ? 'left' : ( curProps.center ? 'center' : 'right'))" />
          <app-icon icon="caret-down" />
        </div>
        <button [titleLang]="curProps.left ? 'justifyLeft' : ( curProps.center ? 'justifyCenter' : 'justifyRight')" [attr.aria-label]="(curProps.left ? 'justifyLeft' : ( curProps.center ? 'justifyCenter' : 'justifyRight')) | langVal"  class="no-very-small-mob"
          (click)="justifyBtnsOpened=!justifyBtnsOpened"  [icon]="'align-'+(curProps.left ? 'left' : ( curProps.center ? 'center' : 'right'))"></button>
        @if(justifyBtnsOpened){
          <div style="position: absolute;display: flex;background-color: #eee;border-radius: 6px;">
          </div>
        }
      </div> -->

				<select
					titleLang="font_size"
					[(ngModel)]="curProps.size"
					(change)="fontSize()"
				>
					@for (opt of fontSizes; track opt) {
						<option [value]="opt">{{ opt }}</option>
					}
				</select>
				<!-- <select [(ngModel)]="curProps.name" (change)="fontName()">
        @for(opt of fontNameOpts;track opt){
          <option [value]="opt">{{opt}}</option>
        }
      </select> -->

				<button
					titleLang="orderedList"
					[attr.aria-label]="'orderedList' | langVal"
					(click)="comm('insertOrderedList')"
					[class.active]="curProps.OL"
					icon="list-ol"
				></button>
				<button
					titleLang="unorderedList"
					[attr.aria-label]="'unorderedList' | langVal"
					class="no-very-small-mob"
					(click)="comm('insertUnorderedList')"
					[class.active]="curProps.UL"
					icon="list-ul"
				></button>

				<div style="position: relative">
					@if (tblDisplay) {
						<div
							class="tbl-holder"
							(click)="chooseTbl()"
						>
							<div>
								@for (num of num10; track num; let ind = $index) {
									<div>
										@for (num2 of num10; track num2; let ind2 = $index) {
											<div
												(mouseover)="moTbl(ind, ind2)"
												[class.on]="curView[0] >= ind && curView[1] >= ind2"
											></div>
										}
									</div>
								}
							</div>
							<div style="text-align: center; padding-top: 4px; direction: ltr">
								{{ curView[0] + 1 }} X {{ curView[1] + 1 }}
							</div>
						</div>
					}
				</div>

				<button
					titleLang="table"
					[attr.aria-label]="'table' | langVal"
					(click)="tblClick($event)"
				>
					T
				</button>
				<button
					titleLang="maximize"
					[attr.aria-label]="'maximize' | langVal"
					(click)="maximini()"
					[icon]="isMaximized ? 'minimize' : 'maximize'"
				></button>

				@if (isDocumentTemplate) {
					@if (documentTemplatesDisplay) {
						<div style="position: relative">
							<div
								class="align-holder"
								style="
									flex-direction: column;
									display: grid;
									grid-template-columns: 1fr 1fr 1fr;
								"
							>
								@for (
									documentTemaplateBtn of documentTemaplateBtns;
									track documentTemaplateBtn
								) {
									<button
										[title]="documentTemaplateBtn | langVal"
										[attr.aria-label]="documentTemaplateBtn | langVal"
										(click)="insertTemplateString(documentTemaplateBtn)"
									>
										[{{ documentTemaplateBtn | langVal }}]
									</button>
								}
								@for (patientfield of patientfields; track patientfield) {
									<button
										[title]="patientfield | langVal"
										[attr.aria-label]="patientfield | langVal"
										(click)="insertTemplateString(patientfield)"
									>
										[{{ patientfield | langVal }}]
									</button>
								}
							</div>
						</div>
					}
					<button
						titleLang="select_tag"
						[attr.aria-label]="'select_tag' | langVal"
						class="align-select"
						(click)="opDocumentTemplates($event)"
						icon="patient"
					></button>
				}
			</div>
			<div class="d-flex align-items-center">
				@if (showAutotextsBtn) {
					<button
						titleLang="autotexts"
						tabindex="-1"
						class="btn btn-primary texts-btn"
						(click)="openAutotexts.emit(true)"
					>
						<app-icon
							icon="autotexts"
							isImg="true"
						/>
						<span> {{ "autotexts" | langVal }}</span>
					</button>
				}
				@if (showTestsBtn) {
					<button
						titleLang="tests"
						tabindex="-1"
						class="btn btn-primary texts-btn"
						(click)="openTests.emit(true)"
					>
						<app-icon
							icon="test-white"
							isImg="true"
						/>
						<span> {{ "tests" | langVal }}</span>
					</button>
				}
				<!--      @if(isMedicalRecord){-->
				<!--        <button titleLang="tests" [attr.aria-label]="'tests' | langVal"-->
				<!--                (click)="openTests()" icon='tests'></button>-->
				<!--        <button titleLang="autotexts" [attr.aria-label]="'autotexts' | langVal"-->
				<!--                (click)="openAutotexts()" icon='autotexts'></button>-->
				<!--      }-->
			</div>
		</div>

		<div
			#editor
			[innerHTML]="previousValue | safeHtml"
			[attr.data-text]="placeholder || (title_ph_lang || fieldName | langVal)"
			[titleLang]="title_ph_lang || fieldName"
			[style.height]="height + 'px'"
			class="editor"
			contenteditable="true"
			[tabindex]="tabindex"
			(keyup)="onChange()"
			(input)="onChange()"
			[style]="exHtmlStyle"
			[style.font-size]="defaultFontSize ? defaultFontSize + 'px' : ''"
			(click)="onClick()"
			(paste)="onPaste($event)"
		></div>
		@if (isMaximized) {
			<div
				class="bottom-bar"
				(mousedown)="dragMouseDown($event)"
			></div>
		}

		<canvas
			#canvas
			style="position: absolute; top: -1000000000000000000px; display: none"
		></canvas>

		@if (hoverOverTbl?.top) {
			<div
				class="tbl-hover hover-gen"
				[style.top]="hoverOverTbl?.top + 'px'"
				[style.left]="hoverOverTbl?.left + 'px'"
			>
				<div
					(click)="hoverTblClick('row')"
					titleLang="row"
				>
					{{ "row" | langVal }}
					@if (hoverTblOpens?.row) {
						<div
							class="hover-gen"
							(click)="$event.stopImmediatePropagation()"
						>
							<div
								(click)="tblChange('row', 'above')"
								titleLang="above"
							>
								{{ "above" | langVal }}
							</div>
							<div
								(click)="tblChange('row', 'below')"
								titleLang="below"
							>
								{{ "below" | langVal }}
							</div>
							<div
								(click)="tblChange('row', 'remove')"
								titleLang="remove"
							>
								{{ "remove" | langVal }}
							</div>
							<div
								(click)="hoverTblOpens.bgCol = !hoverTblOpens?.bgCol"
								titleLang="bg_color"
								icon="fill"
							></div>
							@if (hoverTblOpens?.bgCol) {
								<div class="note-holder">
									<div>
										@for (colArr of paletteColors; track colArr) {
											<div>
												@for (col of colArr; track col) {
													<button
														[style.backgroundColor]="'#' + col"
														(click)="tblChange('row', 'bg-color', col)"
													></button>
												}
											</div>
										}
									</div>
								</div>
							}
						</div>
					}
				</div>

				<div
					(click)="hoverTblClick('col')"
					titleLang="col"
				>
					{{ "col" | langVal }}
					@if (hoverTblOpens?.col) {
						<div
							class="hover-gen"
							(click)="$event.stopImmediatePropagation()"
						>
							<div
								(click)="tblChange('col', 'before')"
								titleLang="before"
							>
								{{ "before" | langVal }}
							</div>
							<div
								(click)="tblChange('col', 'after')"
								titleLang="after"
							>
								{{ "after" | langVal }}
							</div>
							<div
								(click)="tblChange('col', 'remove')"
								titleLang="remove"
							>
								{{ "remove" | langVal }}
							</div>
						</div>
					}
				</div>
			</div>
		}
		@if (isMaximized) {
			<div style="margin-top: 4px; display: flex; justify-content: end">
				<button
					(click)="maximini()"
					class="btn btn-primary"
				>
					{{ "minimize" | langVal }}
				</button>
			</div>
		}
	</div>
}
