import { Component, OnInit } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { PaymentFormObject } from "@app/definitions/types"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { EventsService } from "@app/services/events.service"

@Component({
	selector: "app-payment-form",
	template: `
		<div class="payment-form container-fluid">
			<div class="center-small-div">
				<div>
					{{ "hello" | langVal }} {{ paymentFormObject.user_name }} ,<br />
					{{ "your_monthly_payment" | langVal
					}}{{ paymentFormObject.currency_symbol
					}}{{ paymentFormObject.monthlyPrice }}{{ "plus_vat" | langVal }}.
				</div>
				<div>
					{{ "your_outstanding_payment" | langVal }}
					{{ paymentFormObject.currency_symbol }}{{ paymentFormObject.price }}.
				</div>
				@if (paymentFormObject.last_payment_paid) {
					<div>
						{{ "you_paid" | langVal }} {{ paymentFormObject.currency_symbol
						}}{{ paymentFormObject.last_payment_paid }} {{ "on" | langVal }}
						{{ paymentFormObject.last_payment_date }}.
					</div>
				}
				<div>
					({{ "you_registered_at" | langVal }}
					{{ paymentFormObject.createdAt }}).
				</div>

				<!-- TAKBULL -->
				@if (isBackFromFirstApiCall) {
					<button
						class="btn btn-tall btn-primary"
						[disabled]="paymentFormObject.price <= 0"
						(click)="redirect()"
						[spinner]="isFetching2ndApi"
					>
						{{ "text_paying_first_5" | langVal }}
					</button>
				}
			</div>
		</div>
	`,
})
export class PaymentFormComponent implements OnInit {
	paymentFormObject: PaymentFormObject = new PaymentFormObject() //object containing the data to be displayed and the iframe link
	isSaving: boolean = false
	isBackFromFirstApiCall: boolean = false
	isFetching2ndApi: boolean = false

	constructor(
		private apiService: ApiService,
		public lang: LangService,
		public sanitizer: DomSanitizer,
		public eventsService: EventsService
	) {}

	async ngOnInit() {
		this.isFetching2ndApi = false
		this.isBackFromFirstApiCall = false
		this.isSaving = false
		const res: any = await this.apiService.post("get_payment_form_data")
		this.isBackFromFirstApiCall = true
		this.paymentFormObject = res
	}
	async redirect() {
		this.isFetching2ndApi = true
		const res: any = await this.apiService.post("get_takbull_redirect_link")
		this.isFetching2ndApi = false
		if (res.link) {
			;(window.location.href as any) = res.link
		}
	}
}
