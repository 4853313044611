<!--<button class="btn btn-primary" (click)="openStatusModal()">-->
<!--  {{"status" | langVal}}-->
<!--</button>-->
<!--@if(permsService?.perms?.administrative){-->
<!--  <button class="btn btn-default" (click)="openAutotextModal()">-->
<!--    {{"add_insert_autotext" | langVal}}-->
<!--  </button>-->
<!--}-->
<div class="comp-menu">
	<div class="d-flex justify-content-end">
		@if (permsService?.perms?.administrative) {
			<button
				class="btn btn-icon btn-plus"
				(click)="openinsertAutotest()"
				titleLang="add"
				icon="add"
				isImg
			></button>
			<!--  style="height: 16px;"-->
		}
	</div>

	<div class="items">
		<p>{{ "choose_autotext" | langVal }}</p>

		<div>
			<app-gen-input
				[actualObj]="this"
				fieldName="search"
				(keyupEvent)="handleSearch()"
			/>
		</div>
		@if (permsService?.perms?.administrative) {
			<app-icon
				[icon]="showAll ? 'narrow' : 'expand'"
				(click)="flipShowAll()"
			/>
		}

		@if (!search) {
			<div class="tests-autotext-menu-list d-flex flex-column status">
				<div class="d-flex">
					<div class="colstat">
						<app-icon
							icon="expand"
							(click)="openStatusModal()"
						/>
					</div>
					<div class="colstat">
						<div
							(click)="openStatusModal()"
							class="colstat-small-triple"
						>
							{{ "status" | langVal }}
						</div>
					</div>
				</div>
			</div>
		}
		@for (autotext of dispRows; track autotext.id) {
			<div class="tests-autotext-menu-list d-flex flex-column">
				<div class="d-flex">
					<div class="colstat">
						@if (permsService?.perms?.administrative) {
							<app-icon
								[icon]="autotext.showText ? 'narrow' : 'expand'"
								(click)="autotext.showText = !autotext.showText"
							/>
						}
					</div>
					<div
						(click)="close(autotext.html_autotext)"
						class="colstat-small-triple"
					>
						{{ autotext.name }}
					</div>
					<div class="text-end colstat-small">
						@if (permsService?.perms?.administrative) {
							<app-icon
								icon="settings1"
								(click)="
									openUpdateAutotext(autotext);
									$event.stopImmediatePropagation()
								"
							/>
						}
					</div>
				</div>
				<!--      <div  class="colstat colstat-small">-->
				<!--        @if (permsService?.perms?.administrative) {-->
				<!--          <app-icon icon="show" (click)="openUpdateAutoText(autotext);$event.stopImmediatePropagation();"/>-->
				<!--        }-->
				<!--      </div>-->
				@if (autotext.showText) {
					<div>
						<app-wysiwyg
							#descriptionWysiwyg
							[actualObj]="autotext"
							fieldName="html_autotext"
							[tabindex]="1"
							(changeEvent)="saveField(autotext)"
							title_ph_lang="html_autotext"
							[initHeight]="
								eventsService.isMobile ? eventsService.MobileHeight - 610 : null
							"
						/>
					</div>
				}
				<!--        (changeEvent)="save()"-->
			</div>
		}
	</div>
</div>
