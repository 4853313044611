import { EventsService } from "@app/services/events.service"
import {
	CalendarExportAction,
	ConfigDefinitions,
	Followup,
	HomePersonColumns,
	PatientOrUserId,
	Task,
	TimeStatuses,
} from "@app/definitions/types"
import { environment } from "@env/environment"
import { Component, OnInit, ViewChild } from "@angular/core"
import { LangService } from "@app/services/lang.service"
import { ApiService } from "@app/services/api.service"
import { PatientComponent } from "@app/patient/patient.component"
import { StoreService } from "@app/services/store.service"
import { ConstantPool } from "@angular/compiler"
import { PermsService } from "@app/services/perms.service"
import { Router } from "@angular/router"
import * as moment from "moment"

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
	fusObj: any = null
	patient: any = null
	user: any = null
	errors: any = {} //will hold errors for the fields in the form
	isLoading: boolean = true
	dayEvents: any[] = []
	showColumn: HomePersonColumns = "calendar"
	followup: Followup = null
	@ViewChild("patientComponent") patientComponent: PatientComponent

	isFirstClick: boolean = true
	isInLoadAllDay: boolean = false
	lastCalendarClickId: number = 0
	currentDate: string = ""
	timeStatus: TimeStatuses = "present"

	constructor(
		public lang: LangService,
		public apiService: ApiService,
		public eventsService: EventsService,
		protected store: StoreService,
		public permsService: PermsService,
		public router: Router
	) {}

	ngOnInit() {
		const isOwner = this.store.getCliniqDataOrExit()?.has_users

		if (isOwner) {
			this.router.navigateByUrl("/config-cliniq", { replaceUrl: true })
		} else if (
			this.permsService.owner_has_users &&
			this.permsService.perms?.sees_admin_homepage &&
			!this.permsService.perms?.clinical
		) {
			//go to admin homepage!
			this.router.navigateByUrl("/home-admin", { replaceUrl: true })
		}

		if (this.eventsService.isMobile) {
			this.showColumn = "calendar"
		}
		this.eventsService.isMobileSubj.subscribe(() => {
			this.showColumn = this.eventsService.isMobile ? "calendar" : "all"
		})
		this.eventsService.updateTitle(this.lang.getVal("home"))
	}

	dayEventsChange(events: any[]) {
		this.patient = null
		this.dayEvents = [...events]
	}

	async loadAllDayEvents() {
		if (this.isInLoadAllDay) {
			return
		}
		const notYetLoadedFus = this.dayEvents.filter((fus) => !fus.loaded)
		if (notYetLoadedFus.length) {
			this.isInLoadAllDay = true
			await this.store.timeout(2000)
			const fus = notYetLoadedFus[0]

			if (fus.patient_id) {
				await this.store.getPatient(fus.patient_id)
				// if (fus.followup_id) {		//can this EVER happen?
				// 	this.store.getFollowup(fus.followup_id);
				// }
				this.store.getFollowupForSeries(
					fus.patient_id,
					fus.id,
					fus.date,
					"patient_id"
				)
			} else if (fus.user_id) {
				this.store.getFollowupForSeries(
					fus.user_id,
					fus.id,
					fus.date,
					"user_id"
				)
			}
			fus.loaded = true
			this.isInLoadAllDay = false
			this.loadAllDayEvents()
		}
	}

	calendarChange(obj: CalendarExportAction) {
		this.dayEvents = obj.dayEvents
		this.loadAllDayEvents()

		if (this.currentDate != obj.currentDate) {
			this.isFirstClick = true
		}

		this.currentDate = obj.currentDate

		const todayMom = moment.utc(
			moment.utc().format(ConfigDefinitions.momentDateFormat)
		)

		const isPast = moment.utc(this.currentDate).isBefore(todayMom)
		const isPresent = moment.utc(this.currentDate).isSame(todayMom)
		const isFuture = moment.utc(this.currentDate).isAfter(todayMom)

		this.timeStatus = isPast ? "past" : isPresent ? "present" : "future"

		const centerFusCandidate = obj.centerFus || this.fusObj
		if (centerFusCandidate) {
			this.fusObj = this.dayEvents.find(
				(ev) =>
					ev.id == centerFusCandidate.id &&
					centerFusCandidate.date == ev.date &&
					ev.date == this.currentDate
			)
		}
		if (!this.fusObj && this.dayEvents.length) {
			this.fusObj = this.dayEvents[0]
		}
		this.loadIntoCenter()

		//comparing centerFus to new/current - date MUST be same as currentDate
		//when getting followup, before loading to center, make sure it's SAME DATE as currentDate
	}
	loadIntoCenter() {
		this.patient = null
		this.user = null
		this.followup = null
		if (this.fusObj) {
			this.calendarClick()
		} else {
			this.isLoading = false
		}
	}

	evSameAsLast(ev: any) {
		return ev.id == this.lastCalendarClickId //&& ev.currentDate == this.lastCalendarCurrentDate
	}

	refreshLastEv() {
		const ev = this.dayEvents.find((ev) => this.evSameAsLast(ev))
		this.lastCalendarClickId = 0

		if (ev) {
			// this.calendarClick(ev);
		} else if (this.dayEvents.length) {
			// this.calendarClick(this.dayEvents[0]);
		} else {
			this.isLoading = false
		}
	}

	isObjStillPartOfDayEvents(obj: any) {
		return (
			obj &&
			this.dayEvents.find((it) => it.id == obj.id) &&
			this.currentDate == obj.date
		)
	}

	async calendarClick() {
		// this.followup = null;

		// if (!this.isObjStillPartOfDayEvents(obj)) {	//if failed to find an event in dayEvents with the same (fus) id - avoid it
		// 	obj = null;
		// 	return this.refreshLastEv(); 	//go load either last fus loaded or first dayEvent
		// }
		// else if (obj && this.evSameAsLast(obj)) { return; }
		// this.lastCalendarClickId = obj.id;

		if (!this.isFirstClick) {
			this.showColumn = "center"
		}
		this.isFirstClick = false
		// if(this.followupObjCache[obj.id]){
		// 	this.followupObj=this.followupObjCache[obj.id];
		// }
		// else{
		// 	this.followupObjCache[obj.id]=obj;
		// }

		// this.fusObj = obj;
		if (this.fusObj) {
			//this.lastFusId = obj.id;
			this.isLoading = true

			if (this.fusObj.patient_id) {
				//this.followupObj.patient_id && !this.followupObj.patient){
				const patientId = this.fusObj.patient_id
				const loadingFusInfo: any = {
					id: this.fusObj.id,
					date: this.fusObj.date,
				}
				const patient: any = await this.store.getPatient(this.fusObj.patient_id)
				if (!this.isObjStillPartOfDayEvents(loadingFusInfo)) {
					return
				}

				if (!this.fusObj || this.fusObj.patient_id != patientId) {
					return
				}
				this.patient = patient

				await this.loadFollowup(
					this.fusObj.patient_id,
					this.fusObj.id,
					this.fusObj.date,
					"patient_id"
				)
				if (!this.isObjStillPartOfDayEvents(loadingFusInfo)) {
					return
				}
				const thisFus = this.dayEvents.find((fus) => fus.id == this.fusObj.id)
				if (thisFus) {
					thisFus.loaded = true
				}
				this.loadAllDayEvents()
				this.loadData()
			} else if (this.fusObj.user_id) {
				this.user = this.store.searchSiteDataItem(this.fusObj.user_id, [
					"sub_users",
				])
				const loadingFusInfo: any = {
					id: this.fusObj.id,
					date: this.fusObj.date,
				}
				await this.loadFollowup(
					this.fusObj.user_id,
					this.fusObj.id,
					this.fusObj.date,
					"user_id"
				)
				if (!this.isObjStillPartOfDayEvents(loadingFusInfo)) {
					return
				}
				const thisFus = this.dayEvents.find((fus) => fus.id == this.fusObj.id)
				if (thisFus) {
					thisFus.loaded = true
				}
				this.loadAllDayEvents()
				this.loadData()
			} else {
				this.patient = this.fusObj.patient
				this.loadData()
			}
		} else {
			this.isLoading = false
		}
	}

	async loadFollowup(
		person_id: number,
		series_id: number,
		date: string,
		fieldname: PatientOrUserId
	) {
		;(this.followup as any) = await this.store.getFollowupForSeries(
			person_id,
			series_id,
			date,
			fieldname
		)
	}

	moveToOtherFollowup(isNext: boolean) {
		const id = this.fusObj.id
		if (this.dayEvents.length <= 1) {
			console.log("no other events")
			return
		}
		let index = this.dayEvents.findIndex((it) => it.id == id)
		index += isNext ? 1 : -1
		if (index == this.dayEvents.length) {
			index = 0
		}
		if (index == -1) {
			index = this.dayEvents.length - 1
		}
		const fus = this.dayEvents[index]
		if (fus) {
			this.fusObj = fus
			this.loadIntoCenter()
		}
		// this.calendarClick(this.dayEvents[index]);
	}

	loadData() {
		this.isLoading = false
		let patient = this.patient
		if (!patient) {
			return
		}
	}

	validateField(fieldName: string) {}

	updateShowColumnLocal(col: HomePersonColumns) {
		this.showColumn = col
		this.eventsService.scrollToTop()
	}
}

@Component({
	selector: "app-home-admin",
	template: `<app-cc-sections
		name="multiuser"
		section="multiuser"
	/>`,
})
export class HomeAdminComponent {}

@Component({
	selector: "app-home-super-admin",
	template: `<app-cc-sections section="home-super-admin" />`,
})
export class HomeSuperAdminComponent {}
