import { Directive, ElementRef, HostListener, Input } from "@angular/core"
import { LangService } from "@app/services/lang.service"

@Directive({
	selector: "[titleLang]",
})
export class TitleLangDirective {
	//this directive adds event listeners to modals to enable drag/drop
	@Input() titleLang: string = ""
	constructor(
		private el: ElementRef,
		private lang: LangService
	) {}

	ngAfterViewInit() {
		this.el.nativeElement.title = this.lang.getVal(this.titleLang)
	}
}
