import { Component, OnInit } from "@angular/core"
import { LangService } from "@app/services/lang.service"

@Component({
	selector: "app-payment-client-finish-grow",
	template: `
		<app-payment-wrap>
			<h2>{{ "payment_successful" | langVal }}</h2>
			<br />
			<h3>{{ "payment_successful1" | langVal }}</h3>
			<br />
		</app-payment-wrap>
	`,
})
export class PaymentClientFinishGrowComponent implements OnInit {
	success: boolean = false //was the purchase a success
	constructor(public lang: LangService) {}

	ngOnInit() {
		let input = location.search.split("?").pop() //gets the query string (everything in the URL after the ? sign)
		let statusCode = decodeURIComponent(input) //decodes the url (so it can be parsed as request pairs)
			.split("&") //splits to pairs
			.map((it) => it.split("=")) //split each pair to array of [name,value]
			.find((it) => it[0] == "response") //tried to find an item where the name is "Response"

		if (statusCode && statusCode[1] == "success") {
			//found an item and the response was "000"
			this.success = true //change success to true
		}
	}
}
