import { Component, Input, OnInit } from "@angular/core"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-self-coordination",
	template: `
		<div>
			<h4 class="gen-title">{{ "self_coordination" | langVal }}</h4>
			<hr />
			@if (!permsService?.owner_has_users) {
				<div class="row">
					<div class="col-4 col-md-2">
						<button
							class="btn btn-primary"
							style="margin-top: 25px"
							titleLang="time_allocation"
							tabindex="-1"
							routerLink="/time-allocation/"
						>
							{{ "time_allocation" | langVal }}
						</button>
					</div>
					<div class="col-4 col-md-2">
						<label>{{ "meeting_request_with_calendar" | langVal }}</label>
						<app-gen-button-group
							[actualObj]="configcliniq"
							fieldName="meeting_request_with_calendar"
							optionsListName="yes_no_opts"
							(changeEvent)="validateAndSave()"
						/>
					</div>
					<div class="col-4 col-md-2">
						<label>{{ "guest_sched_hours_to_add" | langVal }}</label>
						<app-gen-input
							[tabindex]="64"
							[actualObj]="configcliniq"
							[errors]="errors"
							fieldName="guest_sched_hours_to_add"
							(keyupEvent)="validateAndSave()"
						/>
					</div>
				</div>
			}
			@if (apiLink) {
				<div class="row">
					<div class="col-12">
						<button
							class="btn btn-primary"
							(click)="copyApiLink()"
							titleLang="copy_link"
						>
							{{ "copy_external_link" | langVal }}
						</button>
					</div>
				</div>
			}
			<hr class="separation-line" />
			<app-cc-params-user-details-website></app-cc-params-user-details-website>
		</div>
	`,
})
export class CcParamsSelfCoordinationComponent extends CcParamsGen {
	//inheriting most functions and fields!
	saveObjName: string = "self-coordination" //which part to save while updating
	apiLink: string = ""
	apiGuestLink: string = ""

	async ngOnInit() {
		this.getApiKeys()
		this.getGuestApiKeys()
	}
	async getApiKeys() {
		this.apiLink = ""
		const meetingtypes = this.store.getSiteDataTable("meetingtypes")

		if (!this.permsService.owner_has_users) {
			if (
				!meetingtypes.find((mt) => mt?.first_time_meeting_request == "yes") &&
				!meetingtypes.find((mt) => mt?.return_meeting_request == "yes")
			) {
				this.apiLink = ""
				return
			}
		}

		if (!this.apiLink) {
			this.apiLink = await this.store.getApiLink()
		}
	}

	async copyApiLink() {
		if (!this.apiLink) {
			this.modalService.openMulti("confirm", {
				actionLang: this.lang.getVal(
					"meetingtypes_and_time_allocation_shoud_be_defined"
				),
				hasCancel: false,
			})
			return
		}
		navigator.clipboard.writeText(this.apiLink)
		this.modalService.openToast(this.lang.getVal("copy_link"))
		window.open(this.apiLink, "_blank")
	}
	async getGuestApiKeys() {
		this.apiGuestLink = (await this.store.getApiLink()).replace(
			"sched",
			"payment"
		)
	}
	async copyApiGuestLink() {
		navigator.clipboard.writeText(this.apiGuestLink)
		this.modalService.openToast(this.lang.getVal("copy_link"))
		//window.open(this.apiLink, '_blank');
	}
}
