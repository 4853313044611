<app-cc-sections
	name="automation"
	section="automation"
/>
<div>
	<h4 titleLang="smses">
		<span>{{ "smses" | langVal }}</span>
	</h4>

	@if (permsService?.perms?.administrative) {
		<div class="row">
			<div class="col-6">
				@if (configcliniq?.consent_to_sms_contract == "no") {
					<span>
						{{ "to_sms_terms_description" | langVal }}:
						<button
							titleLang="to_sms_terms"
							class="btn btn-primary"
							(click)="openTerms()"
						>
							{{ "to_sms_terms" | langVal }}
						</button>
					</span>
				} @else {
					<p>{{ "sms_approved_cancel" | langVal }}</p>
				}
			</div>
		</div>
		@if (configcliniq?.consent_to_sms_contract == "yes") {
			<div style="background-color: #e5f6df; padding: 10px">
				<div class="row">
					<div class="col-6 col-md-3">
						<ng-container
							*ngTemplateOutlet="inp; context: { fieldName: 'name_on_message' }"
						/>
					</div>
					<div class="col-3 col-md-2">
						<ng-container
							*ngTemplateOutlet="
								btnGroup;
								context: {
									fieldName: 'time_of_sms',
									optionsListName: 'morning_evening',
								}
							"
						/>
					</div>
					<div class="col-3">
						<ng-container
							*ngTemplateOutlet="
								inp;
								context: {
									fieldName: 'days_before_meeting_message',
									title: 'days_to_sms',
								}
							"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col-6 col-md-3">
						{{ "name_should_contain" | langVal }}
					</div>
					<div class="col-6 col-md-3">
						{{ "time_of_sms_description" | langVal }}
					</div>
				</div>
				<div class="row">
					<div class="col-6 col-md-2">
						<button
							titleLang="patients_sms"
							tabindex="-1"
							class="btn btn-primary"
							(click)="openPatients_sms()"
						>
							{{ "patients_sms" | langVal }}
						</button>
					</div>
				</div>
			</div>
		}
	}

	<hr class="separation-line" />
</div>

<ng-template
	#inp
	let-fieldName="fieldName"
	let-title="title"
	let-tabindex="tabindex"
>
	<label>{{ title || fieldName | langVal }}</label>
	<app-gen-input
		[tabindex]="tabindex || -1"
		[actualObj]="configcliniq"
		[errors]="errors"
		[fieldName]="fieldName"
		[title_ph_lang]="title || fieldName"
		(keyupEvent)="validateAndSave()"
	/>
</ng-template>
<ng-template
	#btnGroup
	let-fieldName="fieldName"
	let-optionsListName="optionsListName"
>
	<label>{{ fieldName | langVal }}</label>
	<app-gen-button-group
		[actualObj]="configcliniq"
		[fieldName]="fieldName"
		[optionsListName]="optionsListName"
		(changeEvent)="validateAndSave()"
	/>
</ng-template>
<ng-template
	#btnGroupYesNo
	let-fieldName="fieldName"
>
	<ng-container
		*ngTemplateOutlet="
			btnGroup;
			context: { fieldName: fieldName, optionsListName: 'yes_no_opts' }
		"
	/>
</ng-template>
