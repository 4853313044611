<div class="login-register">
	<div class="login-page">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12"></div>
			</div>
		</div>
		<div class="login-form">
			<!-- Login form -->
			<div class="container">
				@if (mes) {
					<div class="row">
						<div class="col-12">
							<div class="alert alert-success">{{ mes }}</div>
						</div>
					</div>
				}
				<div class="row">
					<div class="col-12 col-lg-6 col-xl-5">
						<div class="form-right">
							<div class="login-header">
								<a href="https://my-cliniq.com">
									<!--              <img [src]="environment.serverUrl+'/assets/img/logo.png'" alt="logo">-->
									<img
										src="https://www.my-cliniq.com/wp-content/uploads/slider-logo3-1500-1024x259.webp"
										alt="logo"
									/>
								</a>
								<p>{{ "site_slogan" | langVal }}</p>
							</div>
							<div
								[class]="
									mode == 'greeting' || mode == 'regi-otp'
										? 'form greeting'
										: 'form'
								"
							>
								@if (mode | inArray: ["reset-password", "expired-password"]) {
									<!--<h3 >{{"password_reset" | langVal}}</h3>-->
									@if (mode == "reset-password") {
										<div class="form-group">
											<app-gen-input
												[actualObj]="userCredentials"
												fieldName="email"
												[errors]="errors"
											/>
										</div>
									}
									<div class="form-group showPassword">
										<div>
											<app-icon
												icon="show"
												pointer
												(click)="showPassword = !showPassword"
											/>
										</div>
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="password"
											type="password"
											[showPassword]="showPassword"
											[errors]="errors"
										/>
									</div>
									<div class="form-group showPassword">
										<div>
											<app-icon
												icon="show"
												pointer
												(click)="showPassword = !showPassword"
											/>
										</div>
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="confirm_password"
											type="password"
											[showPassword]="showPassword"
											[errors]="errors"
										/>
									</div>
									<app-btn-ext
										btnClass="tall btn-primary subm"
										text="password_reset"
										(clickEv)="submit()"
										[isSpin]="isSaving"
										iconName="doubleArrow"
									/>
								}

								@if (
									mode
										| inArray: ["regi", "sub-user-regi", "potential-user-regi"]
								) {
									<div class="form-group">
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="user_name"
											[errors]="errors"
										/>
									</div>
									@if (mode == "regi") {
										<div class="form-group">
											<app-gen-input
												[actualObj]="userCredentials"
												fieldName="email"
												[errors]="errors"
											/>
										</div>
									}
									<div class="form-group">
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="phone_mobile"
											[errors]="errors"
										/>
										<div class="text-info">
											{{ "to_secure_your_account" | langVal }}
										</div>
									</div>
									<div class="form-group showPassword">
										<div>
											<app-icon
												icon="show"
												pointer
												(click)="showPassword = !showPassword"
											/>
										</div>
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="password"
											type="password"
											[showPassword]="showPassword"
											[errors]="errors"
										/>
									</div>
									<div class="form-group showPassword">
										<div>
											<app-icon
												icon="show"
												pointer
												(click)="showPassword = !showPassword"
											/>
										</div>
										<app-gen-input
											[actualObj]="userCredentials"
											fieldName="confirm_password"
											type="password"
											[showPassword]="showPassword"
											[errors]="errors"
										/>
									</div>

									<!-- <div class="form-group">
                    <app-gen-input [actualObj]="userCredentials" fieldName="password" type="password" [errors]="errors" />
                  </div>
                  <div class="form-group">
                    <app-gen-input [actualObj]="userCredentials" fieldName="confirm_password" type="password"
                      [errors]="errors" />
                  </div> -->
									<!-- @if(has_affiliate){
                      <div class="form-group">
                        <app-gen-input [actualObj]="userCredentials" fieldName="affiliate_name"
                          [errors]="errors" />
                        <div class="text-info">
                          {{ "leave_affiliate_name_empty" | langVal }}
                        </div>

                      </div>
                    } -->
									@if (mode == "regi") {
										<div class="form-group">
											<!--<label>{{"subscription_type" | langVal}}</label>-->
											<app-gen-select
												[actualObj]="userCredentials"
												[errors]="errors"
												fieldName="subscription_type"
												[tabindex]="-1"
												optionsListName="subscriptionTypeOpts"
												optionFieldId="value"
											/>
										</div>
									}
									<div>
										<div class="form-group row text-agree-cont">
											<div class="col-1 checkbox-wrapper">
												<input
													[(ngModel)]="userCredentials.agree_to_terms"
													class="agree-to-terms"
													type="checkbox"
													[checked]="userCredentials.agree_to_terms"
												/>
											</div>
											<div class="col-11">
												<label
													class="text-agree"
													titleLang="by_clicking_i_agree"
												>
													<span>{{ "by_clicking_i_agree" | langVal }}</span>
													<span
														class="link"
														(click)="openTerms()"
													>
														{{ "to_terms" | langVal }}
													</span>
												</label>
											</div>
										</div>
										<div>
											@if (errors.agree_to_terms) {
												<p class="error">{{ errors.agree_to_terms }}</p>
											}
										</div>
									</div>
									<app-btn-ext
										btnClass="tall btn-primary subm"
										text="joining"
										(clickEv)="submit()"
										[isSpin]="isSaving"
										iconName="doubleArrow"
									/>

									<button
										titleLang="existing_user_login"
										(click)="chMode('login')"
										class="login-text-button"
									>
										{{ "existing_user_login" | langVal }}
									</button>
								}

								@switch (mode) {
									@case ("login") {
										<div class="form-group">
											<app-gen-input
												[actualObj]="userCredentials"
												fieldName="login_string"
												[errors]="errors"
												title_ph_lang="name_or_email"
											></app-gen-input>
										</div>
										<div class="form-group showPassword">
											<div>
												<app-icon
													icon="show"
													pointer
													(click)="showPassword = !showPassword"
												/>
											</div>
											<app-gen-input
												[actualObj]="userCredentials"
												fieldName="password"
												type="password"
												[showPassword]="showPassword"
												[errors]="errors"
												(keydown)="preSubmit($event)"
											/>
										</div>
										<app-btn-ext
											btnClass="tall btn-primary subm"
											text="login"
											(clickEv)="submit()"
											(keydown)="preSubmit($event)"
											[isSpin]="isSaving"
											iconName="doubleArrow"
										/>
										<div>
											<button
												(click)="chMode('forgot-password')"
												titleLang="forgot_password"
												class="login-text-button"
											>
												{{ "forgot_password" | langVal }}?
											</button>
											<br />
											<button
												titleLang="new_user_register"
												(click)="chMode('regi')"
												class="login-text-button"
											>
												{{ "new_user_register" | langVal }}
											</button>
											<br />
											@if (store.deferredPrompt) {
												<button
													titleLang="install_app"
													class="login-text-button"
													(click)="pwaInstall()"
												>
													{{ "install_app" | langVal }}
												</button>
											}
										</div>
									}
									@case ("forgot-password") {
										<!--<h3 >{{"reset_password" | langVal}}</h3>-->
										<div class="form-group">
											<app-gen-input
												[actualObj]="userCredentials"
												fieldName="email"
												[errors]="errors"
											/>
										</div>

										<app-btn-ext
											btnClass="tall btn-primary subm"
											text="reset_password"
											(clickEv)="submit()"
											[isSpin]="isSaving"
											iconName="doubleArrow"
										/>

										<br />
										<button
											titleLang="remembered"
											(click)="chMode('login')"
											class="login-text-button"
										>
											{{ "remembered" | langVal }}
										</button>
										<br />
										<button
											titleLang="new_user_register"
											(click)="chMode('regi')"
											class="login-text-button"
										>
											{{ "new_user_register" | langVal }}
										</button>
									}
									@case ("greeting") {
										<h3 class="success-msg">
											{{ "thank_you_for_your_registration" | langVal }}
										</h3>
										<h5>
											{{
												"please_active_your_account_from_the_activation_email"
													| langVal
											}}
										</h5>
										<h5>{{ "see_you_after_registration" | langVal }}</h5>
										<h5>{{ "My-Cliniq" }}</h5>
									}
									@case ("regi-otp") {
										<h3 class="success-msg">
											{{ "thank_you_for_your_registration1" | langVal }}
										</h3>
										<h5>{{ "registration_wait" | langVal }}</h5>
									}
								}
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6 col-xl-5">
						<!-- right block -->
						<div class="form-left">
							<!-- Left block -->
							<div class="form-left-top">
								@if (mode == "regi") {
									@if (has_affiliate) {
										@if (has_link) {
											<h2>
												<span class="green h2-smaller-font"
													>{{ "link_text1" | langVal }}
												</span>
											</h2>

											<div>
												<div class="affiliate-tbl">
													<div class="pb-20">
														<span>{{ "link_text2" | langVal }} </span>
													</div>
													<div></div>
													<div>
														<span class="login-affilite-text"
															>{{ "link_text3" | langVal }}
														</span>
													</div>
													<div class="pb-20">
														<span>{{ "link_text4" | langVal }}</span>
													</div>
												</div>
												<hr class="login-affiliate-hr" />
												<p>{{ "link_text5" | langVal }}</p>
											</div>
										} @else {
											<h2>
												<span class="green h2-smaller-font"
													>{{ "affiliate_text1" | langVal }}
												</span>
											</h2>

											<div>
												<div class="affiliate-tbl">
													<div class="pb-20">
														<span>{{ "affiliate_text2" | langVal }} </span>
													</div>
													<div></div>
													<div>
														<span class="login-affilite-text"
															>{{ "affiliate_text3" | langVal }}
														</span>
													</div>
													<div class="pb-20">
														<span>{{ "affiliate_text4" | langVal }}</span>
													</div>

													<!-- <div>
                            <span preIcon="ok">{{"affiliate_text5" | langVal}}</span>
                          </div>
                          <div style="padding-bottom: 20px;">
                            <span preIcon="ok">{{"affiliate_text5a" | langVal}}</span>
                          </div> -->
												</div>
												<hr class="login-affiliate-hr" />
												<p>{{ "affiliate_text6" | langVal }}</p>
											</div>
										}
									} @else {
										<h2 class="register-title">
											<span>{{ "register_text1" | langVal }} </span>
										</h2>
										<!-- <div class="green h2-smaller-font"  >{{"register_month" | langVal}} </div> -->
										<div class="register-subtitle">
											{{ "register_text2" | langVal }}
										</div>
										<div>
											<table class="programs-tbl register-text mb-20">
												<tbody>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text3" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text4" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text5" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text6" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text7" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text7b" | langVal }}</td>
													</tr>
												</tbody>
											</table>
										</div>

										<h2 class="register-title pt-5">
											<span>{{ "register_text8" | langVal }} </span>
										</h2>
										<!-- <div class="green h2-smaller-font"  >{{"register_month" | langVal}} </div> -->
										<div class="register-subtitle">
											{{ "register_text9" | langVal }}
										</div>
										<div>
											<table class="programs-tbl register-text mb-20">
												<tbody>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text10" | langVal }}</td>
													</tr>
													<tr>
														<td icon="ok"></td>
														<td>{{ "register_text11" | langVal }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									}
								} @else {
									<div class="announces">
										<h2>{{ "announces" | langVal }}</h2>
										<h4
											[innerHTML]="announce?.message"
											style="padding-inline-start: 25px"
										></h4>
										<h5>{{ announce?.remarks }}</h5>
									</div>
								}
							</div>

							@if (phrase) {
								<div class="form-left-bottom">
									<p style="font-weight: bold">"{{ phrase.phrase }}"</p>
									<p style="text-align: end">{{ phrase.author }}</p>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
