import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "inArray",
})
export class InArrayPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): boolean {
		return (args[0] as any[]).includes(value)
	}
}
