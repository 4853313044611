import { EventsService } from "@app/services/events.service"
import { StoreService } from "@app/services/store.service"
import {
	Followupserie,
	LoginComponentModes,
	UserCredentials,
} from "./../definitions/types"
import { LangService } from "@app/services/lang.service"
import { Component, HostListener, OnInit } from "@angular/core"
import { ApiService, pluck } from "@app/services/api.service"
import { ActivatedRoute, Router } from "@angular/router"
import { environment } from "@env/environment"
import { ModalService } from "@app/services/modal.service"
import { ValidatorService } from "@app/services/validator.service"
import { PermsService } from "@app/services/perms.service"
// import { DataLayerService } from '@app/services/data-layer.service'; // gets hold of the data layer service we just created

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
	userCredentials: UserCredentials = new UserCredentials()
	announce: any = null
	phrase: any = null
	mes: string = ""
	errors: any = {} //will hold errors for the fields in the form
	isFormSubmittable: boolean = false //can submit (controls disabled in the submit button)
	isSaving: boolean = false //while waiting for the api to return from saving (controls save btn disabled and outputs a "waiting" gif)
	confirmationCounter: number = 0 //counts the number of times the confirmation function was called with no csrf token
	modeToHeading: any = {
		login: "login",
		regi: "register",
		"sub-user-regi": "sub_user_register",
		"potential-user-regi": "register",
	}
	modesWithHeading: string[] = Object.keys(this.modeToHeading)
	showPassword: boolean = false

	affiliate_user_id: string = ""
	has_affiliate: boolean = false
	has_link: boolean = false
	inviting_id: string = ""
	accept_partner_id: string = ""
	subscription_type: string = ""
	download_zip: number = null

	confirmToken: string = ""
	mode: LoginComponentModes = "login"
	environment = environment
	validationFields: any = {
		//field validations, control displaying field errors and ability to submit the form
		login_string: { not_empty: null },
		user_name: {
			not_empty: null,
			length_user_name: 4,
			str_max: 80,
			regex_user_name: null,
		},
		password: {
			not_empty: null,
			length_password: 8,
			str_max: 16,
			regex_password: null,
		},
		confirm_password: { not_empty: null, identicalToPassword: null },
		email: { email: null, str_max: 100, bad_domains: true },
		phone_mobile: { not_empty: null, str_max: 20, phone_format: true },
		agree_to_terms: { agree_to_terms: null },
	}
	modeFields: any = {
		login: ["login_string", "password"], //
		regi: [
			"user_name",
			"email",
			"phone_mobile",
			"password",
			"confirm_password",
			"agree_to_terms",
		],
		"forgot-password": ["email"],
		"reset-password": ["email", "password", "confirm_password"],
		"expired-password": ["password", "confirm_password"],
		"sub-user-regi": [
			"user_name",
			"phone_mobile",
			"password",
			"confirm_password",
			"agree_to_terms",
		],
		"potential-user-regi": [
			"user_name",
			"phone_mobile",
			"password",
			"confirm_password",
			"agree_to_terms",
		],
	}

	constructor(
		public router: Router,
		private apiService: ApiService,
		public lang: LangService,
		protected route: ActivatedRoute,
		public modalService: ModalService,
		public validator: ValidatorService,
		protected store: StoreService,
		public eventsService: EventsService,
		public permsService: PermsService
	) {}
	//private _dataLayerService: DataLayerService,
	reset() {
		this.userCredentials = new UserCredentials()
		this.errors = {}
		this.isFormSubmittable = false
		this.isSaving = false //flag "waiting for the api to finish saving" as false
		this.showPassword = false
		this.subscription_type = "private"
	}
	chMode(newMode: LoginComponentModes) {
		this.mode = newMode
		this.reset()
	}
	validateForm() {
		let fieldsToInclude = this.modeFields[this.mode]
		let valiFields: any = {}
		fieldsToInclude.forEach((fieldName) => {
			valiFields[fieldName] = this.validationFields[fieldName]
		})
		if (this.mode == "login") {
			valiFields.password = { not_empty: null }
		}
		this.isFormSubmittable = this.validator.validateEntireForm(
			this.userCredentials,
			this.errors,
			valiFields
		)
	}
	async sendConfirmation(token: string) {
		//try to send confirmation token to be validated (if csrf is available)
		const res: any = await this.apiService.post("ajax_account_confirmation_1", {
			token,
		})
		if (res.success) {
			//this._dataLayerService.logEvent("'registration'","'regi'","'done'","'before otp'");
			const otpRes = await this.modalService.openMulti("otp", {
				token,
				mode: "regi",
			})
			if (otpRes) {
				this.postSuccessfulLogin(otpRes)
			}
		} else {
			this.modalService.openToast(this.lang.getVal("an_error_occured"))
		}
	}

	async ngOnInit() {
		this.affiliate_user_id = ""

		// this.userCredentials.phone_mobile = "0555555555555555555";//"0545833787";
		// this.userCredentials.user_name = "BLAHHHH " + Math.floor(Math.random() * 1000000);
		// this.userCredentials.email = "fgdestyrythfdgdfh@ghdgsfdgdfg.com";
		// this.userCredentials.password = "123456aA";
		// this.userCredentials.confirm_password = "123456aA";
		// this.userCredentials.agree_to_terms = true;

		this.route.queryParams.subscribe((params) => {
			Object.keys(params).forEach((key) => {
				this[key] = params[key]
				console.log(key)
				if (key == "affiliate_user_id") {
					this.has_affiliate = true
				}
				if (key == "link") {
					this.has_link = true
				}
			})
			//console.log(this.has_link);
			if (this.mes == "token-refresh") {
				this.mes = this.lang.getVal("the_system_was_reset")
				this.modalService.openToast(this.mes)
			}
		})

		this.route.paramMap.subscribe((paramMap) => {
			if (paramMap.get("confirmToken")) {
				this.mode = "regi-otp"

				let token = paramMap.get("confirmToken")
				this.sendConfirmation(token)
			}
			if (paramMap.get("subUserToken")) {
				this.mode = "sub-user-regi"
				// this.userCredentials.phone_mobile = "05555555555555555";//"0545833787";
				// this.userCredentials.user_name = "Nisim " + Math.floor(Math.random() * 1000000);
				// this.userCredentials.password = "123456aA";
				// this.userCredentials.confirm_password = "123456aA";
				// this.userCredentials.agree_to_terms = true;

				this.confirmToken = paramMap.get("subUserToken")
			}
			if (paramMap.get("potentialUserToken")) {
				this.mode = "potential-user-regi"
				// this.userCredentials.phone_mobile = "05555555555555555";//"0545833787";
				// this.userCredentials.user_name = "Nisim " + Math.floor(Math.random() * 1000000);
				// this.userCredentials.password = "123456aA";
				// this.userCredentials.confirm_password = "123456aA";
				// this.userCredentials.agree_to_terms = true;

				this.confirmToken = paramMap.get("potentialUserToken")
			}
			if (paramMap.get("token")) {
				this.userCredentials.token = paramMap.get("token")
			}
			if (paramMap.get("type")) {
				let type = paramMap.get("type")
				;(this.mode as any) = type + "-password"
				if (type == "expired") {
					this.mes = this.lang.getVal("expired_password")
				}
			}
			if (paramMap.get("regi")) {
				;(this.mode as any) = paramMap.get("regi")
			}
			// if(this.mode=="expired-password"){
			// 	this.apiService.activateVerifyConfigCliniqIsIn();
			// }
		})

		const res: any = await this.apiService.get("prep_login_screen")
		this.announce = res.announce
		this.phrase = res.phrase
	}

	preSubmit(ev: KeyboardEvent) {
		if (["Tab", "Enter"].includes(ev.key)) {
			this.submit()
		}
	}
	async submit() {
		if (this.userCredentials.email) {
			this.userCredentials.email = this.userCredentials.email.trim()
		}

		this.validateForm()
		if (!this.isFormSubmittable) {
			return
		}
		if (this.isSaving) {
			return
		} //is curently waiting for api to finish saving - exit

		this.isSaving = true //flag "waiting for the api to finish saving" as true
		this.mes = ""
		let mes: string
		let res: any
		switch (this.mode) {
			case "regi":
				const code = this.route.snapshot.queryParamMap.get("code")

				res = await this.apiService.post("user_regi", {
					...this.userCredentials,
					code,
					...pluck(this, "affiliate_user_id,inviting_id"),
				})
				this.isSaving = false //flag "waiting for the api to finish saving" as false
				if (res.success) {
					this.mes =
						this.lang.getVal("registration_successful") +
						" - " +
						this.lang.getVal(
							"please_active_your_account_from_the_activation_email"
						)
					this.router.navigateByUrl("/regi/greeting/" + res?.user_id, {
						replaceUrl: true,
					})
					this.eventsService.scrollToTop()
				} else {
					if (res.errors) {
						Object.keys(res.errors).forEach((field) => {
							this.errors[field] = this.lang.getVal(res.errors[field])
						})
					}
					this.mes = this.lang.getVal("an_error_occured")
				}
				this.modalService.openToast(this.mes)
				break
			case "sub-user-regi":
				this.userCredentials.token = this.confirmToken
				res = await this.apiService.post("sub_user_regi", this.userCredentials)
				if (res.success) {
					const otpRes = await this.modalService.openMulti("otp", {
						token: this.confirmToken,
						mode: "sub_user_regi",
					})
					if (otpRes) {
						this.postSuccessfulLogin(otpRes)
					}

					// this.mes = this.lang.getVal("registration_successful") + ' - ' + this.lang.getVal("please_active_your_account_from_the_activation_email");
				} else {
					this.isSaving = false //flag "waiting for the api to finish saving" as false
					if (res.errors) {
						Object.keys(res.errors).forEach((field) => {
							this.errors[field] = this.lang.getVal(res.errors[field])
						})
					}
					this.mes = this.lang.getVal("an_error_occured")
					this.modalService.openToast(this.mes)
				}
				break
			case "potential-user-regi":
				this.userCredentials.token = this.confirmToken
				res = await this.apiService.post(
					"potential_user_regi",
					this.userCredentials
				)
				if (res.success) {
					const otpRes = await this.modalService.openMulti("otp", {
						token: this.confirmToken,
						mode: "potential_user_regi",
					})
					if (otpRes) {
						this.postSuccessfulLogin(otpRes)
					}
					// this.mes = this.lang.getVal("registration_successful") + ' - ' + this.lang.getVal("please_active_your_account_from_the_activation_email");
				} else {
					this.isSaving = false //flag "waiting for the api to finish saving" as false
					if (res.errors) {
						Object.keys(res.errors).forEach((field) => {
							this.errors[field] = this.lang.getVal(res.errors[field])
						})
					}
					this.mes = this.lang.getVal("an_error_occured")
					this.modalService.openToast(this.mes)
				}
				break
			case "reset-password":
				res = await this.apiService.post("reset_password", {
					...this.userCredentials,
					password_confirmation: this.userCredentials.confirm_password,
				})
				this.isSaving = false //flag "waiting for the api to finish saving" as false
				mes = res?.message
				if (res.success) {
					mes = this.lang.getVal("your_password_has_been_reset_successfully")
					this.chMode("login")
				}

				this.modalService.openToast(this.lang.getVal(mes))
				break
			case "expired-password":
				res = await this.apiService.post("change_password", {
					password: this.userCredentials.password,
					password_confirmation: this.userCredentials.confirm_password,
				})
				this.isSaving = false //flag "waiting for the api to finish saving" as false
				mes = this.lang.getVal(res?.message)
				if (res.success) {
					mes = this.lang.getVal("password_was_changed")
					this.chMode("login")
				}
				this.modalService.openToast(mes)
				break
			case "forgot-password":
				res = await this.apiService.post(
					"forgot_password",
					this.userCredentials,
					"email"
				)
				this.isSaving = false //flag "waiting for the api to finish saving" as false
				if (res.success) {
					this.modalService.openToast(
						this.lang.getVal("please_follow_the_instructions_we_sent_to")
					)
				} else {
					this.modalService.openToast(this.lang.getVal(res.status))
				}
				break
			case "login":
				res = await this.apiService.post(
					"user_login",
					this.userCredentials,
					"login_string,password"
				)
				if (res.success) {
					this.postSuccessfulLogin(res)
				} else {
					this.isSaving = false //flag "waiting for the api to finish saving" as false

					this.mes = this.lang.getVal(res.status)
					switch (res.status) {
						case "no_auth":
							this.mes = this.lang.getVal("you_are_not_authorized")
							break
						case "not_active":
							this.mes = this.lang.getVal("not_active")
							break
						case "expired_password":
							// this.mes = this.lang.getVal("expired_password");
							this.router.navigateByUrl("/password/expired")
							break
						case "otp_needed":
						case "check_your_2fa_app":
							this.modalService.openToast(this.mes)
							this.mes = ""

							const otpRes = await this.modalService.openMulti("otp", {
								is2faApp: res.status == "check_your_2fa_app",
							})
							if (otpRes) {
								this.postSuccessfulLogin(otpRes)
							}
							break
					}

					if (this.mes) {
						this.modalService.openToast(this.mes)
					}
				}
				break
		}
	}
	async goToNewVersion() {
		await this.store.timeout(3000)
		let href = window.location.href
		href +=
			(href.includes("?") ? "" : "?") +
			"&jwero0tghokfkd=" +
			new Date().getTime()
		;(window.location as any) = href
	}

	async postSuccessfulLogin(res: any) {
		// this._dataLayerService.logEvent("'login'","'success'","'done'","'before everything loads'");
		this.apiService.resetLastActivity()
		await this.store.activateVerifyConfigCliniqIsIn()
		await this.store.verifyFuzzyIsIn()
		this.store.postLoginActions()

		this.apiService.sendApiGetPerms()
		const isOwner = this.store.getCliniqDataOrExit()?.has_users
		const only_therapist_id =
			this.store.getCliniqDataOrExit()?.only_therapist_id
		const login_to_weekly_view =
			this.store.getCliniqDataOrExit()?.login_to_weekly_view == "yes"

		//(window as any).dataLayer.push({"event":"login"});

		if (res?.mirrorUsers?.length) {
			const mirror_user_id = await this.modalService.openMulti(
				"choose-mirror-user",
				{ rows: res.mirrorUsers, username: res.username }
			)
			if (mirror_user_id) {
				res = await this.apiService.post("logout_and_login_as_mirror", {
					mirror_user_id,
				})
				this.apiService.logoutCommon()
				// await this.store.timeout(10*1000);
				await this.postSuccessfulLogin(res)
				await this.store.timeout(10 * 1000)
				window.location.reload()
				return
			}
		}
		if (isOwner) {
			this.router.navigateByUrl(this.apiService.postLogin || "/config-cliniq", {
				replaceUrl: true,
			})
		} else if (
			this.permsService.owner_has_users &&
			this.permsService.perms.administrative &&
			!only_therapist_id &&
			!login_to_weekly_view
		) {
			// אם יש הרשאה אדמיניסטרטיבית
			this.router.navigateByUrl(
				this.apiService.postLogin || "/calendar/day/today",
				{ replaceUrl: true }
			)
		} else if (
			!this.permsService.owner_has_users ||
			(this.permsService.owner_has_users &&
				!this.permsService.perms.sees_admin_homepage)
		) {
			// אם יש הרשאה קלינית
			this.router.navigateByUrl(this.apiService.postLogin || "/", {
				replaceUrl: true,
			})
		} else {
			this.router.navigateByUrl(this.apiService.postLogin || "/calendar", {
				replaceUrl: true,
			})
		}

		if (res?.VERSION != this.store.VERSION) {
			this.goToNewVersion()
		}

		if (this.accept_partner_id) {
			const accept_partner = await this.modalService.openMulti("confirm", {
				actionLang: "confirm_partnership",
				confirmText: "confirm",
				freeText: this.lang.getVal("i_confirm_partnership"),
			})
			if (accept_partner) {
				this.apiService.post(
					"confirm_accept_partner",
					pluck(this, "accept_partner_id")
				)
			}
		}

		if (this.download_zip) {
			this.store.downloadToastFunc(async () => {
				const res: any = await this.apiService.download("download_zip")
				if (!res?.size) {
					return this.store.downloadFailedModal()
				}
				this.apiService.downloadFile(res, `all-documents.zip`)
				this.modalService.openToast(this.lang.getVal("download_documents"))
			})
		}

		this.apiService.postLogin = ""

		if (res?.doesOwnerNeedToPay) {
			const newRenew =
				res.doesOwnerNeedToPay == "new"
					? this.lang.getVal("payment_request_text_new")
					: this.lang.getVal("payment_request_text_renew") // here new or renew text
			const freeText =
				this.lang.getVal("hello") + "," + "</br>" + newRenew + "</br>"
			"<p style='font-size:14px;'>" +
				"* " +
				this.lang.getVal("if_paid_ignore") +
				"</p></br>"
			const goToPurchase = await this.modalService.openMulti("confirm", {
				actionLang: "payment_request",
				confirmText: "to_payment",
				freeText: freeText,
				hasCancel: false,
				hasContact: true,
			})
			if (goToPurchase) {
				this.router.navigateByUrl("/purchases/create")
			}
		}
		if (res?.needsItaToken || res?.needsItaRefreshToken) {
			await this.modalService.openMulti("ita-get-auth", {})
		}
		const { notifications_upon_login } = this.store.getCliniqDataOrExit()
		if (notifications_upon_login === "yes") {
			{
				const { total } = await this.apiService.post(
					"get_task_notifications_total"
				)
				if (total) {
					this.modalService.openMulti("task")
				}
			}
		} else if (res?.openInstructions) {
			/* checkin if this is good for us  */
			await this.modalService.openMulti("open-instructions")
		} else if (res?.openSpecialNotification) {
			/* checkin if this is good for us  */
			await this.modalService.openMulti("open-special-notification")
		}
	}

	openTerms() {
		//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("terms")
	}

	async pwaInstall() {
		this.store.deferredPrompt.prompt()
		const { outcome } = await this.store.deferredPrompt.userChoice
		// Optionally, send analytics event with outcome of user choice
		console.log(`User response to the install prompt: ${outcome}`)
		// We've used the prompt, and can't use it again, throw it away
		this.store.deferredPrompt = null
	}
}
