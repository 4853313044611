import {
	Component,
	ElementRef,
	Input,
	NgZone,
	ViewChild,
	ViewContainerRef,
} from "@angular/core"
import { Router } from "@angular/router"
import { ApiService } from "@app/services/api.service"
import { EventsService } from "@app/services/events.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { OverlapService } from "@app/services/overlap.service"
import { PermsService } from "@app/services/perms.service"
import { SearchSortService } from "@app/services/search-sort.service"
import { StoreService } from "@app/services/store.service"
import { ValidatorService } from "@app/services/validator.service"

@Component({
	selector: "app-gen-modal",
	templateUrl: "./gen-modal.component.html",
})
export class GenModalComponent {
	@ViewChild("modalDialog") modalDialog: ElementRef //the modal dialog, used by the gen-modal for dragging
	isShow: boolean = false //show the content (otherwise hidden)
	isContentVisible: boolean = false //display the content (otherwise none), comes before show so the content has some time to load first
	zIndex: number = 0 //the zIndex of the modal, is managed in steps by the modal service
	readyToClose: boolean = false //determines if to close (depends if the click ended inside the modal or not)
	//the mousedown/click differences are meant to prevent cases like
	//mousedown inside the modal but mouseup outside
	//and vice versa

	constructor(
		public lang: LangService,
		public modalService: ModalService,
		protected apiService: ApiService,
		protected validator: ValidatorService,
		protected searchSortService: SearchSortService,
		public eventsService: EventsService,
		protected zone: NgZone,
		protected router: Router,
		public permsService: PermsService,
		public store: StoreService,
		public overlapService: OverlapService
	) {}

	chShow(val: boolean) {
		//changes isShow
		this.isShow = val
	}
	chCV(val: boolean) {
		//changes isContentVisible (makes sure to run inside angular inspection)
		this.zone.run(() => {
			//force change detection (?)
			this.isContentVisible = val
		})
	}
	innerClick(ev: any) {
		//click occured inside the modal - don't try to close it (and inform the doc)
		ev.stopPropagation() //stop the outer click from happening
		this.eventsService.onDocClick() //broadcast the click
	}
	innerMouseDown(ev: any) {
		//mouse down inside the modal - not considered closing flow
		ev.stopPropagation()
		this.readyToClose = false
	}
	onMouseDown() {
		//mouse down in the wrapper - might begin closing flow
		this.readyToClose = true
	}
	onClick() {
		//click in the wrapper - if the mousedown happened also outside - ready to close
		if (this.readyToClose) {
			//if mousediwn was outside
			this.modalService.closeLatestModal() //make the service close the latest (probably this modal)
		}
		this.readyToClose = false //reset the closing flag
	}

	modalName: string = "gen-modal" //name used to call this modal
	title: string = ""
	modalWrapperClass: string = "modal-full-width"
	componentToUse: any
	data: any = null
	instance: any
	hasExit: boolean = false

	@ViewChild("viewContainerRef", { read: ViewContainerRef })
	vcr!: ViewContainerRef
	@ViewChild("header", { read: ViewContainerRef }) header!: ViewContainerRef

	onClose() {
		this.vcr.clear()
		this.modalWrapperClass = "modal-full-width"
	}
	async preClose(obj: any = null) {
		// this.instance.close=null;
		if (await this.close(obj)) {
			this.instance.close = () => {}
		}
	}

	reset() {
		this.data = null
		this.title = ""
	}
	async onOpen() {
		const componentRef: any = this.vcr.createComponent(this.componentToUse)
		const instance = componentRef?.instance
		this.instance = instance

		if (this.data && Object.keys(this.data).length) {
			for (let key in this.data) {
				instance[key] = this.data[key]
			}
		}
		if (instance?.modalWidth) {
			this.modalWrapperClass = instance.modalWidth
		}
		if (instance?.modalTitleLang) {
			this.title = instance.modalTitleLang
		}

		instance.isModalMode = true
		instance.close = this.preClose.bind(this)
		await this.store.timeout(50)

		if (instance?.modalWidth) {
			this.modalWrapperClass = instance.modalWidth
		}

		this.hasExit = instance?.hasExit
		const header = instance?.header
		if (header) {
			this.header.createEmbeddedView(header)
		}
	}

	isOpened: boolean = false
	self: any = this

	ngOnInit() {
		this.modalService.register(this)
	}

	async close(obj: any = null) {
		return true
		// this.modalService.close(this.modalName, obj);
	}

	headerMouseDown(ev: MouseEvent) {
		this.eventsService.onDocClick()
		let ne = this.modalDialog.nativeElement
		this.eventsService.dragMouseDown(ev, {
			affectedObj: ne,
			xStyleAttrName: "left",
			yStyleAttrName: "top",
		})
	}
}
