@if (isStatic) {
	{{ actualObj[fieldName] }}
	@if (fileType == "image" && actualObj[fieldName]) {
		<img
			class="file-input-img"
			[src]="url"
			[alt]="fieldName | langVal"
		/>
	}
} @else {
	<div>
		<input
			[attr.data-fieldname]="fieldName"
			type="file"
			class="f-hide"
			(change)="onchange($event.target)"
			[accept]="accept"
			#elementEl
		/>
		<label
			(click)="elementEl.click()"
			[title]="title_ph_lang || fieldName | langVal"
			[className]="'form-control flex-center image-upload-button ' + className"
			[spinner]="isUploading"
		>
			{{ (fileType == "image" ? "upload_image" : "choose_file") | langVal }}
		</label>
		<span>{{ actualObj[fieldName] }}</span>
	</div>
	@if (actualObj[fieldName]) {
		<div>
			<span
				class="pointer"
				(click)="empty()"
				[class.file-closer]="fileType == 'image'"
				icon="close"
			></span>
			@if (showImagePreview && fileType == "image") {
				<img
					class="file-input-img"
					[src]="url"
				/>
			}
		</div>
	}
	@if (errors[fieldName]) {
		<p class="error">{{ errors[fieldName] }}</p>
	}
}
