import { EventsService } from "@app/services/events.service"
import {
	Component,
	HostListener,
	Input,
	OnInit,
	SimpleChange,
	ViewChild,
	ElementRef,
	booleanAttribute,
} from "@angular/core"
import {
	BasicInputFormClass,
	ConfigDefinitions,
	DatepickerChangeOpts,
} from "@app/definitions/types"
import { Moment } from "moment"
import * as moment from "moment"
import { LangService } from "@app/services/lang.service"
import { InputsService } from "@app/services/inputs.service"

@Component({
	selector: "app-date-picker",
	templateUrl: "./date-picker.component.html",
})
export class DatePickerComponent extends BasicInputFormClass {
	// @Input() canChooseBeforeToday: boolean = true;	//flag if can choose dates before today
	@Input({ transform: booleanAttribute }) disabledAfterToday: boolean = false //control whether the input is only shown as a button
	@Input({ transform: booleanAttribute }) isButtonView: boolean = false //control whether the input is only shown as a button
	@Input({ transform: booleanAttribute }) isFullYear: boolean = false
	@Input({ transform: booleanAttribute }) noInput: boolean = false
	@Input({ transform: booleanAttribute }) startFromDecade: boolean = false

	popupHeight: number = 184 //popup dimensions
	popupWidth: number = 180
	popupProps: any = {} //object to retain the calculated dimensions from the popup
	headerDisp: string = "" //the display on the header (month/year/decade..)
	dispArr: any[] = [] //array of all the cells to be display

	weekDays: string[] = ["su", "mo", "tu", "we", "th", "fr", "sa"] //week days column headers
	displayTypes = {
		DECADE: 0,
		YEAR: 1,
		MONTH: 2,
	} //types of display for the popup
	displayLevel: number = this.displayTypes.MONTH //current display type
	isPopupOpen: boolean = false //isPopup currently visible
	docClickSubscription: any //listens to the document clicks
	@ViewChild("inpEl") inpEl: ElementRef //the input element. used to focus and understand dimension
	@ViewChild("btnEl") btnEl: ElementRef //the input element. used to focus and understand dimension

	displayStr: string = "" //the current display string (format DD-MM-YY)
	currentDate: Moment = moment.utc().startOf("day") //current date
	curDispStart: Moment = moment.utc().startOf("day") //current start of display (start of month/year/decade etc)

	constructor(
		private eventsService: EventsService,
		private inputsService: InputsService,
		public lang: LangService
	) {
		super()
	}

	ngOnInit() {
		this.inputsService.loadVarsToInput(this)
		if (this.startFromDecade) {
			this.displayLevel = this.displayTypes.DECADE
		}
		this.updateCalendarData() //update calendar display data according to current value

		this.docClickSubscription = this.eventsService.docClickSubject.subscribe(
			(val: any) => {
				//when document clicks - if the click source is not this popoup, close it
				if (val.src != this) {
					this.isPopupOpen = false
				}
			}
		)
	}
	ngOnDestroy() {
		//unsubscribe from the document clicks broadcast
		this.docClickSubscription.unsubscribe()
	}
	ngOnChanges(changes: SimpleChange) {
		//on changes to the connected object - call change from object to current date
		if (changes["actualObj"]) {
			if (this.actualObj[this.fieldName]) {
				//if connected object and field - call change (object->date)
				this.callChange("ActualObject", "CurrentDate")
			} else {
				this.displayStr = ""
			}
		}
	}
	onChange() {
		//called after input itself has changed - call change (display->date), close popup
		this.callChange("DisplayStr", "CurrentDate")
		this.isPopupOpen = false
	}

	focus() {
		//focus field. called by parent
		this.inpEl.nativeElement.focus()
	}
	onKeyDown(ev: KeyboardEvent) {
		//on keydown, if tab - closes popup
		if (ev.key == "Tab") {
			this.isPopupOpen = false
			// this.onLeave();
		}
	}

	refreshWithModel() {
		//call change (object->date). called by parent
		this.callChange("ActualObject", "CurrentDate")
	}

	clickTop(ev: any) {
		//clicking on popup or input. block bubbling and inform document click broadcast
		ev.stopImmediatePropagation()
		this.eventsService.onDocClick({ src: this }) //broadcast the click with this popup as the src (so it woulnd't be closed)
	}
	openPopup(ev: MouseEvent) {
		//on clicking input calulcate dimensions for popup, open it, broadcast click
		ev.stopImmediatePropagation() //prevent bubbling
		this.popupProps = this.eventsService.datePopUpProps(
			this.isButtonView ? this.btnEl : this.inpEl,
			this.popupHeight,
			this.popupWidth,
			ev
		) //calculate dimensions for popup

		this.isPopupOpen = true //open popup
		this.eventsService.onDocClick({ src: this }) //broadcast the click with this popup as the src (so it woulnd't be closed)
	}
	updPopupHeight() {
		//calculate new popup height according to expected rows (days in month + weekdays header OR years/decades)
		//header+monthdays?+math.ciel(dispArr/(month?7:4))
		const isMonth = this.displayLevel == this.displayTypes.MONTH //is in month display
		//formula:
		//24 pixels X (next 3 items added together)

		//1 row for header
		//add 1 row ONLY if month display (weekdays header)
		//add: number of cells / number of cells in row (7 days in a week if month display, 4 years in a row in the other displays)

		//add 4 pixels for margin
		this.popupHeight =
			24 *
				(1 +
					(isMonth ? 1 : 0) +
					Math.ceil(this.dispArr.length / (isMonth ? 7 : 4))) +
			4
	}
	move(isForward: boolean) {
		//
		let mult = isForward ? 1 : -1
		switch (this.displayLevel) {
			case this.displayTypes.DECADE: //decade
				this.curDispStart.year(this.curDispStart.year() + mult * 10)
				break
			case this.displayTypes.YEAR: //year
				this.curDispStart.year(this.curDispStart.year() + mult)
				break
			case this.displayTypes.MONTH: //month
				this.curDispStart.month(this.curDispStart.month() + mult)
				break
		}
		this.updateCalendarData()
	}
	headerMidClick() {
		if (this.displayLevel) {
			this.displayLevel--
			switch (this.displayLevel) {
				case this.displayTypes.DECADE: //year to decade
					this.curDispStart.year(
						this.curDispStart.year() - (this.curDispStart.year() % 10)
					)
					break
				case this.displayTypes.YEAR: //month to year
					this.curDispStart.month(0)
					break
			}
			this.updateCalendarData()
		}
	}
	updateCalendarData() {
		this.updHeaderDisp()
		this.prepCalDisp()
		this.updPopupHeight()
	}
	updHeaderDisp() {
		switch (this.displayLevel) {
			case this.displayTypes.DECADE: //decade
				this.headerDisp = `${this.curDispStart.year()} - ${this.curDispStart.year() + 9}`
				break
			case this.displayTypes.YEAR: //year
				this.headerDisp = `${this.curDispStart.year()}`
				break
			case this.displayTypes.MONTH: //month
				this.headerDisp = `${this.curDispStart.month() + 1}/${this.curDispStart.year()}`
				break
		}
	}
	resetDisplayToCurrentDate() {
		this.curDispStart = moment.utc(this.currentDate)
		this.displayLevel = this.displayTypes.MONTH
		this.updateCalendarData()
	}
	prepCalDisp() {
		const today = moment.utc().startOf("day")

		let resArr = []
		let start = this.curDispStart.year()
		switch (this.displayLevel) {
			case this.displayTypes.DECADE: //decade
				for (let i = 0; i < 10; i++) {
					let obj: any = { val: start + i }
					if (start + i == this.currentDate.year()) {
						obj.isAct = true
					}
					if (start + i == today.year()) {
						obj.isToday = true
					}
					resArr.push(obj)
				}
				break
			case this.displayTypes.YEAR: //year
				for (let i = 0; i < 12; i++) {
					let obj: any = { val: i + 1 }
					if (
						start == this.currentDate.year() &&
						this.currentDate.month() == i
					) {
						obj.isAct = true
					}
					if (start == today.year() && today.month() == i) {
						obj.isToday = true
					}
					resArr.push(obj)
				}
				break
			case this.displayTypes.MONTH: //month
				this.curDispStart.date(1)
				//prev month?
				let dayOfWeek = this.curDispStart.day()
				for (let i = 0; i < dayOfWeek; i++) {
					resArr.push({})
				}
				//this month
				let otherD = moment.utc(this.curDispStart)
				otherD.month(otherD.month() + 1)
				otherD.date(0)
				let daysInThisMonth = otherD.date()
				let runningDate = moment.utc(this.curDispStart).startOf("day")
				for (let i = 0; i < daysInThisMonth; i++) {
					runningDate.date(i + 1)
					let obj: any = { val: i + 1 }

					if (runningDate.isSame(this.currentDate, "day")) {
						obj.isAct = true
					}
					if (runningDate.isSame(today, "day")) {
						obj.isToday = true
					}
					if (this.disabledAfterToday && runningDate.isAfter(today, "day")) {
						obj.disabled = true
					}
					// if (!this.canChooseBeforeToday && runningDate.isBefore(today, "day")) {
					// 	obj.disabled = true;
					// }
					resArr.push(obj)
				}
				break
		}
		this.dispArr = resArr
	}
	cellClick(cell: any) {
		if (!cell.val || cell.disabled) {
			return
		}
		switch (this.displayLevel) {
			// case 0:	//century
			case this.displayTypes.DECADE: //decade
				this.curDispStart.year(cell.val)
				break
			case this.displayTypes.YEAR: //year
				this.curDispStart.month(cell.val - 1)
				break
			case this.displayTypes.MONTH: //month
				this.curDispStart.date(cell.val)
				break
		}
		if (this.displayLevel < this.displayTypes.MONTH) {
			this.displayLevel++
		} else {
			this.currentDate = moment.utc(this.curDispStart)
			this.callChange("CurrentDate", "DisplayStr")
			this.callChange("CurrentDate", "ActualObject")
			this.isPopupOpen = false
		}
		this.updateCalendarData()
	}

	callChange(from: DatepickerChangeOpts, to: DatepickerChangeOpts) {
		if (from == "ActualObject" && to == "CurrentDate") {
			//assumed format is Y-m-d
			if (this.actualObj) {
				this.currentDate = moment
					.utc(this.actualObj[this.fieldName])
					.startOf("day")
				this.resetDisplayToCurrentDate()
				this.callChange("CurrentDate", "DisplayStr")
			}
		} else if (from == "DisplayStr" && to == "CurrentDate") {
			//assumes format is d-m-Y
			if (!this.displayStr.length) {
				if (this.actualObj) {
					this.actualObj[this.fieldName] = ""
					this.changeEvent.emit(this.actualObj[this.fieldName])
				}
				return
			}
			let [date, month, year] = this.displayStr
				.split(".")
				.map((it) => Number(it))
			if (year && year < 100) {
				year += 2000
			}
			const isLegit = date && month && year
			//add missing year
			const newDate = moment.utc(`${year}-${month}-${date}`)
			if (isLegit && newDate.isValid()) {
				this.currentDate = newDate.startOf("day")
				this.resetDisplayToCurrentDate()
				this.callChange("CurrentDate", "ActualObject")
			}
			this.callChange("CurrentDate", "DisplayStr")
		} else if (from == "CurrentDate") {
			if (to == "ActualObject") {
				if (this.actualObj) {
					this.actualObj[this.fieldName] = this.currentDate.format(
						ConfigDefinitions.momentDateFormat
					)
					this.changeEvent.emit(this.actualObj[this.fieldName])
				}
			} else if (to == "DisplayStr") {
				const format = this.isFullYear ? "D.M.YYYY" : "D.M.YY"
				this.displayStr = moment.utc(this.currentDate).format(format)
			}
		}
	}
}

//-possible changes: #1 actObject[fieldName] changed. #2 date cell was clicked. #3 blur happened (docClick or tab)
//-has currentDate=moment.utc(actObject[fieldName] or today)
//-has displayStr
//-MIGHT have actObject[fieldName]
//-if #1 happened - actObject[fieldName] modifies currentDate - currentDate modifies displayStr
//-if #2 happened - modifies currentDate - currentDate modifies actObject[fieldName] - currentDate modifies displayStr
//-if #3 happened - displayStr modifies currentDate IF POSSIBLE - currentDate modifies actObject[fieldName]
//-if actObject[fieldName] changed - changeEvent
//-when to update displays and this.curDispStart? changes to actObject
//-go to all date-pickers and see if they work (specifically changeEvent)
//go to all flips and see if still needed
//validate after debounceTime in add meeting? (like time does)

//add meeting save doesn't dave correct date!!
