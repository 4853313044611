import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { ValidatorService } from "@app/services/validator.service"
import { ModalService } from "@app/services/modal.service"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { EventsService } from "@app/services/events.service"
import { ConfigDefinitions, daysOfWeek } from "@app/definitions/types"

@Component({
	selector: "app-guest-add-meeting-form",
	templateUrl: "./guest-add-meeting-form.component.html",
})
export class GuestAddMeetingFormComponent implements OnInit {
	errors: any = {} //will hold errors for the fields in the form
	isFormSubmittable: boolean = false //can submit (controls disabled in the submit button)
	validationFields: any = {
		//field validations, control displaying field errors and ability to submit the form
		name: { not_empty: null },
		email: { email_or_empty: null },
		phone: { not_empty: null, regex: /^([0-9]+)$/ },
		agree_to_terms: { agree_to_terms: null },
	}
	isSaving: boolean = false
	@Input() saveObj: any = {}
	@Input() dataObj: any = {}
	@Output() saveOut = new EventEmitter()
	@Output() backEv = new EventEmitter()
	daysOfWeek = daysOfWeek
	actualObj: any
	isSummary: boolean = false
	isMobile: boolean = true
	showBack: boolean = true
	links: string[] = []

	constructor(
		public validator: ValidatorService,
		public modalService: ModalService,
		private apiService: ApiService,
		private lang: LangService,
		private eventsService: EventsService
	) {}

	ngOnInit() {
		this.isMobile = this.eventsService.isMobile
		this.actualObj = {
			name: "",
			phone: "",
			email: "",
			remarks: "",
			isNew: "yes",
			agree_to_terms: false,
		}
		// this.actualObj={name:"dsfdsfdsf",phone:"123213",email:"asdsad@fdds.com",remarks:"assadsa",isNew:"yes",agree_to_terms:true};
		this.errors = {}
		this.isFormSubmittable = false
		this.isSaving = false
		this.isSummary = false

		if (!this.isMobile) {
			if (!this.dataObj?.meeting_request_with_calendar) {
				this.showBack = false
			}
		}
	}

	validateEntireForm() {
		this.isFormSubmittable = this.validator.validateEntireForm(
			this.actualObj,
			this.errors,
			this.validationFields
		)

		if (this.errors?.name) {
			this.errors.name = this.lang.getVal("name_should_be_inserted")
		}
		if (this.errors?.phone) {
			this.errors.phone = this.lang.getVal("phone_should_be_inserted")
		}
	}
	openTerms() {
		//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("terms")
	}
	async save() {
		this.isSaving = true
		const res = await this.apiService.post("save_meeting_request", {
			...this.saveObj,
			...this.actualObj,
		})
		if (res?.links?.length) {
			this.links = res.links
		}
		this.isSaving = false
		this.modalService.openToast(
			this.lang.getVal("meeting_request_was_sent") + this.dataObj.name
		)
		this.scrollToTop()
		this.isSummary = true
		this.saveOut.emit(true)

		if (!this.isMobile && this.dataObj?.meeting_request_with_calendar) {
			this.showBack = false
		}
	}
	scrollToTop() {
		window.scrollTo(0, 0)
	}
}
