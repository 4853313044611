import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ApiService } from "@app/services/api.service"
import { EventsService } from "@app/services/events.service"
import { StoreService } from "@app/services/store.service"
import { debounceTime, distinctUntilChanged, Subject } from "rxjs"

@Component({
	selector: "app-menu-contactway-search",
	template: `
		<div>
			<div style="margin-bottom: 0">
				<app-gen-input
					[actualObj]="this"
					fieldName="currentSearch"
					(keyupEvent)="onKeyUp()"
					(click)="onClick($event)"
					title_ph_lang="contactway_search"
					[addonObject]="{ after: { icon: 'mobile' } }"
				>
				</app-gen-input>
			</div>

			@if (doShow) {
				<div class="items-container">
					<app-spin-or-content [isSpin]="isLoading">
						@for (patient of patients; track patient) {
							<div
								class="patient-row"
								(click)="openPatient(patient.id)"
							>
								{{ patient.str }}
							</div>
						} @empty {
							@if (hasFirstSearched) {
								<div>{{ "not_found" | langVal }}</div>
							}
						}
					</app-spin-or-content>
				</div>
			}
		</div>
	`,
})
export class MenuContactwaySearchComponent implements OnInit {
	patients: any[] = []
	currentSearch: string = ""
	isLoading: boolean = false
	hasFirstSearched: boolean = false
	subj: any = null
	searchSubj = new Subject()
	requestSub: any
	doShow: boolean = false

	constructor(
		protected apiService: ApiService,
		protected store: StoreService,
		private router: Router,
		private eventsService: EventsService
	) {}

	ngOnInit() {
		this.subj = this.eventsService.docClickSubject.subscribe(() => {
			this.doShow = false
		})
		this.searchSubj
			.pipe(
				debounceTime(500), //wait 800ms
				distinctUntilChanged() //only if value changes
			)
			.subscribe((res: string) => {
				this.search()
			})
	}
	ngOnDestroy() {
		if (this.searchSubj) {
			this.searchSubj.unsubscribe()
		}
		if (this.subj) {
			this.subj.unsubscribe()
		}
	}

	onKeyUp() {
		this.doShow = false
		this.searchSubj.next(this.currentSearch)
	}

	async search() {
		const currentSearch = this.currentSearch.trim()
		this.patients = []

		if (this.requestSub) {
			this.requestSub.unsubscribe()
		}
		if (currentSearch.length < 5) {
			return
		}
		if (!currentSearch) {
			return
		}
		this.doShow = true
		this.isLoading = true

		this.requestSub = this.apiService.postSubscribe(
			"get_contactway_patients_start",
			{ value: currentSearch },
			this.onGetResult.bind(this)
		)
	}
	onGetResult(res: any) {
		const show_patient_id =
			this.store.getCliniqDataOrExit()?.show_patient_id == "yes"
		this.hasFirstSearched = true
		this.isLoading = false
		for (let obj of res) {
			const patient = this.store.searchSiteDataItem(obj.patient_id, [
				"patients",
			])
			if (patient) {
				const str =
					patient.name +
					" " +
					(show_patient_id ? "(" + patient.id + ")" : "") +
					" (" +
					obj.phone +
					")"
				this.patients.push({ id: patient.id, str })
			}
		}
	}
	openPatient(id: any) {
		if (!id) {
			return
		}
		this.router.navigateByUrl("/patients/" + id)
		this.patients = []
		this.currentSearch = ""
	}
	onClick(ev: MouseEvent) {
		ev.stopImmediatePropagation()
		this.doShow = true
	}
}
