import { Component, Inject, NgZone, OnInit } from "@angular/core"
import { AutoCompleteKeyUpObj } from "@app/definitions/types"
import { StoreService } from "@app/services/store.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { ApiService } from "@app/services/api.service"
import { ValidatorService } from "@app/services/validator.service"

@Component({
	selector: "app-patient-unify",
	templateUrl: "./patient-unify.component.html",
})
export class PatientUnifyComponent implements OnInit {
	target: number = 0
	source: number = 0
	errors: any = {} //will hold errors for the fields in the form
	constructor(
		public lang: LangService,
		protected apiService: ApiService,
		protected validator: ValidatorService,
		protected store: StoreService
	) {}

	isFormSubmittable: boolean = false //can submit (controls disabled in the submit button)
	validationFields: any = {
		//field validations, control displaying field errors and ability to submit the form
		target: { is_legit_fuzzy_value: true },
		source: { is_legit_fuzzy_value: true },
	}
	isSaving: boolean = false

	ngOnInit() {
		this.target = 0
		this.source = 0
		this.errors = {}
	}

	keyUpPatient(obj: AutoCompleteKeyUpObj, field: string) {
		if (!obj.isIdenticalToPreviousValue) {
			this[field] = 0
			return this.validateEntireForm()
		}
	}

	choosePatient(obj: any, field: string) {
		this[field] = obj?.id || 0
		this.validateEntireForm()
	}

	validateEntireForm() {
		this.isFormSubmittable = this.validator.validateEntireForm(
			this,
			this.errors,
			this.validationFields
		)
		if (this.isFormSubmittable) {
			if (this.source == this.target) {
				this.errors.source = this.lang.getVal("same_patient")
			} else {
				;["target", "source"].forEach((fieldName) => {
					const p = this.store.searchSiteDataItem(this[fieldName], ["patients"])
					if (p?.is_group == "yes") {
						this.errors[fieldName] = this.lang.getVal("this_is_group")
					}
				})
			}
		}
		this.isFormSubmittable = this.validator.formIsValid(this.errors)
	}

	async save() {
		this.isSaving = true
		const res = await this.apiService.post(
			"unify_patients",
			this,
			"source,target"
		)

		if (res?.success) {
			await this.store.verifyFuzzyIsIn()
			await this.store.timeout(500)
			window.location.reload()
		}
		this.isSaving = false
	}
}
