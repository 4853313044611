import { SearchSortService } from "@app/services/search-sort.service"
import { StoreService } from "../services/store.service"
import { LangService } from "@app/services/lang.service"
import {
	Component,
	Input,
	OnInit,
	Output,
	ViewChild,
	EventEmitter,
	ElementRef,
} from "@angular/core"
import { ApiService } from "@app/services/api.service"
import { ModalService } from "@app/services/modal.service"
import {
	ConfigDefinitions,
	FollowupserieSave,
	FusCrudReturnObj,
	patientColors,
} from "@app/definitions/types"
import { FullCalendarComponent } from "@fullcalendar/angular"
import { CalendarOptions } from "@fullcalendar/core"
import * as moment from "moment"
import { EventsService } from "@app/services/events.service"
import { PermsService } from "@app/services/perms.service"
import listPlugin from "@fullcalendar/list"
import timegridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { GenAutocompleteComponent } from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component"

@Component({
	selector: "app-time-allocation",
	templateUrl: "./time-allocation.component.html",
})
export class TimeAllocationComponent implements OnInit {
	hoverObj: any = null
	cliniqData: any
	currentView: "timeGridWeek" | "listWeek" = "timeGridWeek"

	@ViewChild("calendar") calendar: FullCalendarComponent
	// @ViewChild("datePicker") datePicker:DatePickerComponent;
	events: any[] = []

	@ViewChild("subUsersFuzzy") subUsersFuzzy: GenAutocompleteComponent
	currentUserId: number = 0
	subUserIds: number[] = null

	calendarOptions: CalendarOptions = {
		plugins: [listPlugin, interactionPlugin, timegridPlugin],
		// initialView: 'listWeek',
		initialView: "timeGridWeek",
		slotLabelFormat: { hour: "2-digit" },
		slotMinTime: "05:00:00",
		slotMaxTime: "23:00:00",
		allDaySlot: false,
		height: "auto",
		timeZone: "UTC",
		locale: this.lang.langCode,
		eventDisplay: "block",
		direction: this.lang.isRtl ? "rtl" : "ltr",
		events: [
			// { id: '1', title: 'event 1', date: '2020-09-07 03:00:00' },
			// { id: '2', title: 'event 2', date: '2020-09-08 04:00:00' }
		],

		eventContent: this.eventContent.bind(this),
		// eventDragStart: this.eventDragStart.bind(this),
		eventClick: this.handleEventClick.bind(this), // bind is important!
		dateClick: this.handleDateClick.bind(this),
		eventDrop: this.handleEventDrop.bind(this),
		eventResize: this.handleEventResize.bind(this),
		// navLinkDayClick: this.dayClick.bind(this),
		eventDidMount: this.evMount.bind(this),
		// eventWillUnmount:this.evUnMount.bind(this),
		// eventDidMount: this.evMount.bind(this),

		headerToolbar: false,
		dayHeaderContent: this.dayHeaderContent.bind(this),

		editable: true,
		navLinks: false,
		views: {
			week: {
				// name of view
				titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
				// other view-specific options here
			},
		},
	}
	constructor(
		protected apiService: ApiService,
		public modalService: ModalService,
		public lang: LangService,
		public store: StoreService,
		protected searchSortService: SearchSortService,
		protected eventsService: EventsService,
		public permsService: PermsService
	) {}
	eventContent(arg: any) {
		let ev = arg.event
		return { html: ev.title }
	}

	changeView(view: "timeGridWeek" | "listWeek") {
		this.currentView = view
		this.calendar.getApi().changeView(view)
	}
	evMount(ev: any) {
		if (this.currentView == "timeGridWeek") {
			return
		}
		const style = ev.el.style
		style.backgroundColor = ev.backgroundColor
		style.borderColor = ev.borderColor
		style.color = "white"
		ev.el.classList.remove("fc-event")
		// ev.el.style.borderWidth = "0px";

		// ev.el.parentNode.style.marginLeft = "0px";
		// ev.el.parentNode.style.right = "0%";
		// ev.el.parentNode.style.left = "40%";
		// ev.el.parentNode.style.zIndex = "0";
	}

	async ngOnInit() {
		this.cliniqData = this.store.getCliniqDataOrExit()

		this.resetSubUserIds()

		this.eventsService.updateTitle(this.lang.getVal("time_allocation"))
		this.calendarOptions.slotMinTime = this.cliniqData.calendar_start_time
		this.calendarOptions.slotMaxTime = this.cliniqData.calendar_end_time

		const tableName = this.permsService.owner_has_users
			? "time_allocations"
			: "solo_time_allocations"

		const res: any = await this.store.get_gen_items(tableName)
		this.events = res[tableName]
		this.loadEvents()
	}

	async loadEvents() {
		if (!this.calendar?.getApi()) {
			await this.store.timeout(500)
			this.loadEvents()
			return
		}
		const firstDay = moment.utc(this.calendar.getApi().view.currentStart)
		const alpha = "10"
		const sub_users = this.store.getSiteDataTable("sub_users")
		const locations = this.store.getSiteDataTable("locations")

		let events = this.events

		if (this.permsService.owner_has_users) {
			events = events.filter((ev) =>
				this.subUserIds.find((subUserId) => ev.user_id == subUserId)
			)
		}

		if (this.currentUserId) {
			events = events.filter((ev) => ev.user_id == this.currentUserId)
		}
		events = events.map((ev) => {
			const color = patientColors[ev.user_id % patientColors.length]
			const day = moment.utc(firstDay).add(ev.day, "days")
			const start = new Date(
				day.format(ConfigDefinitions.momentDateFormat) + " " + ev.time + "Z"
			)
			const end = new Date(
				day.format(ConfigDefinitions.momentDateFormat) + " " + ev.time_to + "Z"
			)
			const sub_user = sub_users.find((it) => it.id == ev.user_id)
			const location = locations.find((it) => it.id == ev.location_id)
			const time = ev.time.split(":")
			if (time.length > 2) {
				time.pop()
			}
			const time_to = ev.time_to.split(":")
			if (time_to.length > 2) {
				time_to.pop()
			}
			let title = time.join(":") + "-" + time_to.join(":")
			if (this.permsService.owner_has_users) {
				title +=
					"<br>" +
					sub_user?.name +
					": " +
					(location?.name || this.lang.getVal("no_location"))
			}

			return {
				id: ev.id,
				start,
				end,
				title,
				// start: new Date(fusInstance.instance_start_unix * 1000),
				// end: new Date(fusInstance.instance_end_unix * 1000),
				color,
				//  eventColor:patientColors[ev.color],
				borderColor: "#00000000",
				//  backgroundColor:patientColors[ev.color],
				extendedProps: { dbObj: ev },
				// editable: !fusInstance.isInVac
			}
		})
		this.calendar
			.getApi()
			.getEventSources()
			.forEach((ev) => ev.remove())
		this.calendar.getApi().addEventSource(events)

		this.getApiKeys()
	}

	dayHeaderContent(arg: any) {
		if (this.currentView == "listWeek") {
			return { html: `<div><span>${arg.text}</span></div>` }
		}

		const arr = arg.text.split(" ")
		arr.pop()

		// let arr = arg.text.replace("שבת", "ש").replace("יום ", "").split(" ");
		// arr[0] = arr[0].replace("׳", "");
		// let html = `<div>${arr.map(it => `<span>${it}</span>`).join("")}</div>`;
		let html = `<div><span>${arr.join(" ")}</span></div>`

		return { html }
	}

	handleEventResize(arg: any) {
		const dbObj = arg.event?.extendedProps?.dbObj || null
		const insertObj = { ...dbObj }
		insertObj.time_to = moment.utc(arg.event.end).format("HH:mm")
		this.preUpdate(insertObj)
	}

	handleEventClick(arg: any) {
		const dbObj = arg.event?.extendedProps?.dbObj || null
		const insertObj = { ...dbObj }
		this.preUpdate(insertObj)
	}
	async preUpdate(insertObj: any) {
		const retObj: any = await this.modalService.openMulti(
			"time-allocation-form",
			{
				insertObj,
				events: this.events,
				isUpdateMode: true,
			}
		)
		if (retObj?.id) {
			this.events = this.events.filter((it) => it.id != retObj.id)
			this.events.push(retObj)
		} else if (retObj?.deletedId) {
			this.events = this.events.filter((it) => it.id != retObj.deletedId)
		}
		this.loadEvents()
	}

	async handleDateClick(arg: any) {
		if (!this.calendar.getApi()) {
			this.modalService.openToast("1") //this.lang.getVal("an_error_occured_please_reload"));
			return
		}
		const time = moment.utc(arg.date)
		const time_to = moment.utc(time).add(1, "hours")

		//get time and time to
		const insertObj = {
			time: time.format("HH:mm"),
			time_to: time_to.format("HH:mm"),
			day: time.weekday(),
			user_id: this.currentUserId,
		}
		const retObj: any = await this.modalService.openMulti(
			"time-allocation-form",
			{
				insertObj,
				events: this.events,
			}
		)
		if (retObj?.id) {
			this.events.push(retObj)
			this.loadEvents()
		}
	}

	handleEventDrop(arg: any) {
		const dbObj = arg.event?.extendedProps?.dbObj || null
		const insertObj = { ...dbObj }
		insertObj.day = moment.utc(arg.event.start).weekday()
		insertObj.time = moment.utc(arg.event.start).format("HH:mm")
		insertObj.time_to = moment.utc(arg.event.end).format("HH:mm")
		this.preUpdate(insertObj)
	}

	subUserMove(dir: number) {
		let siteDataSubUsers: any = this.store.getSiteDataTable("sub_users")
		siteDataSubUsers = siteDataSubUsers.filter((it) =>
			this.subUserIds.includes(it.id)
		)
		// if (this.subUserIds?.length) {
		// }
		const subUsers = [{ id: 0 }, ...siteDataSubUsers]
		const curIndex = subUsers.findIndex((it) => it.id == this.currentUserId)

		let nextIndex = (subUsers.length + curIndex + dir) % subUsers.length
		this.updSubUser(subUsers[nextIndex]?.id || 0)
	}

	async chooseTags(tagObjs: any[]) {
		this.resetSubUserIds()

		if (tagObjs?.length) {
			this.subUserIds = []
			const tagIds = tagObjs.map((it) => Number(it.id))
			//get user ids to tags
			const userIdsToTagArray = {}
			const o2t = this.store
				.getSiteDataTable("object_to_tags")
				.filter((row) => row.item_type == "user")
			o2t.forEach((o2tRow) => {
				if (!userIdsToTagArray[o2tRow.item_id]) {
					userIdsToTagArray[o2tRow.item_id] = []
				}
				userIdsToTagArray[o2tRow.item_id].push(Number(o2tRow.tag_id))
			})
			//only get total cross of arrays
			Object.keys(userIdsToTagArray).forEach((user_id) => {
				const userTagArray = userIdsToTagArray[user_id]
				if (tagIds.every((tag_id) => userTagArray.includes(tag_id))) {
					this.subUserIds.push(Number(user_id))
				}
			})
		}
		this.subUsersClick()
		await this.store.timeout(200)
		this.subUsersFuzzy.focus()
		this.subUsersFuzzy.clickInput()
	}
	updSubUser(id: number) {
		this.currentUserId = id
		this.loadEvents()
		this.subUsersFuzzy.searchStr =
			this.store.getSiteDataTable("sub_users").find((it) => it.id == id)
				?.name || ""
	}

	subUsersClick(obj: any = null) {
		this.updSubUser(obj?.id || 0)
	}

	resetSubUserIds() {
		this.subUserIds = this.store
			.getSiteDataTable("sub_users")
			.map((it) => it.id)
	}

	async getApiKeys() {
		// if(this.permsService.owner_has_users){return}

		if (!this.events.length && !this.permsService.owner_has_users) {
			return
		}

		const cc = this.store.getCliniqDataOrExit()
		if (cc.meeting_request_with_calendar == "yes") {
			const meetingtypes = this.store.getSiteDataTable("meetingtypes")
			if (
				!meetingtypes.find((mt) => mt?.first_time_meeting_request == "yes") ||
				!meetingtypes.find((mt) => mt?.return_meeting_request == "yes")
			) {
				return
			}
		}
	}

	async openMeetingtypes() {
		await this.modalService.openMulti("gen-table-display", {
			tableName: "meetingtypes",
			overrideUpperFields: [
				"name",
				"price",
				"length",
				"first_time_meeting_request",
				"return_meeting_request",
			],
		})
		this.getApiKeys()
	}

	async openTimeAllocationSolo() {
		const tableName = "solo_time_allocations"
		await this.modalService.openMulti("gen-table-display", {
			tableName,
			modalWidth: "modal-default-width",
		})

		const res: any = await this.store.get_gen_items(tableName)
		this.events = res[tableName]
		this.loadEvents()
	}
}
