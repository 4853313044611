<div style="padding: 20px">
	<h4 class="gen-title">{{ "user_configuration" | langVal }}</h4>

	@for (rowFields of rows; track rowFields) {
		<div class="row">
			@for (field of rowFields; track field) {
				<div
					[className]="
						'col-' +
						(field?.xs || clsDefaults.xs) +
						' col-lg-' +
						(field?.lg || clsDefaults.lg)
					"
				>
					<label>{{ field?.lang || field.name | langVal }}</label>
					<app-gen-input
						[tabindex]="field?.tabIndex"
						[className]="field.name == 'email' ? ' email' : ''"
						[actualObj]="sub_user_configcliniq"
						[errors]="errors"
						[fieldName]="field.name"
						[title_ph_lang]="field?.lang || field.name"
						(changeEvent)="validateAndSave()"
					></app-gen-input>
				</div>
			}
		</div>
	}

	<div class="row">
		<div class="col-4 col-lg-2">
			<label>{{ "account_type" | langVal }}</label>
			<app-gen-select
				[actualObj]="sub_user_configcliniq"
				[errors]="errors"
				fieldName="tax_account_type"
				(changeEvent)="onAccountTypeChange(); validateAndSave()"
				optionsListName="taxAccountOpts"
				optionFieldId="value"
			/>
		</div>
		<div class="col-4 col-lg-2">
			<ng-container
				*ngTemplateOutlet="inp; context: { fieldName: 'vat_number' }"
			/>
		</div>
		<div class="col-4 col-lg-2">
			<ng-container
				*ngTemplateOutlet="inp; context: { fieldName: 'tax_number' }"
			/>
		</div>
		<div class="col-4 col-lg-1">
			<ng-container
				*ngTemplateOutlet="btnGroupYesNo; context: { fieldName: 'withoutvat' }"
			/>
		</div>
	</div>
	<div class="row">
		<div class="col-6 col-lg-2">
			<ng-container
				*ngTemplateOutlet="
					inp;
					context: { fieldName: 'time_to_logout', tabindex: 13 }
				"
			/>
		</div>
		<div class="col-6 col-md-2">
			<label>{{ "default_signature" | langVal }}</label>
			<app-gen-input
				[tabindex]="13"
				type="textarea"
				[actualObj]="sub_user_configcliniq"
				[errors]="errors"
				fieldName="default_signature"
				(keyupEvent)="validateAndSave()"
			>
			</app-gen-input>
		</div>
	</div>
	<div class="row">
		<div class="col-6 col-lg-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'is_google2fa' }
				"
			/>
		</div>

		<div class="col-6 col-lg-2">
			<button
				class="btn btn-default"
				style="margin-top: 20px"
				titleLang="get_2fa_key"
				(click)="store.openGoogle2faModal()"
				preIcon="changePassword"
			>
				{{ "get_2fa_key" | langVal }}
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-2 col-lg-2">
			<label>{{ "language" | langVal }}</label>

			<app-gen-select
				[actualObj]="sub_user_configcliniq"
				[errors]="errors"
				fieldName="lang"
				(changeEvent)="validateAndSave()"
				optionsListName="langOpts"
				optionFieldId="value"
			>
			</app-gen-select>
		</div>
		<div class="col-2 col-lg-2">
			<label>{{ "default_font_size" | langVal }}</label>

			<app-gen-select
				[actualObj]="sub_user_configcliniq"
				[errors]="errors"
				fieldName="default_font_size"
				(changeEvent)="validateAndSave()"
				[options]="fontSizeOpts"
				optionFieldName="value"
			>
			</app-gen-select>
		</div>
	</div>
</div>

<ng-template
	#inp
	let-fieldName="fieldName"
	let-tabindex="tabindex"
>
	<label>{{ fieldName | langVal }}</label>
	<app-gen-input
		[tabindex]="tabindex || -1"
		[actualObj]="sub_user_configcliniq"
		[errors]="errors"
		[fieldName]="fieldName"
		(keyupEvent)="validateAndSave()"
	/>
</ng-template>
<ng-template
	#btnGroupYesNo
	let-fieldName="fieldName"
>
	<label>{{ fieldName | langVal }}</label>
	<app-gen-button-group
		[actualObj]="sub_user_configcliniq"
		[fieldName]="fieldName"
		optionsListName="yes_no_opts"
		(changeEvent)="validateAndSave()"
	/>
</ng-template>
