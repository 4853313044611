import { Component, Input } from "@angular/core"
import {
	insertPatientFields,
	reportNames,
	reportNamesSolo,
	ValueName,
} from "@app/definitions/types"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { PermsService } from "@app/services/perms.service"
import { StoreService } from "@app/services/store.service"
import { Subject, debounceTime } from "rxjs"

@Component({
	selector: "app-cc-checkboxes",
	template: `
		<app-checkboxes-gen
			[fields]="fields"
			[obj]="saveObj"
			[title]="title"
			(onChange)="onChange($event)"
		/>
	`,
})
export class CcCheckboxesComponent {
	@Input() fieldsArr: string[] = []
	@Input() title: string = ""
	@Input() ccFieldName: string = ""

	saveObj: any = {}
	isSaving: boolean = false
	saveSubj = new Subject()
	fields: ValueName[] = []

	constructor(
		protected store: StoreService,
		protected apiService: ApiService,
		public lang: LangService,
		public modalService: ModalService
	) {}

	ngOnInit() {
		this.fields = this.fieldsArr.map((name: string) => ({ value: name, name }))
		this.saveObj = this.store.getCliniqDataOrExit()[this.ccFieldName]
		if (!this.saveObj) {
			this.saveObj = this.fieldsArr.reduce(
				(acc, item) => ({ ...acc, [item]: true }),
				{}
			)
		}
		this.saveSubj.pipe(debounceTime(800)).subscribe(() => {
			this.save()
		})
	}
	onChange(newSaveObj: any) {
		this.saveObj = newSaveObj
		this.saveSubj.next(1)
	}

	async save() {
		const cc = this.store.getCliniqDataOrExit()
		cc[this.ccFieldName] = this.saveObj

		this.isSaving = true
		const result: any = await this.apiService.post("update_configcliniq_data", {
			...cc,
			update: "user",
		})
		this.isSaving = false
		if (result && !result?.error) {
			//success
			this.modalService.openToast(this.lang.getVal("updated_successfully")) //open toast with success message
			sessionStorage.setItem("cliniqData", JSON.stringify(result)) //update the config object in the sessionStorage
			if (this.ccFieldName == "reports") {
				this.store.hiddenMenuItemsSubj.next(true)
			}
		} else {
			//fail
			this.modalService.openToast(this.lang.getVal(result?.error)) //open toast with fail message
		}
	}
}

@Component({
	selector: "app-cc-insert-patient-templates",
	template: `
		<app-cc-checkboxes
			title="select_fields"
			[fieldsArr]="fieldsArr"
			ccFieldName="insert_patient_template"
		/>
	`,
})
export class CcInsertPatientTemplate {
	fieldsArr: string[] = insertPatientFields
}

@Component({
	selector: "app-cc-reports",
	template: `
		@if (fieldsArr) {
			<app-cc-checkboxes
				title="report_selection"
				[fieldsArr]="fieldsArr"
				ccFieldName="reports"
			/>
		}
	`,
})
export class CcReports {
	fieldsArr: string[]
	constructor(protected perms: PermsService) {
		this.fieldsArr = this.perms.owner_has_users ? reportNames : reportNamesSolo
	}
}
