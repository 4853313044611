import {
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChange,
} from "@angular/core"
import { AcCommonComponent } from "@app/gen-inputs/ac-common/ac-common.component"

@Component({
	selector: "app-autocomplete-multi",
	templateUrl: "../ac-common/ac-common.component.html",
})
export class AutocompleteMultiComponent extends AcCommonComponent {
	@Input() ids: number[] = []
	@Input() siteDataFilters: any = null
	@Input() siteDataTable: string = null
	@Input() optionsArray: any[] = null
	@Output() addItem = new EventEmitter()
	@Output() deleteItem = new EventEmitter()

	ngOnInit() {
		this.init()
		this.resetChosenObjs()
	}
	ngOnChanges(changes: SimpleChange) {
		//if person changed (ex: contact) - send to load function
		if (changes["ids"]) {
			this.resetChosenObjs()
		}
	}

	resetChosenObjs() {
		this.chosenObjs = []
		if (this.ids) {
			const items = this.siteDataTable
				? this.store.getSiteDataTable(this.siteDataTable)
				: this.optionsArray
			this.ids.forEach((id) => {
				const item = items.find((it) => it.id == id)
				if (item) {
					this.chosenObjs = [...this.chosenObjs, { ...item }]
				}
			})
		}
	}

	delete(optObj: any) {
		if (!this.chosenObjs.find((ob) => ob.id == optObj.id)) {
			return
		}
		this.chosenObjs = this.chosenObjs.filter((ob) => ob.id != optObj.id)
		this.outputValue()
		this.deleteItem.emit(optObj.id)
		this.focus()
	}
	keyupOtps(strToLower: string) {
		const items = this.siteDataTable
			? this.store.getSiteDataTable(this.siteDataTable)
			: this.optionsArray
		const opts = [
			...items
				.filter((it) => {
					if (this.siteDataFilters) {
						for (let key of Object.keys(this.siteDataFilters)) {
							const val = this.siteDataFilters[key]
							if (it[key] != val) {
								return false
							}
						}
					}
					return true
				})
				.filter((it) => it.name.toLowerCase().indexOf(strToLower) != -1),

			// .map(obj => this.getOpt(obj, tbl))
		]

		return opts
	}
	optAlreadyInChosen(optObj: any) {
		return this.chosenObjs.find((ob) => ob.id == optObj.id)
	}
	addOption(optObj: any) {
		this.options = []
		this.inputStr = ""
		this.focus()
		if (this.optAlreadyInChosen(optObj)) {
			return
		}
		this.chosenObjs = [...this.chosenObjs, { ...optObj }]
		this.outputValue()
		this.addItem.emit(optObj)
	}
}
