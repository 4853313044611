<div>
	<h4 class="gen-title">{{ name | langVal }}</h4>

	@for (section of sections; track section.tableName) {
		<div
			class="section-row"
			[style.background-color]="section.color"
			(click)="openSection(section)"
		>
			<h4>{{ (section.isOpen ? "" : section.title) | langVal }}</h4>
		</div>
		@if (section.isOpen) {
			@if (section.nonTable) {
				@switch (section.tableName) {
					@case ("cc-insert-patient-templates") {
						<app-cc-insert-patient-templates />
					}
					@case ("patient-details-fields-manage") {
						<app-patient-details-fields-manage />
					}
					@case ("patient_unification") {
						<app-patient-unify />
					}
					@case ("import-patients") {
						<app-import-patients />
					}
					@case ("import-followups") {
						<app-import-followups />
					}
					@case ("import-tags") {
						<app-import-tags />
					}
					@case ("accountant") {
						<app-cc-params-accountant />
					}
					@case ("tests") {
						<app-test-manage />
						<!--            <app-tests-menu />-->
					}
					@case ("public_tests") {
						<app-cc-params-public-tests />
					}
					@case ("holiday") {
						<app-cc-params-holiday />
					}
					@case ("activity") {
						<app-activity />
					}
					@case ("admininvoices") {
						<app-user />
					}
					@case ("payment") {
						<app-payment-form />
					}
					@case ("user-details-website") {
						<app-cc-params-user-details-website />
					}
					<!-- @case("cc-reports"){
            <app-cc-reports />
          } -->
					<!-- @case("hashav"){
            <app-cc-params-hashav />
          } -->
				}
			} @else {
				@if (section?.isPagi) {
					<app-gen-table-pagination [tableName]="section.tableName" />
				} @else {
					<app-gen-table [tableName]="section.tableName" />
				}
			}
		}
		<hr class="separation-line" />
	}
</div>
