import { Injectable } from "@angular/core"
import { LangService } from "./lang.service"

@Injectable({
	providedIn: "root",
})
export class InputsService {
	constructor(private lang: LangService) {}

	loadVarsToInput(inputComp: any) {
		//add field component props according to attached genTableVars
		//genTableVars: { curRow: any, fieldObj: any, isNewRow: boolean,tabindexDomain:number }

		if (!inputComp.genTableVars) {
			return
		} //if no value - exit
		const { curRow, fieldObj, isNewRow, tabindexDomain } =
			inputComp.genTableVars //deconstruct into vars

		const langVal = this.lang.getVal(fieldObj.langName) //get the lang values
		// this.lang.translation[fieldObj.langName] || fieldObj.langName;

		if ("title" in inputComp) {
			//title
			inputComp.title = langVal
		}
		if ("placeholder" in inputComp) {
			//placeholder for input fields
			inputComp.placeholder = langVal
		}
		if ("autofocus" in inputComp) {
			//autofocus when applicable
			inputComp.autofocus = isNewRow ? fieldObj.attrs.autofocus : ""
		}
		if ("dataRowField" in inputComp) {
			//used for focusing sequencing and progression
			inputComp.dataRowField = (curRow.id || 0) + "_" + fieldObj.fieldName
		}
		if ("tabindex" in inputComp) {
			//assign a tabindex
			//update - all fields are same (domain-50)
			//new row - if field was assigned tabindex. otherise -1
			inputComp.tabindex = isNewRow
				? fieldObj.tabindex
					? fieldObj.tabindex
					: -1
				: tabindexDomain - 50
		}
	}
}
