<div class="row">
	<div class="colstat colstat-big">
		<label>{{ "name" | langVal }}</label>
		<app-gen-input
			[tabindex]="1"
			[actualObj]="payor || contact"
			[errors]="errors"
			fieldName="name"
			(keyupEvent)="validateField('name')"
			(changeEvent)="onChange('name')"
		>
		</app-gen-input>
	</div>
</div>
<div class="row">
	<div class="colstat colstat-big">
		<label>{{ "address" | langVal }}</label>
		<app-gen-input
			[tabindex]="-1"
			[actualObj]="payor || contact"
			[errors]="errors"
			fieldName="address"
			(keyupEvent)="validateField('address')"
			(changeEvent)="onChange('address')"
		/>
	</div>
	<div class="colstat colstat-small">
		<label>{{ "bn_number" | langVal }}</label>
		<app-gen-input
			[tabindex]="-1"
			[actualObj]="payor || contact"
			[errors]="errors"
			fieldName="bn_number"
			(keyupEvent)="validateField('bn_number')"
			(changeEvent)="onChange('bn_number')"
		>
		</app-gen-input>
	</div>
</div>
@if (!(payor || contact).id) {
	<hr />
	<div>
		<app-contactway-add
			[contactways]="contactways"
			(removeContactway)="removeContactway($event)"
			(keyupContactway)="validateEntireForm()"
			(changeEventContactway)="validateEntireForm()"
			(fieldChange)="validateEntireForm()"
			(addContactway)="addContactway()"
		/>
	</div>
	<hr />
}

@if (tableName == "contacts") {
	<div class="row">
		<div class="colstat colstat-small">
			<label>{{ "notifiy" | langVal }}</label>
			<app-gen-button-group
				[actualObj]="contact"
				fieldName="notifiy"
				optionsListName="yes_no_opts"
				(changeEvent)="validateField('notifiy'); onChange('notifiy')"
			/>
		</div>
		<div class="colstat colstat-small">
			<label>{{ "notification_default" | langVal }}</label>
			<app-gen-button-group
				[actualObj]="contact"
				fieldName="notification_default"
				optionsListName="notification_defaultOpts"
				(changeEvent)="
					validateField('notification_default');
					onChange('notification_default')
				"
			/>
		</div>
	</div>
}

<div class="row">
	<div class="colstat colstat-small">
		<label>{{ "is_withholding_tax" | langVal }}</label>
		<app-gen-button-group
			[actualObj]="payor || contact"
			fieldName="is_withholding_tax"
			optionsListName="yes_no_opts"
			(changeEvent)="
				validateField('is_withholding_tax'); onChange('is_withholding_tax')
			"
		/>
	</div>
	<div class="colstat colstat-small">
		<label>{{ "withholding_tax_percent" | langVal }}</label>
		<app-gen-input
			[tabindex]="-1"
			[actualObj]="payor || contact"
			[errors]="errors"
			fieldName="withholding_tax_percent"
			(keyupEvent)="validateField('withholding_tax_percent')"
			(changeEvent)="onChange('withholding_tax_percent')"
		/>
	</div>
</div>
@if (budget_for_payors == "yes" && tableName == "payors") {
	<div class="row">
		<div class="colstat colstat-small">
			<label>{{ "budget_sum" | langVal }}</label>
			<app-gen-input
				[tabindex]="-1"
				[actualObj]="payor"
				[errors]="errors"
				fieldName="budget_sum"
				(keyupEvent)="validateField('budget_sum')"
				(changeEvent)="onChange('budget_sum')"
			/>
		</div>
		<div class="colstat colstat-small">
			<label>{{ "budget_date" | langVal }}</label>
			<app-date-picker
				[tabindex]="-1"
				[actualObj]="payor"
				[errors]="errors"
				fieldName="budget_date"
				(changeEvent)="onChange('budget_date')"
				isFullYear
			/>
		</div>

		<div class="colstat colstat-small">
			<label>{{ "budget_percent_for_alert" | langVal }}</label>
			<app-gen-input
				[tabindex]="-1"
				[actualObj]="payor"
				[errors]="errors"
				fieldName="budget_percent_for_alert"
				(keyupEvent)="validateField('budget_percent_for_alert')"
				(changeEvent)="onChange('budget_percent_for_alert')"
			>
			</app-gen-input>
		</div>
		@if (payor.budget_sum) {
			<div
				titleLang="budget_usage"
				class="colstat colstat-small"
			>
				<label>{{ "budget_usage" | langVal }}</label>
				<div
					titleLang="budget_usage"
					class="border-0 disabled-input"
				>
					{{ payor.budget_usage | finNumber }}
				</div>
			</div>
			<div
				titleLang="budget_usage"
				class="colstat colstat-small"
			>
				<label>{{ "budget_usage_percent" | langVal }}</label>
				<div
					titleLang="budget_usage_percent"
					class="border-0 disabled-input"
				>
					{{ payor.budget_usage_percent | finNumber }}
				</div>
			</div>
		}
	</div>
}
<div class="row"></div>

@if ((payor || contact).id) {
	<hr />
	@if (tableName == "payors") {
		<app-gen-table
			filterField="payor_id"
			[filterFieldValue]="payor.id"
			tableName="payorpatients"
		/>
	}

	<div
		style="margin-top: 24px"
		class="bottom-row-buttons"
	>
		<div
			class="action-buttons p-0 d-flex justify-content-sm-end justify-content-between"
		>
			<!--    <button titleLang="invoice_short" tabindex="-1" class="btn btn-primary" (click)="addInvoiceEv.emit()">{{ "invoice_short" | langVal }} </button>-->
			<button
				class="btn btn-primary"
				titleLang="invoice_short"
				(click)="addInvoiceEv.emit()"
				preIcon="money-white"
				isImg
			>
				{{ "invoice_short" | langVal }}
			</button>

			@if (tableName == "payors") {
				<!--      <button titleLang="payorprices_short" tabindex="-1" class="btn btn-primary" (click)="openPayorprices()">{{ "payorprices_short" | langVal }} </button>-->
				<button
					class="btn btn-primary"
					titleLang="payorprices_short"
					(click)="openPayorprices()"
					preIcon="payorprices"
				>
					{{ "payorprices_short" | langVal }}
				</button>

				<!--      <button titleLang="follow_ups_report" tabindex="-1" class="btn btn-primary" (click)="openReport.emit('followups_report')">{{ "follow_ups_report" | langVal }}</button>-->
				<button
					class="btn btn-primary"
					titleLang="follow_ups_report"
					(click)="openReport.emit('followups_report')"
					preIcon="report"
				>
					{{ "follow_ups_report" | langVal }}
				</button>
			}
		</div>
	</div>
} @else {
	<div class="modal-btns">
		@if (isModalMode) {
			<app-cancel-btn (click)="closeModal.emit()" />
		}
		<app-btn-ext
			text="add"
			(clickEv)="save()"
			[disabled]="!isFormSubmittable"
			[isSpin]="isSaving"
		/>
	</div>
}
