import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { GuestAddMeetingFormComponent } from "@app/guest-add-meeting-form/guest-add-meeting-form.component"
import { GuestHoursSchedComponent } from "@app/guest-hours-sched/guest-hours-sched.component"
import { ApiService } from "@app/services/api.service"
import { EventsService } from "@app/services/events.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { environment } from "@env/environment"

@Component({
	selector: "app-guest-user-details",
	templateUrl: "./guest-user-details.component.html",
})
export class GuestUserDetailsComponent {
	environment = environment
	@Input() fdsewrfsdfds: string = ""
	@Input() hgfe5ty6hjtgft454tr: string = ""
	@Input() dataObj: any = null
	@Input() saveObj: any = null
	@ViewChild("hoursComp") hoursComp: GuestHoursSchedComponent
	@ViewChild("formComp") formComp: GuestAddMeetingFormComponent

	postSubmit: boolean = false
	isSaving: boolean = false
	dynamicArea: "lines" | "hours" | "form" = "lines"

	constructor(
		protected route: ActivatedRoute,
		private apiService: ApiService,
		public lang: LangService,
		private modalService: ModalService,
		public router: Router,
		public eventsService: EventsService
	) {}

	setMeetingClick() {
		this.dynamicArea = this.dataObj?.meeting_request_with_calendar
			? "hours"
			: "form"
	}
	goToForm() {
		this.dynamicArea = "form"
	}

	backFromHours(obj: any) {
		this.saveObj = { ...this.saveObj, ...obj }
		this.dynamicArea = "form"
	} //date,meetingtype_id:this.meetingtype.id,time

	backFromForm(isSummary: boolean) {
		const nextArea =
			this.dataObj?.meeting_request_with_calendar && !isSummary
				? "hours"
				: "lines"
		this.dynamicArea = nextArea

		if (isSummary) {
			this.formComp.ngOnInit()
			this.hoursComp.ngOnInit()
		}
	}
	scrollToTop() {
		window.scrollTo(0, 0)
	}
}
