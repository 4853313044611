import { Component, Input, SimpleChange } from "@angular/core"
import { Modalable } from "@app/Modalable"

@Component({
	selector: "app-activity-log-table",
	template: `<app-table-wrap
		fieldsStr="date,user,id,owner,total_hours_logged,average_time_logged,count_logs"
	>
		@for (row of displayRows; track row) {
			@if (row?.isSubTotal || !isClosed || row.date == openedDate) {
				<tr
					[class.highlight-row]="row?.highlight_row"
					[class.highlight-row2]="row?.highlight_row2"
					[class.subtotal]="row?.isSubTotal"
					(click)="clickRow(row)"
					[class.pointer]="row?.isSubTotal"
				>
					@for (
						field of [
							"date",
							"name",
							"user_id",
							"owner_name",
							"total_hours_logged",
							"average_time_logged",
							"count_logs",
						];
						track field
					) {
						@if (field == "name" && !row?.isSubTotal && !user_id) {
							<td
								class="pointer"
								(click)="openUser(row.user_id)"
							>
								{{ row[field] }}
							</td>
						} @else {
							<td>{{ row[field] }}</td>
						}
					}
				</tr>
			}
		}
	</app-table-wrap>`,
})
export class ActivityLogTableComponent extends Modalable {
	modalTitleLang: string = "activity"

	rows: any[] = []
	displayRows: any[] = []
	@Input() period: string = "daily"
	@Input() user_id: number = 0
	@Input() isClosed: boolean = true
	openedDate: string = ""

	ngOnInit() {
		this.getRows()
	}
	ngOnChanges(changes: SimpleChange) {
		//follow changes to input values. if any changes - run initRun
		if (changes["period"]) {
			this.isClosed = true
			this.getRows()
		}
	}

	async openUser(user_id: number) {
		this.modalService.openMulti("activity-log-table", {
			isClosed: false,
			user_id,
		})
	}

	async getRows() {
		if (!this.period) {
			return
		}
		this.rows = await this.apiService.post(
			"get_activity",
			this,
			"period,user_id"
		)
		let subTotalRow: any = null
		const subTotalRows = []
		this.rows.forEach((row) => {
			row.total_hours_logged = Math.round(row.total_hours_logged)
			row.average_time_logged = Math.round(row.average_time_logged)
		})
		if (this.user_id) {
			this.displayRows = this.rows
		} else {
			this.rows.forEach((row, ind) => {
				const date = row.date
				if (!ind) {
					subTotalRow = this.getNewSubtotalRow(row.date)
				}
				if (ind && subTotalRow?.date !== date) {
					//new subtotal row
					subTotalRows.push({ ...subTotalRow })
					subTotalRow = this.getNewSubtotalRow(row.date)
				}
				subTotalRow.rows.push({ ...row })

				subTotalRow.name++
				;["total_hours_logged", "average_time_logged", "count_logs"].forEach(
					(field) => {
						subTotalRow[field] += Number(row[field])
					}
				)
			})
			if (this.rows.length) {
				subTotalRows.push({ ...subTotalRow })
			}
			this.displayRows = subTotalRows.reduce(
				(acc, row) => [...acc, row, ...row.rows],
				[]
			)
		}

		this.displayRows.forEach((row) => {
			row.total_hours_logged = this.store.minutesToHI(row.total_hours_logged)
			row.average_time_logged = this.store.minutesToHI(row.average_time_logged)
		})
	}
	clickRow(row: any) {
		if (!row?.isSubTotal) {
			return
		}
		this.openedDate = row.date == this.openedDate ? null : row.date
	}
	getNewSubtotalRow(date: string) {
		return {
			date,
			name: 0,
			total_hours_logged: 0,
			average_time_logged: 0,
			count_logs: 0,
			isSubTotal: true,
			rows: [],
		}
	}
}
