import {
	Component,
	ElementRef,
	HostListener,
	Input,
	NgZone,
	OnInit,
	SimpleChange,
	ViewChild,
	EventEmitter,
	Output,
	booleanAttribute,
} from "@angular/core"
import { OptionsList } from "@app/definitions/opts"
import {
	documentTemaplateBtns,
	ExtendedInputFormClass,
	Store,
} from "@app/definitions/types"
import { EventsService } from "@app/services/events.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { StoreService } from "@app/services/store.service"
import { Subject } from "rxjs"
import { debounceTime, distinctUntilChanged } from "rxjs/operators"
import { AutotextMenuComponent } from "@app/autotext-menu/autotext-menu.component"
import { InputsService } from "@app/services/inputs.service"

const minHeight = 75

@Component({
	selector: "app-wysiwyg",
	templateUrl: "./wysiwyg.component.html",
})
export class WysiwygComponent extends ExtendedInputFormClass {
	previousValue: string = "" //"a1234567<div>b1234567</div><div>c1234567</div><div>d1234567</div>";
	props: any = {
		bgColor: "white",
		color: "var(--green)",
		size: 14,
		name: "Heebo",
	}
	curProps: any = {}
	colorDisplay: boolean = false
	alignDisplay: boolean = false
	documentTemplatesDisplay: boolean = false
	curColorField: string = ""
	curHtml: string = ""
	OptionsList = OptionsList
	height: number = 75
	@Input() initHeight: number = null
	@Input() maxHeight: number = 400
	@Input() allowsImageUpload: boolean = false
	@Input() isDocumentTemplate: boolean = false
	@Input({ transform: booleanAttribute }) showTestsBtn: boolean = false
	@Input({ transform: booleanAttribute }) showAutotextsBtn: boolean = false
	@Output() openTests = new EventEmitter()
	@Output() openAutotexts = new EventEmitter()
	@Input({ transform: booleanAttribute }) isMedicalRecord: boolean = false
	@Input() exHtmlStyle: string = ""
	@Output() uploadFile = new EventEmitter()
	heightRange = [100, 400]
	curSelRng: any = null
	changeSubject = new Subject()
	defaultFontSize: number = null
	@ViewChild("canvas") canvas: ElementRef
	num10: number[] = new Array(10)
	curView: any = [-1, -1]
	tblDisplay: boolean = false
	isMaximized: boolean = false
	justifyBtnsOpened: boolean = false

	hoverOverTbl: any = null
	hoverTblOpens: any = {}
	curTd: any = null
	fontNameOpts: any[] = []

	documentTemaplateBtns = documentTemaplateBtns
	patientfields: string[] = []

	constructor(
		protected zone: NgZone,
		protected eventsService: EventsService,
		public lang: LangService,
		protected store: StoreService,
		private inputsService: InputsService,
		private modalService: ModalService
	) {
		super()
	}

	fontSizes: number[] = [10, 12, 14, 16, 18, 20, 24, 32]

	paletteColors: string[][] = [
		[
			"295218",
			"311873",
			"424242",
			"630000",
			"636363",
			"731842",
			"846300",
			"000000",
		],
		[
			"9C9C94",
			"CEC6CE",
			"EFEFEF",
			"F7F7F7",
			"FFFFFF",
			"FF0000",
			"FF9C00",
			"FFFF00",
		],
		[
			"00FF00",
			"00FFFF",
			"0000FF",
			"9C00FF",
			"FF00FF",
			"F7C6CE",
			"FFE7CE",
			"FFEFC6",
		],
		[
			"D6EFD6",
			"CEDEE7",
			"CEE7F7",
			"D6D6E7",
			"E7D6DE",
			"E79C9C",
			"FFC69C",
			"FFE79C",
		],
		[
			"B5D6A5",
			"A5C6CE",
			"9CC6EF",
			"B5A5D6",
			"D6A5BD",
			"E76363",
			"F7AD6B",
			"FFD663",
		],
		[
			"94BD7B",
			"73A5AD",
			"6BADDE",
			"8C7BC6",
			"C67BA5",
			"CE0000",
			"E79439",
			"EFC631",
		],
		[
			"6BA54A",
			"4A7B8C",
			"3984C6",
			"634AA5",
			"A54A7B",
			"9C0000",
			"B56308",
			"BD9400",
		],
		[
			"397B21",
			"104A5A",
			"085294",
			"7B3900",
			"083139",
			"003163",
			"21104A",
			"4A1031",
		],
	]

	@ViewChild("editor") editor: ElementRef

	ngOnChanges(changes: SimpleChange) {
		if (changes["actualObj"]) {
			if (this.actualObj) {
				this.previousValue = this.store.sanitizeWysiwyg(
					this.actualObj[this.fieldName]
				)
				this.prepPreviousValue()
				if (this.editor) {
					this.editor.nativeElement.innerHTML = this.previousValue
				}

				if (!this.previousValue && this.editor) {
					this.editor.nativeElement.innerHTML = ""
				}
			}
		}
	}

	prepPreviousValue() {
		// if(!this.previousValue){
		// 	this.previousValue
		// }
		// let str=this.previousValue.toString();
		// if(str.length && !str.includes("<div")){
		// }
	}

	getInitHeight() {
		return Math.max(this.initHeight || 0, minHeight)
	}

	// my remarks
	ngOnInit() {
		this.inputsService.loadVarsToInput(this)
		this.patientfields = this.store
			.getPreppedPatientFields()
			.map((it) => it.name)

		this.fontNameOpts = [
			"Heebo",
			...OptionsList.default_font_for_documents_opts.map((it: any) => it.lang),
		]

		let cliniqData = this.store.getCliniqDataOrExit()
		this.defaultFontSize = cliniqData.default_font_size
		this.props.size = this.defaultFontSize

		this.curProps = Object.assign({}, this.props)
		if (this.actualObj) {
			this.previousValue = this.store.sanitizeWysiwyg(
				this.actualObj[this.fieldName]
			)
		}
		this.heightRange[1] = this.maxHeight

		this.height = this.getInitHeight()
		this.changeSubject
			.pipe(debounceTime(500), distinctUntilChanged())
			.subscribe((html) => {
				// this.updateHeight();

				this.changeEvent.emit(html)
			})
		this.changeSubject.pipe(distinctUntilChanged()).subscribe(() => {
			this.updateHeight()
		})
		this.prepPreviousValue()

		this.eventsService.docClickSubject.subscribe(() => {
			this.colorDisplay = false
			this.alignDisplay = false
			this.documentTemplatesDisplay = false
			this.tblDisplay = false
		})
	}

	async ngAfterViewInit() {
		await this.store.timeout(50)
		this.updateHeight()
	}

	moTbl(row: number, col: number) {
		this.curView = [row, col]
	}

	updateHeight() {
		if (!this.editor) {
			return
		}

		let sh = this.editor.nativeElement.scrollHeight + 2
		if (sh <= this.height) {
			return
		}

		let curHeight = Math.min(this.heightRange[1], sh)
		if (curHeight > this.height) {
			this.height = curHeight
		}
	}

	colorSelect(color: string) {
		color = "#" + color
		this.colorDisplay = false
		this.chProp(this.curColorField, color)
		this[this.curColorField == "bgColor" ? "hiliteColor" : "foreColor"]()
		if (!window.getSelection().isCollapsed) {
			window.getSelection().collapseToEnd()
		}
	}

	async reset(withFocus = false) {
		this.height = this.getInitHeight()
		this.heightRange[1] = this.maxHeight
		if (withFocus) {
			this.focus()
		}
		await this.store.timeout(50)
		this.updateHeight()
	}

	focus(force: boolean = false) {
		// this.editor.nativeElement.focus();
		const el = this.editor.nativeElement
		if (force || !this.eventsService.isTouch) {
			el.focus()
			if (el.firstChild) {
				let sel = window.getSelection()
				sel.removeAllRanges()
				const rng = document.createRange()
				rng.setStartBefore(el.firstChild)
				rng.setEndAfter(el.lastChild)
				rng.collapse(false)
				sel.addRange(rng)
			}
			this.getCurProps()
		}
	}

	async replaceText(t: string) {
		this.previousValue = t
		this.prepPreviousValue()
		this.onChange()
		// await this.store.timeout(1000);
		// 	this.injectText(t);
	}

	async addText(t: string) {
		this.replaceText(this.curHtml + t)
	}

	injectText(t: string) {
		this.focus(true)
		if (this.curSelRng) {
			let sel = window.getSelection()
			sel.removeAllRanges()
			sel.addRange(this.curSelRng)
		}
		document.execCommand("insertHTML", false, t)
		// this.previousValue=t;
		this.onChange()
	}

	opColorPalette(fieldName: string, ev: MouseEvent) {
		ev.stopImmediatePropagation()
		this.curColorField = fieldName
		this.colorDisplay = true
	}
	opAlign(ev: MouseEvent) {
		const doOpen = !this.alignDisplay
		setTimeout(() => {
			this.alignDisplay = doOpen
		})
	}
	opDocumentTemplates(ev: MouseEvent) {
		const doOpen = !this.documentTemplatesDisplay
		setTimeout(() => {
			this.documentTemplatesDisplay = doOpen
		})
	}

	tblClick(ev: MouseEvent) {
		this.curView = [-1, -1]
		const doOpen = !this.tblDisplay
		setTimeout(() => {
			this.tblDisplay = doOpen
		})
	}

	chooseTbl() {
		if (this.curView[0] > -1 && this.curView[1] > -1) {
			let table = "<table class='editor-tbl' ><tbody>"
			for (let row = 0; row <= this.curView[0]; row++) {
				table += "<tr>"
				for (let col = 0; col <= this.curView[1]; col++) {
					table += "<td></td>"
				}
				table += "</tr>"
			}
			table += "</tbody></table>"
			this.injectText(table)
		}
	}

	chProp(propName: string, value: any) {
		this.props[propName] = value
	}

	onClick() {
		this.getCurProps()
		this.saveCurSelRng()
	}

	getCurProps() {
		this.curProps = Object.assign({}, this.props)
		//get b,i,u,bg color,color,size
		if (!window.getSelection().rangeCount) {
			return
		}
		let rng = window.getSelection().getRangeAt(0)
		let el: any = rng.startContainer

		let inTbl = false

		if (el.nodeType == Node.TEXT_NODE) {
			el = el.parentElement
		}
		while (el) {
			let tagName = el.tagName

			switch (tagName) {
				case "B":
				case "I":
				case "U":
				case "OL":
				case "UL":
					this.curProps[tagName] = true
					break
				case "FONT":
					// if(el.color){
					// 	this.curProps.color=el.color;
					// }
					if (el.style.fontSize) {
						this.curProps.size = parseInt(el.style.fontSize)
					}
					break
				case "TABLE":
					if (el.classList.contains("editor-tbl")) {
						inTbl = true
						// const rect=el.getBoundingClientRect();
						let left = el.offsetLeft - 50
						const width = el.offsetWidth
						left += width / 2
						if (left < 0) {
							left = 0
						}
						this.hoverOverTbl = { top: el.offsetTop - 30, left, el }
						// this.hoverOverTbl={top:rect.top-50,left:0};
						// el.getBoundingClientRect()
					}

					break
				case "TD":
					this.curTd = el
					break
				case "SPAN":
					// if(el.style.backgroundColor){
					// 	this.curProps.bgColor=el.style.backgroundColor;
					// }
					if (el.style.fontSize) {
						this.curProps.size = parseInt(el.style.fontSize)
					}
					break
				default: //DIV?
					if (el.style.textAlign) {
						this.curProps[el.style.textAlign] = true
					}
					break
			}
			el = el.parentElement
		}

		this.hoverTblOpens = {}
		if (!inTbl) {
			this.hoverOverTbl = null
		}
	}

	tblChange(type: string, action: string, color: string = "") {
		if (!this.curTd) {
			return
		}

		const tr = this.curTd.parentElement
		const tbl = tr.parentElement
		if (type == "col") {
			const ind = Array.from(tr.children).indexOf(this.curTd)

			switch (action) {
				case "before":
					if (!ind) {
						return
					}
					break
				case "after":
					if (ind >= tr.children.length - 1) {
						return
					}
					break
			}

			Array.from(tbl.children).forEach((tRow: any) => {
				switch (action) {
					case "before":
					case "after":
						const newTd = document.createElement("TD")
						newTd.innerHTML = ""
						const pos = action == "before" ? ind : ind + 1
						tRow.insertBefore(newTd, tRow.children[pos])
						break
					case "remove":
						const remTd = tRow.children[ind]
						tRow.removeChild(remTd)
						break
				}
			})
		} else {
			//row
			const ind = Array.from(tbl.children).indexOf(tr)

			switch (action) {
				case "above":
					if (!ind) {
						return
					}
					break
				case "below":
					if (ind >= tbl.children.length - 1) {
						return
					}
					break
			}

			switch (action) {
				case "above":
				case "below":
					const newTr = document.createElement("TR")
					const colNum = tr.children.length
					let html = ""
					for (let i = 0; i < colNum; i++) {
						html += "<td></td>"
					}
					newTr.innerHTML = html
					const pos = action == "above" ? ind : ind + 1
					tbl.insertBefore(newTr, tbl.children[pos])
					break
				case "remove":
					const remTr = tbl.children[ind]
					tbl.removeChild(remTr)
					break
				case "bg-color":
					tr.style.backgroundColor = "#" + color
					break
			}
		}
		this.onChange()
		this.hoverTblOpens = {}
	}

	showValue() {}

	isSelectionEmpty(rng: Range) {
		return (
			rng.startContainer === rng.endContainer &&
			rng.startOffset === rng.endOffset
		)
	}

	onEmptySelectCreateNonVisibleSpan() {
		if (!window.getSelection().rangeCount) {
			return
		}
		let rng = window.getSelection().getRangeAt(0)
		let isEmpty = this.isSelectionEmpty(rng)
		if (isEmpty) {
			rng.collapse(false)
			let nonVisibleChar = "&#65279;"
			// let span=`<span>${nonVisibleChar}</span>`;
			// let node=document.createTextNode(nonVisibleChar);
			let node = document.createElement("SPAN")
			node.innerHTML = nonVisibleChar
			rng.insertNode(node)
			window.getSelection().addRange(rng)
		}
	}

	comm(c: string) {
		this.onEmptySelectCreateNonVisibleSpan()
		document.execCommand(c, false, null)

		if (c.startsWith("justify")) {
			this.alignDisplay = false
		}
		if (c == "insertOrderedList") {
			if (!window.getSelection().isCollapsed) {
				window.getSelection().collapseToEnd()
			}
		}
	}

	hiliteColor() {
		this.onEmptySelectCreateNonVisibleSpan()
		document.execCommand("hiliteColor", false, this.props.bgColor)
	}

	foreColor() {
		this.onEmptySelectCreateNonVisibleSpan()
		document.execCommand("foreColor", false, this.props.color)
	}

	fontName() {
		this.onEmptySelectCreateNonVisibleSpan()
		// this.chProp("name",this.curProps.name);
		document.execCommand("fontName", false, this.curProps.name)
		this.getCurProps()
	}

	fontSize() {
		this.onEmptySelectCreateNonVisibleSpan()
		document.execCommand("fontSize", false, "1")

		// let rng=window.getSelection().getRangeAt(0);
		// let fragment=rng.extractContents();
		// this.repFontSize(fragment);
		// rng.insertNode(fragment);

		this.repFontSize(this.editor.nativeElement)
	}

	repFontSize(el: any) {
		Array.from(el.children).forEach((child: any) => {
			if (child.tagName) {
				if (child.tagName == "FONT" && child.getAttribute("size")) {
					child.removeAttribute("size")
					child.style.fontSize = this.curProps.size + "px"
					// let span=document.createElement("SPAN");
					// span.style.fontSize=this.curProps.size+"px";
					// span.innerHTML=child.innerHTML;
					// child.parentNode.replaceChild(span,child);
					// child=span;
				}
				this.repFontSize(child)
			}
		})
	}

	textDirection(dirString: string) {
		//lrt or rtl
		//get selection(range) START and END
		//if START and END are same div(row) in parentage - change its direction
		//if no common ancestor - wrap in div
		if (!window.getSelection().rangeCount) {
			return
		}

		let rng = window.getSelection().getRangeAt(0)
		let commonAncestor: any = rng.commonAncestorContainer

		if (commonAncestor.nodeType == Node.TEXT_NODE) {
			commonAncestor = commonAncestor.parentElement
		}

		//am I OUTSIDE the editor?
		let isInside = this.isDescendant(this.editor.nativeElement, commonAncestor)
		if (!isInside) {
			return
		}
		//climb up until the closest BLOCK LEVEL ancenstor
		let blockLevelTags = ["P", "DIV", "BODY"]
		while (!blockLevelTags.includes(commonAncestor.tagName)) {
			commonAncestor = commonAncestor.parentElement
		}

		//am I THE editor?
		if (commonAncestor == this.editor.nativeElement) {
			rng.setStart(rng.startContainer, 0)
			if (rng.endContainer.nodeType == Node.TEXT_NODE) {
				rng.setEnd(rng.endContainer, rng.endContainer.textContent.length)
			} else {
				rng.setEnd(rng.endContainer, rng.endContainer.childNodes.length)
			}
			let fragment = rng.extractContents()

			// window.getSelection().removeAllRanges();
			let div = document.createElement("DIV")
			div.style.direction = dirString
			div.appendChild(fragment)
			rng.insertNode(div)
			rng.setStart(div, 0)
			rng.setEnd(div, div.childNodes.length)
			window.getSelection().addRange(rng)

			//iterate and remove divs
			this.removeEmptyBlocksFromParent(this.editor.nativeElement)
		} else {
			//am I INSIDE the editor
			commonAncestor.style.direction = dirString
		}

		// this.focus(true);

		this.onChange()
	}

	removeEmptyBlocksFromParent(parent: HTMLElement) {
		let blockLevelTags = ["P", "DIV"]
		Array.from(parent.children).forEach((child: any) => {
			if (child.tagName) {
				if (blockLevelTags.includes(child.tagName)) {
					if (this.isElEmpty(child)) {
						child.remove()
					} else {
						this.removeEmptyBlocksFromParent(child)
					}
				}
			}
		})
	}

	isElEmpty(el: any) {
		return (
			!el.childNodes.length ||
			(el.childNodes.length == 1 &&
				el.firstChild.nodeType == Node.TEXT_NODE &&
				el.firstChild.nodeValue == "")
		)
	}

	isDescendant(parent: any, child: any) {
		return parent.contains(child)
		//
		let node = child.parentNode
		while (node != null) {
			if (node == parent) {
				return true
			}
			node = node.parentNode
		}
		return false
	}

	saveCurSelRng() {
		if (!window.getSelection().rangeCount) {
			return
		}
		let sel = window.getSelection()
		let rng = sel.getRangeAt(0)
		this.curSelRng = rng
	}

	insertTemplateString(documentTemaplateString: string) {
		this.injectText("[" + this.lang.getVal(documentTemaplateString) + "]")
		this.documentTemplatesDisplay = false
	}

	async onChange() {
		await this.store.timeout()
		if (!window.getSelection().rangeCount) {
			return
		}
		this.saveCurSelRng()
		this.getCurProps()
		this.curHtml = this.editor.nativeElement.innerHTML
		const stripped = this.store.strip_tags(this.curHtml || "")
		if (!stripped) {
			this.curHtml = ""
		} else {
			if (this.curHtml && !this.curHtml.includes("<div")) {
				this.curHtml = `<div>${this.curHtml}</div>`
			}
		}

		if (this.actualObj) {
			this.actualObj[this.fieldName] = this.store.sanitizeWysiwyg(this.curHtml)
		}
		// this.changeEvent.emit(this.curHtml);
		this.keyupEvent.emit(this.curHtml)
		this.changeSubject.next(this.curHtml)
	}

	dragMouseDown(ev: MouseEvent) {
		this.eventsService.dragMouseDown(ev, {
			affectedObj: this.editor.nativeElement,
			yStyleAttrName: "height",
		})
	}

	@HostListener("window:mouseup", ["$event"])
	onMouseUp(ev: MouseEvent) {
		this.eventsService.dragMouseUp(ev)
		if (!this.editor?.nativeElement) {
			return
		}
		this.height = parseInt(this.editor.nativeElement.style.height)
		this.heightRange[1] = Math.max(this.heightRange[1], this.height)
	}

	onPaste(ev: ClipboardEvent) {
		for (let item of Object.values(ev.clipboardData.items)) {
			if (item?.type && item.type.startsWith("image")) {
				ev.preventDefault()
				ev.stopPropagation()
				if (!this.allowsImageUpload) {
					continue
				}
				// const reader = new FileReader();
				// reader.onload = (e) => console.log(e.target);
				// reader.readAsDataURL(item.getAsFile());
				const file = item.getAsFile()

				let image = new Image()
				let canvas = this.canvas.nativeElement
				let ctx = canvas.getContext("2d")
				image.src = URL.createObjectURL(file)
				image.onload = () => {
					URL.revokeObjectURL(image.src)

					canvas.width = image.width
					canvas.height = image.height
					ctx.clearRect(0, 0, canvas.width, canvas.height)
					ctx.drawImage(image, 0, 0)
					let blobCallback = (blob) => {
						let file = new File([blob], "image.jpg", {
							type: "application/octet-stream",
						})

						this.uploadFile.emit(file)
					}
					canvas.toBlob(blobCallback, "image/jpeg", 0.8)
				}
			} else if (item?.type && item.type.startsWith("text/html")) {
				ev.preventDefault()
				ev.stopPropagation()
			} else if (item?.type && item.type.startsWith("text/plain")) {
				ev.preventDefault()
				ev.stopPropagation()
				const text = (ev.clipboardData.getData("text") || "").trim()
				if (text.length) {
					// const html = text.split("\n").map(line => line + "<br />").join("");
					const html = this.store.strip_tags(
						text
							.split("\n")
							.map((line) => line + "<br />")
							.join("")
					)

					//convert < to &lt;

					this.injectText(html)
				}
			}
		}
	}

	hoverTblClick(type: string) {
		const obj: any = {}
		obj[type] = !this.hoverTblOpens[type]
		this.hoverTblOpens = obj
	}

	async maximini() {
		this.isMaximized = !this.isMaximized
		document.body.style.overflow = this.isMaximized ? "hidden" : ""

		// await this.store.timeout(50);
		// if(!this.curHtml){
		//   this.curHtml = this.editor.nativeElement.innerHTML;
		// }
	}

	//   justifyClick(ev:MouseEvent){
	// 	ev.stopImmediatePropagation();
	// 	ev.stopPropagation();
	// 	this.justifyBtnsOpened=!this.justifyBtnsOpened;

	//   }
}
