<div class="container-wrap">
	<h4 class="gen-title">{{ "preferences" | langVal }}</h4>
	<hr class="separation-line" />

	<div>
		<div class="buttons-row row">
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'show_email_in_pdf' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'download_documents' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'pagination_in_big_tables' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'notifications_upon_login' }
					"
				/>
			</div>

			@if (permsService.owner_has_users) {
				<div class="col-3 col-xl-2">
					<ng-container
						*ngTemplateOutlet="
							btnGroupYesNo;
							context: { fieldName: 'show_therapist' }
						"
					/>
				</div>
			}
		</div>
		<div class="buttons-row row">
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'show_payment_details' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<label>{{ "invoice_per_followup" | langVal }}</label>
				<app-gen-button-group
					[actualObj]="configcliniq"
					fieldName="invoice_per_followup"
					optionsListName="yes_no_opts"
					(changeEvent)="warnInvoicePerFollowupChange()"
				/>
			</div>

			<div class="col-3">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'show_manual_charge' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'send_original_to_user' }
					"
				/>
			</div>
			<div class="col-3">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'is_selfinvoice' }
					"
				/>
			</div>
			<div class="col-3">
				<label>{{ "enable_israel_invoice" | langVal }}</label>
				<app-gen-button-group
					[actualObj]="configcliniq"
					fieldName="enable_israel_invoice"
					optionsListName="yes_no_opts"
					title_ph_lang="enable_israel_invoice"
					(changeEvent)="validateAndSave(); openItaGetAuth()"
				/>
			</div>
			<!--      <div class="col-3  ">-->
			<!--        <label>{{"enable_invoice_number_edit" | langVal}}</label>-->
			<!--        <app-gen-button-group [actualObj]="configcliniq" fieldName="enable_invoice_number_edit" optionsListName="yes_no_opts" title_ph_lang="enable_invoice_number_edit" (changeEvent)="validateAndSave();openItaGetAuth()" />-->
			<!--      </div>-->
		</div>
		<div class="buttons-row row"></div>
		<div class="row">
			<div class="col-6 col-md-2">
				<label>{{ "language" | langVal }}</label>

				<app-gen-select
					[actualObj]="configcliniq"
					[errors]="errors"
					fieldName="lang"
					[tabindex]="64"
					(changeEvent)="validateAndSave()"
					optionsListName="langOpts"
					optionFieldId="value"
				/>
			</div>
			<div class="col-6 col-md-3">
				<label>{{ "default_font_size" | langVal }}</label>

				<app-gen-select
					[actualObj]="configcliniq"
					[errors]="errors"
					fieldName="default_font_size"
					[tabindex]="65"
					(changeEvent)="validateAndSave()"
					[options]="fontSizeOpts"
					optionFieldName="value"
				/>
			</div>
			<div class="col-4 col-md-2">
				<label>{{ "default_font_for_documents" | langVal }}</label>

				<app-gen-select
					[actualObj]="configcliniq"
					[errors]="errors"
					[tabindex]="66"
					fieldName="default_font_for_documents"
					(changeEvent)="validateAndSave()"
					optionsListName="default_font_for_documents_opts"
					optionFieldId="value"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'default_medical_conf' }
					"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'no_saturday' }
					"
				/>
			</div>
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'short_meetings_calendar' }
					"
				/>
			</div>

			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'wa_app_installed' }
					"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-3 col-xl-2">
				<label>{{ "MaxNumberOfPayments" | langVal }}</label>
				<app-gen-select
					[actualObj]="configcliniq"
					[errors]="errors"
					[tabindex]="67"
					style="width: 100px"
					fieldName="MaxNumberOfPayments"
					(changeEvent)="validateAndSave()"
					[options]="MaxNumberOfPaymentsOpts"
					optionFieldName="value"
				>
				</app-gen-select>
			</div>
		</div>
	</div>
	<div class="buttons-row row">
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_patient_id' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'show_versions',
						title: 'show_versions_on_followup',
					}
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'enable_past_followups_on_followup' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'enable_group_attends_on_followup' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'show_personal_memorandum',
						title: 'show_personal_memorandum_on_mobile',
					}
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_insert_row' }
				"
			/>
		</div>

		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'enable_less_fus' }
				"
			/>
		</div>
	</div>
	<hr class="separation-line" />
	<div>
		<div class="row">
			<div class="col-6 col-md-2">
				<label>{{ "time_to_logout" | langVal }}</label>
				<app-gen-input
					[tabindex]="63"
					[actualObj]="configcliniq"
					[errors]="errors"
					fieldName="time_to_logout"
					(keyupEvent)="validateAndSave()"
				>
				</app-gen-input>
			</div>
		</div>
	</div>
	<hr class="separation-line" />
	<div class="buttons-row row">
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_future_meetings' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'show_search_by_phone' }
				"
			/>
		</div>
	</div>
	<hr />
	<div class="buttons-row row">
		<!-- expenses_by_created_at_default expenses_by_former_month  -->
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'expenses_by_created_at_default' }
				"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: { fieldName: 'expenses_by_former_month' }
				"
			/>
		</div>
	</div>
	<div class="buttons-row row">
		@if (permsService.owner_has_users) {
			<div class="col-3 col-xl-2">
				<ng-container
					*ngTemplateOutlet="
						btnGroupYesNo;
						context: { fieldName: 'search_by_tags_add_meeting' }
					"
				/>
			</div>
		}
	</div>
	<div class="buttons-row row">
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'budget_for_payors',
						title: 'budget_for_payors'}"
			/>
		</div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'calc_age_by_months'}"
			/>
    </div>
		<div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {fieldName: 'wide_logo'}"
			/>
		</div>
    <div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'sms_real_debt',	}
				"
			/>
		</div>
    <div class="col-3 col-xl-2">
			<ng-container
				*ngTemplateOutlet="
					btnGroupYesNo;
					context: {
						fieldName: 'id_number_on_patient_banner',	}
				"
			/>
		</div>
    </div>

		<!--    <div class="col-3  col-xl-2">-->
		<!--      <ng-container *ngTemplateOutlet="btnGroupYesNo;context:{fieldName:'login_to_weekly_view',title:'login_to_weekly_view'}" />-->
		<!--    </div>-->
	</div>
	<hr />
	<app-cc-params-zones />
	<hr />
	<app-cc-reports />


<ng-template
	#btnGroupYesNo
	let-fieldName="fieldName"
	let-title="title"
>
	<label>{{ title || fieldName | langVal }}</label>
	<app-gen-button-group
		[actualObj]="configcliniq"
		[fieldName]="fieldName"
		optionsListName="yes_no_opts"
		[title_ph_lang]="title || fieldName"
		(changeEvent)="validateAndSave()"
	/>
</ng-template>
