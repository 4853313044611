import { Component, Input, OnInit } from "@angular/core"
import {
	Configcliniq,
	ConfigDefinitions,
	Holiday,
} from "@app/definitions/types"
import { ApiService } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { StoreService } from "@app/services/store.service"

@Component({
	selector: "app-cc-params-holiday",
	templateUrl: "./cc-params-holiday.component.html",
})
export class CcParamsHolidayComponent implements OnInit {
	configcliniq: Configcliniq //the config DB obj
	holidayGroupNames: string[] = [
		"national",
		"jewish",
		"muslim",
		"christian_catholic_protestant",
		"christian_orthodox",
		"druze",
		"cherkess",
	]
	holidayGroupsChecked: any = {}
	holidayGroups: any = {}
	// holidays: Holiday[] = [];
	isSaving: boolean = false

	constructor(
		protected apiService: ApiService,
		public lang: LangService,
		public modalService: ModalService,
		protected store: StoreService
	) {
		this.configcliniq = this.store.getCliniqDataOrExit()
	}

	ngOnInit() {
		this.holidayGroups = {}
		this.holidayGroupsChecked = {}
		this.holidayGroupNames.forEach((holidayGroupName) => {
			this.holidayGroups[holidayGroupName] = []
			this.holidayGroupsChecked[holidayGroupName] = false
		})
		const holidays = this.store.getSiteDataTable("holidays")
		holidays.forEach((holiday) => {
			const holiday_group = holiday.holiday_group
			if (!this.holidayGroups[holiday_group]) {
				return
			}

			holiday.instancesFloat =
				this.lang.getVal("holiday_float_header") +
				"\n" +
				holiday.instances
					.map((instance) => {
						return (
							this.store.getMomentDisplay(instance.date) +
							" - " +
							this.store.getMomentDisplay(instance.date_to)
						)
					})
					.join("\n")

			this.holidayGroups[holiday_group].push(holiday)
		})
	}
	async save() {
		if (this.isSaving) {
			return
		}
		this.isSaving = true
		const holidays = [] //will collect all holidays from all groups
		this.holidayGroupNames.forEach((holidayGroupName) => {
			//for each group name
			this.holidayGroups[holidayGroupName].forEach((holiday) => {
				//for each holiday in the array (of this group name)
				holidays.push(holiday) //add to holidays
			})
		})
		const res: any = await this.apiService.post("user_holiday_update", {
			holidays,
		})
		this.isSaving = false
		this.modalService.openToast(this.lang.getVal("updated_successfully"))
		this.store.getAllFus(true)
	}

	checkAll(holidayGroupName: string, checked: boolean) {
		this.holidayGroupsChecked[holidayGroupName] = checked
		this.holidayGroups[holidayGroupName].forEach((holiday) => {
			//for each holiday in the array (of this group name)
			holiday.active = checked ? "yes" : "no"
		})
	}
}
