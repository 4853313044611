<div class="payment-form container-fluid">
	<!-- <app-logo-gen /> -->
	@if (guestPaymentFormObject) {
		<div class="product">
			<!--Product data    {{guestPaymentFormObject | json}}-->
			<div>
				<img
					[src]="
						environment.serverUrl +
						'/product-image/' +
						guestPaymentFormObject?.id
					"
					[alt]="guestPaymentFormObject?.title || ''"
					style="
						width: 100%;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
						padding-block: 10px;
						height: 100% !important;
					"
				/>
			</div>
			<div
				class="d-flex flex-column align-items-center justify-content-center"
				[innerHTML]="guestPaymentFormObject?.title || '' | safeHtml"
				style="position: absolute; top: 20%; transform: translate(-50%, -50%)"
			></div>
			<!--    <span class="d-flex align-items-top justify-content-center" [innerHTML]="guestPaymentFormObject?.title | safeHtml" [style.background-image]="'url('+environment.serverUrl+ '/product-image/'+guestPaymentFormObject.id+')'" style="-->
			<!--           background-size:cover; background-repeat: no-repeat; background-position: center center;padding-block: 10px;-->
			<!--        ">-->

			<!--    </span>-->

			<div class="px-4">
				<!-- <h3 class="d-flex flex-column align-items-center justify-content-center" [innerHTML]="guestPaymentFormObject?.title" >

        </h3> -->
				<div
					class="d-flex flex-column align-items-center justify-content-center"
					[innerHTML]="guestPaymentFormObject?.sub_title || '' | safeHtml"
				></div>
				<p
					style="padding-block: 10px"
					[innerHTML]="guestPaymentFormObject?.body || '' | safeHtml"
				></p>
			</div>
		</div>

		<div class="scheduler payment">
			<div>
				{{ "to_pay_instructions" | langVal }}
			</div>
			<div>
				<label>{{ "name" | langVal }}</label>
				<app-gen-input
					[tabindex]="1"
					autocomplete="off"
					autofocus="autofocus"
					[actualObj]="actualObj"
					[errors]="errors"
					fieldName="name"
					(keyupEvent)="validateEntireForm()"
				/>
			</div>
			<div>
				<label>{{ "phone" | langVal }}</label>
				<app-gen-input
					[tabindex]="2"
					[actualObj]="actualObj"
					[errors]="errors"
					fieldName="phone"
					(keyupEvent)="validateEntireForm()"
				/>
			</div>
			<div>
				<label>{{ "email" | langVal }}</label>
				<app-gen-input
					[tabindex]="3"
					[actualObj]="actualObj"
					[errors]="errors"
					fieldName="email"
					(keyupEvent)="validateEntireForm()"
				/>
			</div>

			@if (guestPaymentFormObject.coupon) {
				<div>
					<label>{{ "coupon" | langVal }}</label>
					<app-gen-input
						[tabindex]="4"
						[actualObj]="actualObj"
						[errors]="couponErrorObj"
						fieldName="coupon"
						(changeEvent)="checkCoupon()"
					/>
				</div>
			}

			<div>
				{{ "price" | langVal }}: {{ "₪" }}{{ guestPaymentFormObject?.price }}.
			</div>
			<!-- TAKBULL -->
			@if (isBackFromFirstApiCall) {
				<app-btn-ext
					btnClass="tall btn-primary subm"
					text="to_pay"
					[disabled]="guestPaymentFormObject?.price <= 0 || !isFormSubmittable"
					(clickEv)="redirect()"
					[isSpin]="isFetching2ndApi"
					iconName="doubleArrow"
				/>
			}
			<!-- END TAKBULL -->
		</div>
		<footer>
			מופעל על ידי &nbsp;
			<a
				href="http://my-cliniq.com/"
				target="_blank"
				>My-Cliniq.com</a
			>
		</footer>
	}
</div>
