import { EventsService } from "@app/services/events.service"
import { LangService } from "@app/services/lang.service"
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	SimpleChange,
	ElementRef,
	ViewChild,
	booleanAttribute,
} from "@angular/core"
import { ApiService } from "@app/services/api.service"
import { SearchSortService } from "@app/services/search-sort.service"
import {
	ConfigDefinitions,
	FuzzySearchObj,
	Searchable,
} from "@app/definitions/types"
import { StoreService } from "@app/services/store.service"
import { InputsService } from "@app/services/inputs.service"

@Component({
	selector: "app-gen-autocomplete",
	templateUrl: "./gen-autocomplete.component.html",
})
export class GenAutocompleteComponent implements OnInit, Searchable {
	@Input() options: any[] = []
	@ViewChild("inputElement") inputElement: ElementRef
	@Input() fieldName: string = ""
	@Input({ transform: booleanAttribute }) avoidNoResult: boolean = false
	@Input() className: string = ""
	@Input() searchStrFieldName: string = ""
	@Input() disabled: boolean = false
	@Input({ transform: booleanAttribute }) autofocus: boolean = false
	@Input() width: string = null
	@Input() tabindex: string = ""
	@Input() error: any = undefined
	@Input() fuzzySearchableClasses: string[] = []
	@Input() searchableFields: string[] = null
	@Input() displayField: string = "name"
	@Input() sortField: string = null
	@Input() placeholder: string = ""
	@Input() title_ph_lang: string = ""
	@Output() chosenEvent = new EventEmitter()
	@Output() keyUp = new EventEmitter()

	@Input() objectFieldToSave: string = "id"
	@Input() fuzzySearchObj: FuzzySearchObj = null
	@Input() outerFilterValue: any = null
	@Input() searchStr: string = ""
	@Input() optionDisplay: string = ""
	@Input() dataRowField: string = ""
	@Input({ transform: booleanAttribute }) onClickClearAndShowOpts: boolean =
		false
	@Input() genTableVars: any = null //vars to be used to load init vars to gen table field
	@Input() isStatic: boolean = false
	@Input() approvedIds: number[] = null

	@Input() actualObj: any = null

	@Input({ transform: booleanAttribute }) hasPlus: boolean = false
	@Input() plusLang: string = ""
	@Output() plusClick = new EventEmitter()

	optionsShows: any[] = []
	isSearchStrBelongsToRealObject: boolean = true

	isDisplayResults: boolean = false
	isFuzzy: boolean = false
	isInApiCall: boolean = false
	actualDisplayString: string = "actualDisplayString45grty_56"
	docClickSubscription: any

	constructor(
		private searchSortService: SearchSortService,
		public lang: LangService,
		public apiService: ApiService,
		protected eventsService: EventsService,
		protected store: StoreService,
		private inputsService: InputsService
	) {}

	async ngOnInit() {
		this.inputsService.loadVarsToInput(this)

		this.eventsService.escapeKeySubj.subscribe(() => {
			this.isDisplayResults = false
		})
		this.docClickSubscription = this.eventsService.docClickSubject.subscribe(
			(val: any) => {
				//when document clicks - if the click source is not this popoup, close it
				if (val.src != this) {
					this.isDisplayResults = false
				}
			}
		)

		this.options.forEach((it) => {
			it[this.displayField] = this.store.strip_tags(it[this.displayField])
		})

		if (
			!this.searchStr &&
			this.actualObj &&
			this.actualObj[this.fieldName] &&
			this.fuzzySearchableClasses.length
		) {
			this.updateSearchStr()
		}
		if (this.autofocus && !this.eventsService.isTouch) {
			await this.store.timeout(50)
			this.focus()
		}
	}
	async ngOnChanges(changes: SimpleChange) {
		if (changes["actualObj"] && this.actualObj && this.fieldName) {
			this.store.timeout(50)
			this.updateSearchStr()
		}

		// if (changes["ids"]) {
		// 	this.resetChosenObjs();
		// }
	}
	ngOnDestroy() {
		//unsubscribe from the document clicks broadcast
		this.docClickSubscription.unsubscribe()
	}
	handleClick(ev: any) {
		ev.stopImmediatePropagation()
		this.eventsService.onDocClick({ src: this })
		this.onkeyup(ev.target.value, false, true)
	}

	updateSearchStr() {
		let className = this.fuzzySearchableClasses[0]
		if (!className) {
			return
		}
		let tbl = this.store.getSiteDataTable(className)
		let obj = tbl.find((it) => it.id == this.actualObj[this.fieldName])
		if (className === "banks") {
			obj = tbl.find((it) => it.code == this.actualObj[this.fieldName])
		}

		if (obj) {
			let displayField =
				this.searchSortService.siteDataSearchableFieldPerClass[className] ||
				"name"
			this.searchStr = obj[displayField]
		} else {
			this.searchStr = ""
		}
	}

	focus() {
		this.inputElement.nativeElement.focus()
	}
	//add esc to close

	returnSearchStrForAdding() {
		return this.isSearchStrBelongsToRealObject ? "" : this.searchStr
	}

	emptySearchStr() {
		this.searchStr = ""
	}

	choose(option: any, isEmpty: boolean = false) {
		this.searchStr = isEmpty ? "" : option[this.displayField]
		if (!isEmpty && this.optionDisplay) {
			this.searchStr = option[this.displayField]
		}

		this.isSearchStrBelongsToRealObject = true
		delete option[this.actualDisplayString]
		if (this.actualObj) {
			this.actualObj[this.fieldName + "_fuzzy_val3465"] = ""
			this.actualObj[this.fieldName] =
				option && option[this.objectFieldToSave]
					? option[this.objectFieldToSave]
					: 0
			if (this.searchStrFieldName) {
				this.actualObj[this.searchStrFieldName] = this.searchStr
			}
		}
		this.chosenEvent.emit(option)
		this.isDisplayResults = false
		this.optionsShows = []
		// this.onkeyup("");
	}

	getActualDisplayString(item: any) {
		return this.optionDisplay
			? this.searchSortService.templateReplaceInObj(this.optionDisplay, item)
			: item[this.displayField || "name"]
	}

	async searchByApiFilter() {
		this.optionsShows = []
		this.isDisplayResults = false
		const minLength = this.fuzzySearchObj?.minLength || 1
		if (this.searchStr.length < minLength) {
			return
		}

		if (this.fuzzySearchObj.outerFilterName && this.outerFilterValue === null) {
			//if has category type value (like bank or atc5) but it's NULL, this will error the request
			return
		}

		let callObj: any = {}
		callObj[this.fuzzySearchObj.outerFilterName] = this.outerFilterValue
		callObj[this.fuzzySearchObj.innerFilterName] = this.searchStr
		callObj.mode = this.actualObj.id ? "update" : "add"

		const res: any = await this.apiService.post(
			this.fuzzySearchObj.apiCall,
			callObj
		)
		res.forEach((item) => {
			item[this.actualDisplayString] = this.getActualDisplayString(item)
			item[this.displayField] = item[this.actualDisplayString]
		})

		this.optionsShows = [...res]
		this.isDisplayResults = true

		this.preDisplayProcess(this.searchStr)
	}

	preDisplayProcess(value: string) {
		const cc = this.store.getCliniqDataOrExit()
		const show_patient_id = cc?.show_patient_id == "yes"

		const sortField = this.sortField || this.displayField
		if (this.searchStr) {
			this.optionsShows.sort((a: any, b: any) =>
				String(b[sortField]).indexOf(this.searchStr) <
				String(a[sortField]).indexOf(this.searchStr)
					? 1
					: -1
			)
		} else {
			this.optionsShows.sort((a: any, b: any) =>
				b[sortField] < a[sortField] ? 1 : -1
			)
		}

		const valueLc = value.toLocaleLowerCase()

		this.optionsShows.forEach((it) => {
			let v = this.getActualDisplayString(it)
			let vLC = v.toLowerCase()
			if (this.isFuzzy) {
				let endVal = []
				let fuzzVal = [...value]
				for (let char of fuzzVal) {
					let pos = vLC.indexOf(char.toLowerCase())
					if (pos != -1) {
						let addedSpan = `<span class="autocomplete-highlight">${v.substr(pos, 1)}</span>`
						endVal.push(v.substr(0, pos) + addedSpan)
						v = v.substr(pos + 1)
						vLC = vLC.substr(pos + 1)
					}
				}
				endVal.push(v)
				v = endVal.join("")
			} else {
				const pos = vLC.indexOf(valueLc)
				if (pos != -1) {
					const orig = v.substring(pos, pos + value.length)
					v =
						v.substring(0, pos) +
						`<span class="autocomplete-highlight">${orig}</span>` +
						v.substring(pos + value.length)
				}
				// let reg = new RegExp(value);
				// v = v.replace(reg, `<span class="autocomplete-highlight">$&</span>`);
			}

			let isInactive = false
			let iconCls = ""

			switch (it.typeClassName) {
				case "patients":
					if (show_patient_id) {
						v = v + " (" + it.id + ")"
					}
					const iconName = it.is_group == "yes" ? "group" : "patient"
					iconCls = this.lang.getConfigVal("icon." + iconName)
					// v = `<i class="${this.lang.getConfigVal("icon." + iconName)}" ></i>${v}`;
					if (it.active == "no") {
						isInactive = true
						// v = `<span class="autocomplete-inactive">${v}</span>`;
					}
					break
				case "contacts":
					iconCls = this.lang.getConfigVal("icon.contact")
					// v = `<i class="${this.lang.getConfigVal("icon.contact")}" ></i>${v}`;
					break
				case "sub_users":
					iconCls = this.lang.getConfigVal("icon.sub_user")
					// v = `<i class="${this.lang.getConfigVal("icon.sub_user")}" ></i>${v}`;
					break
				case "payors":
					iconCls = this.lang.getConfigVal("icon.payor")
					// v = `<i class="${this.lang.getConfigVal("icon.sub_user")}" ></i>${v}`;
					break
			}

			v = `<div class="gen-ac-opt ${isInactive ? "autocomplete-inactive" : ""}"><div>${iconCls ? `<i class="${iconCls}"></i>` : ""}</div><div>${v}</div></div>`

			it[this.actualDisplayString] = v
		})
	}

	async onkeyup(
		value: string,
		isBackFromApi: boolean = false,
		onClick: boolean = false,
		skipClear: boolean = false
	) {
		if (!skipClear && this.onClickClearAndShowOpts && onClick) {
			this.clear()
			this.onkeyup("", isBackFromApi, true, true)
			return
		}

		// if(this.searchStr.length==0 && !onClick && !isBackFromApi){
		//   this.clear();
		//   return;
		// }

		const isIdenticalToPreviousValue = this.searchStr == value

		if (!isBackFromApi && isIdenticalToPreviousValue && !onClick) {
			return
		}

		if (value == "" && !onClick) {
			this.clear()
			return
		}

		this.searchStr = value
		this.isSearchStrBelongsToRealObject = false
		if (this.actualObj) {
			this.actualObj[this.fieldName + "_fuzzy_val3465"] = this.searchStr
		}
		this.keyUp.emit({ str: this.searchStr, isIdenticalToPreviousValue })

		//start searching
		if (this.fuzzySearchObj?.apiCall) {
			this.searchByApiFilter()
			return
		}

		if (this.isInApiCall) {
			return
		}

		if (this.fuzzySearchableClasses.length && !this.store.data.siteData) {
			this.isInApiCall = true
			this.isInApiCall = false
			this.onkeyup(this.searchStr, true, onClick)
			return
		}
		if (!this.searchStr.length && !onClick) {
			this.isDisplayResults = false
			return (this.optionsShows = [])
		}
		this.isDisplayResults = true
		this.searchSortService.search(
			this,
			value,
			"optionsShows",
			"options",
			this.isFuzzy,
			this.searchableFields
		)
		if (this.fuzzySearchableClasses[0] === "tarifs") {
			this.optionsShows = this.optionsShows.filter(
				(row) => row.active === "yes"
			)
		}

		this.preDisplayProcess(value)
	}
	clickInput() {
		this.onkeyup(this.searchStr, false, true)
	}

	clear() {
		if (this.disabled) {
			return
		}
		this.choose({ id: 0 }, true)
		this.focus()
	}
}
