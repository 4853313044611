import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core"
import { StoreService } from "@app/services/store.service"
import { distinctUntilChanged, Subject } from "rxjs"

@Component({
	selector: "app-ta-expands",
	template: `
		<textarea
			#inp
			(paste)="paste()"
			(change)="onChange()"
			[tabindex]="tabindex"
			[ngClass]="className"
			[disabled]="disabled"
			class="form-control"
			[(ngModel)]="actualObj[fieldName]"
			[placeholder]="placeholder || (title_ph_lang || fieldName | langVal)"
			[title]="title_ph_lang || fieldName | langVal"
			(keyup)="keyup()"
			[style.height]="height + 'px'"
		></textarea>
		@if (errors[fieldName]) {
			<p class="error">{{ errors[fieldName] }}</p>
		}
	`,
})
export class TaExpandsComponent implements OnInit {
	@Input() tabindex = null
	@Input() className = ""
	@Input() actualObj = null
	@Input() fieldName = ""
	@Input() disabled = false
	@Input() errors = null
	@Input() placeholder = ""
	@Input() title_ph_lang = ""
	@Output("keyupEvent") keyupEvent = new EventEmitter()
	@Output("changeEvent") changeEvent = new EventEmitter()
	changeSubject = new Subject()
	baseHeight: number = 24
	height: number = this.baseHeight
	@ViewChild("inp") inp: ElementRef = null

	constructor(private store: StoreService) {}

	ngOnInit() {
		this.changeSubject.pipe(distinctUntilChanged()).subscribe(() => {
			this.updateHeight()
		})
	}

	async ngAfterViewInit() {
		await this.store.timeout(50)
		this.updateHeight()
	}

	async paste() {
		await this.store.timeout()
		this.changeSubject.next(this.actualObj[this.fieldName])
	}

	keyup() {
		this.keyupEvent.emit(this.actualObj[this.fieldName])
		this.changeSubject.next(this.actualObj[this.fieldName])
	}
	onChange() {
		this.changeEvent.emit(this.actualObj[this.fieldName])
	}
	async resetHeight() {
		this.height = this.baseHeight
		await this.store.timeout(50)
		this.updateHeight()
	}
	updateHeight() {
		if (!this.inp) {
			return
		}

		const na = this.inp.nativeElement
		if (na.scrollHeight > this.height) {
			this.height = na.scrollHeight + 6
		}
	}
	focus(scrollToTop: boolean = false) {
		//called from parent. focuses the input (can also scroll into view)
		this.inp.nativeElement.focus()
		if (scrollToTop) {
			this.inp.nativeElement.scrollIntoView(true)
		}
	}
}
